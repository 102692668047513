import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent, DialogComponentData } from './dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogServiceService {

  constructor(
    private dialog: MatDialog,
  ) { }

  /**
   * show dialog with size 250px
   * @param text message to show
   */
  show(text: string, title?: string, callback?: (result: DialogComponentData) => void): void {
    this.dialog.open(DialogComponent, {
      width: '250px',
      data: {
        title: title || '',
        message: text
      }
    }).afterClosed().subscribe( result => {
      if (callback) { callback(result); }
    });
  }
  /**
   * show dialog to confirm with size 250px
   * @param text message to show
   */
  confirm(text: string, callback: (result: DialogComponentData) => void, title?: string, yes?: string, no?: string): void {
    this.dialog.open(DialogComponent, {
      width: '250px',
      data: {
        title: title || '',
        message: text,
        showYesNo: true,
        yesText: yes,
        noText: no
      }
    }).afterClosed().subscribe( result => {
      callback(result);
    });
  }
}
