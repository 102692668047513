import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-not-mobile',
  templateUrl: './not-mobile.component.html',
  styleUrls: ['./not-mobile.component.sass']
})
export class NotMobileComponent implements OnInit {

  logoURL: string;
  bgURL: string;
  url = '';

  constructor(
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    let lg = (window.navigator.language || 'en').substr(0, 2);
    if (location.href.match(/lg=fr/gi)) { lg = 'fr'; }
    this.translate.use(lg || 'en');

    this.logoURL = 'assets/icons/icon-128x128.png';
    this.bgURL = 'assets/images/login_bg.jpeg';

    this.url = location.href;
  }
}
