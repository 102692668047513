import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { HomeComponent } from './component/home/home.component';
import { Authguard } from './class/authguard/authguard';
import { NewsComponent } from './component/news/news.component';
import { AdsComponent } from './component/ads/ads.component';
import { EventsComponent } from './component/events/events.component';
import { NewComponent } from './component/news/new.component';
import { DisclaimerComponent } from './component/disclaimer/disclaimer.component';

import { AccountComponent } from './component/account/account.component';
import { SignupComponent } from './component/signup/signup.component';
import { SearchComponent } from './component/search/search.component';
import { ProductComponent } from './component/product/product.component';
import { ProductsComponent } from './component/product/products.component';
import { ProductConfirmComponent } from './component/shop/confirm/product-confirm.component';
import { CartComponent } from './component/shop/cart/cart.component';
import { CheckoutComponent } from './component/shop/checkout/checkout.component';
import { PaymentComponent } from './component/shop/payment/payment.component';
import { OrderHistoryComponent } from './component/order/order-history/order-history.component';
import { EventComponent } from './component/events/event.component';
import { NotMobileComponent } from './component/not-mobile/not-mobile.component';
import { MobileGuard } from './class/mobile-guard/mobile-guard';
import { PlaceComponent } from './component/place/place.component';
import { AboutUsComponent } from './component/about-us/about-us.component';
import { LeaderboardComponent } from './component/leaderboard/leaderboard.component';
import { communityAppBeta } from './config/beta';
import { BenefitsComponent } from './component/benefits/benefits.component';
import { BenefitComponent } from './component/benefits/benefit.component';
import { PhotoAlbumsComponent } from './component/photo-albums/photo-albums.component';
import { PhotoAlbumComponent } from './component/photo-albums/photo-album/photo-album.component';
import { ForumComponent } from './component/forum/forum.component';
import { NewPostComponent } from './component/forum/new-post/new-post.component';
import { CompetitionsComponent } from './component/competitions/competitions.component';
import { CompetitionComponent } from './component/competitions/competition/competition.component';
import { MembersComponent } from './component/members/members.component';
import { MemberComponent } from './component/members/member/member.component';
import { LinksComponent } from './component/links/links.component';
import { PlacesComponent } from './component/place/places.component';
import { MenusComponent } from './component/restaurant/menus/menus.component';
import { MenuComponent } from './component/restaurant/menu/menu.component';
import { MenuConfirmComponent } from './component/restaurant/confirm/menu-confirm.component';
import { MealComponent } from './component/restaurant/meal/meal.component';
import { MealCheckoutComponent } from './component/restaurant/checkout/checkout.component';
import { MealPaymentComponent } from './component/restaurant/payment/payment.component';
import { LinkComponent } from './component/links/link.component';
import { EverstreamComponent } from './component/everstream/everstream.component';
import { EverstreamsComponent } from './component/everstream/everstreams.component';
import { CPSURLComponent } from './component/booking/cpsurl.component';

const defP = { path: '', component: HomeComponent, canActivate: [Authguard] };

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [MobileGuard] },
  { path: 'signup', component: SignupComponent },
  { path: 'not-mobile', component: NotMobileComponent },

  // protected by auth
  { path: '', component: HomeComponent, canActivate: [Authguard] },
  { path: 'ads', component: AdsComponent, canActivate: [Authguard] },
  { path: 'disclaimer', component: DisclaimerComponent, canActivate: [Authguard] },
  { path: 'new/:id', component: NewComponent, canActivate: [Authguard] },
  { path: 'new2/:id', component: NewComponent, canActivate: [Authguard] },
  { path: 'new3/:id', component: NewComponent, canActivate: [Authguard] },
  { path: 'new4/:id', component: NewComponent, canActivate: [Authguard] },
  { path: 'news', component: NewsComponent, canActivate: [Authguard] },
  { path: 'news2', component: NewsComponent, canActivate: [Authguard] },
  { path: 'news3', component: NewsComponent, canActivate: [Authguard] },
  { path: 'news4', component: NewsComponent, canActivate: [Authguard] },
  { path: 'event/:d', component: EventComponent, canActivate: [Authguard] },
  { path: 'events', component: EventsComponent, canActivate: [Authguard] },
  { path: 'shop', component: ProductsComponent, canActivate: [Authguard] },
  { path: 'account', component: AccountComponent, canActivate: [Authguard] },
  { path: 'search', component: SearchComponent, canActivate: [Authguard] },
  { path: 'product/:id', component: ProductComponent, canActivate: [Authguard] },
  { path: 'product/confirm/:id', component: ProductConfirmComponent, canActivate: [Authguard] },
  { path: 'cart', component: CartComponent, canActivate: [Authguard] },
  { path: 'checkout', component: CheckoutComponent, canActivate: [Authguard] },
  { path: 'payment', component: PaymentComponent, canActivate: [Authguard] },
  { path: 'order/history', component: OrderHistoryComponent, canActivate: [Authguard] },
  { path: 'reorder/:id', component: CartComponent, canActivate: [Authguard] },
  { path: 'place/:source/:sourceid/:id', component: PlaceComponent, canActivate: [Authguard] },
  { path: 'about', component: AboutUsComponent, canActivate: [Authguard] },
  { path: 'links', component: LinksComponent, canActivate: [Authguard] },
  { path: 'links2', component: LinksComponent, canActivate: [Authguard] },
  { path: 'links3', component: LinksComponent, canActivate: [Authguard] },
  { path: 'links4', component: LinksComponent, canActivate: [Authguard] },
  { path: 'link/:id', component: LinkComponent, canActivate: [Authguard] },
  { path: 'link2/:id', component: LinkComponent, canActivate: [Authguard] },
  { path: 'link3/:id', component: LinkComponent, canActivate: [Authguard] },
  { path: 'link4/:id', component: LinkComponent, canActivate: [Authguard] },
  { path: 'leaderboard', component: LeaderboardComponent, canActivate: [Authguard] },
  { path: 'benefits', component: BenefitsComponent, canActivate: [Authguard]},
  { path: 'benefit/:id', component: BenefitComponent, canActivate: [Authguard]},
  { path: 'forum', component: ForumComponent, canActivate: [Authguard] },
  { path: 'new-post', component: NewPostComponent, canActivate: [Authguard]},
  { path: 'albums', component: PhotoAlbumsComponent, canActivate: [Authguard]},
  { path: 'album/:id', component: PhotoAlbumComponent, canActivate: [Authguard]},
  { path: 'competitions', component: CompetitionsComponent, canActivate: [Authguard]},
  { path: 'competition/:id', component: CompetitionComponent, canActivate: [Authguard]},
  { path: 'members', component: MembersComponent, canActivate: [Authguard]},
  { path: 'member/:id', component: MemberComponent, canActivate: [Authguard]},
  { path: 'places', component: PlacesComponent, canActivate: [Authguard]},
  { path: 'place/:id', component: PlaceComponent, canActivate: [Authguard]},

  { path: 'resto/:table', component: MenusComponent, canActivate: [Authguard] },
  { path: 'resto', component: MenusComponent, canActivate: [Authguard] },
  { path: 'menu/:id', component: MenuComponent, canActivate: [Authguard] },
  { path: 'menu/confirm/:id', component: MenuConfirmComponent, canActivate: [Authguard] },
  { path: 'meal', component: MealComponent, canActivate: [Authguard] },
  { path: 'meal-checkout', component: MealCheckoutComponent, canActivate: [Authguard] },
  { path: 'meal-payment', component: MealPaymentComponent, canActivate: [Authguard] },
  { path: 'meal-unpaid', component: MealPaymentComponent, canActivate: [Authguard] },

  { path: 'streams', component: EverstreamsComponent, canActivate: [Authguard] },
  { path: 'streams2', component: EverstreamsComponent, canActivate: [Authguard] },
  { path: 'streams3', component: EverstreamsComponent, canActivate: [Authguard] },
  { path: 'streams4', component: EverstreamsComponent, canActivate: [Authguard] },
  { path: 'stream/:id', component: EverstreamComponent, canActivate: [Authguard] },
  { path: 'stream2/:id', component: EverstreamComponent, canActivate: [Authguard] },
  { path: 'stream3/:id', component: EverstreamComponent, canActivate: [Authguard] },
  { path: 'stream4/:id', component: EverstreamComponent, canActivate: [Authguard] },
  // else redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
