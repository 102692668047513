<app-toolbar page="leaderboard" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''"></app-toolbar>
<div class="form-list" [ngClass]="{tablet: menuType==2}" *ngIf="leaderboard$ | async as leaderboard">
  <table mat-table [dataSource]="leaderboard">
    <ng-container matColumnDef="name">
      <th mat-header-cell class="name" *matHeaderCellDef > {{ 'leaderboard.name' | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.getLastname()}} {{element.getFirstname()}} </td>
    </ng-container>
    <ng-container matColumnDef="brut">
      <th mat-header-cell class="brut" *matHeaderCellDef> {{ 'leaderboard.brut' | translate}} </th>
      <td mat-cell class="numbers" *matCellDef="let element"> {{element.getBrut()}} </td>
    </ng-container>
    <ng-container matColumnDef="net">
      <th mat-header-cell class="net" *matHeaderCellDef>{{ 'leaderboard.net' | translate}}</th>
      <td mat-cell class="numbers" *matCellDef="let element"> {{element.getNet()}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr [ngClass]="{'row-odd': ii % 2, 'row-even': (ii % 2) === 0}" mat-row *matRowDef="let myRowData; columns: columnsToDisplay; let ii = index"></tr>

  </table>




</div>
