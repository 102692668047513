import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { communityVersion } from '../../config/version';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.sass']
})
export class PromptComponent {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { mobileType: 'ios' | 'android', promptEvent?: any; },
    private bottomSheetRef: MatBottomSheetRef<PromptComponent>,
  ) {  }

  public installPwa(): void {
    this.data.promptEvent.prompt();
    this.close();
  }

  public close() {
    this.bottomSheetRef.dismiss();
  }
  get version() {
    const v = ((navigator.userAgent
          .match(/iphone[^\x0]+[ 0-9_]+[^\x0]+/gi) || [])[0] || '')
          .replace(/_/g, '.').replace(/[^0-9.]/g, '');
    return +((v.split('.') || [])[0]);
  }
  get agent() {
    return navigator.userAgent;
  }
  get isNew() {
    return +localStorage.getItem('v') !== communityVersion;
  }
  get isChrome() {
    return navigator.userAgent.indexOf('CriOS/') >= 0;
  }
  doReload() {
    localStorage.setItem('v', communityVersion + '');
    location.reload();
  }
}
