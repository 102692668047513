import { Component, OnInit } from '@angular/core';
import { Leaderboard } from 'src/app/libs/proto/commUnity_pb';
import { Router } from '@angular/router';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { GrpcLeaderboardLibService } from 'src/app/service/grpc/leaderboard/grpc-leaderboard-lib.service';
import { GrpcLibService } from 'src/app/service/grpc/grpc-lib.service';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.sass']
})
export class LeaderboardComponent implements OnInit {
  leaderboard$: Promise<Leaderboard[]>;
  columnsToDisplay = ['name', 'brut', 'net'];

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;


  constructor(
    private route: Router,
    private grpcLeaderboardLib: GrpcLeaderboardLibService,
    private userLib: UserLibService,
    private translate: TranslateService,
    private storeLib: StorageLibService,
    private detector: DeviceLibService,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  ngOnInit(): void {
    this.leaderboard$ = this.grpcLeaderboardLib.getLeaderboard({
      Offline: this.storeLib.cache.leaderboard || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }
  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }

  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }
}
