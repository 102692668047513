import { Component, OnInit } from '@angular/core';
import { Product, ProductOrder, ProductOrderLine, Cart } from '../../../libs/proto/shop_pb';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLibService } from '../../../service/user/user-lib.service';
import { GrpcProductLibService } from '../../../service/grpc/product/grpc-product-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { Color } from '../../../libs/proto/commUnity_pb';
import { environment } from '../../../../environments/environment';
import { GrpcShopLibService } from '../../../service/grpc/shop/grpc-shop-lib.service';
import { DialogServiceService } from '../../../service/dialog/dialog-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageLibService } from '../../../service/storage/storage-lib.service';
import { CartConversionService } from '../../../service/conversion/cart/cart-conversion.service';
import { GrpcRestaurantLibService } from '../../../service/grpc/restaurant/grpc-resto-lib.service';
import { Meal, RestaurantOrderLine, RestaurantProduct } from '../../../libs/proto/restaurant_pb';
import { RestaurantMealConversionService } from '../../../service/conversion/restaurant/resto-meal-conversion.service';

export interface MealForm {
  size?: string;
  color?: string;
  qtySelect?: string;
  qtyEdit?: string;
  qtyOK?: string;
  show?: boolean;

  lValue?: RestaurantOrderLine;
  line?: number;
  qElem?: HTMLSpanElement;
  tElem?: HTMLSpanElement;
}

@Component({
  selector: 'app-menu-confirm',
  templateUrl: './menu-confirm.component.html',
  styleUrls: ['./menu-confirm.component.sass']
})
export class MenuConfirmComponent implements OnInit {

  order: RestaurantOrderLine;
  mealForm: MealForm;
  product: RestaurantProduct;
  colors: {k?: string, v?: string} = {};
  colorL: {key: string, label: string}[] = [];

  showRelated = false;
  related: RestaurantProduct[] = [];
  /**
   * translations
   */
  T = {};

  constructor(
    private actRoute: ActivatedRoute,
    private userLib: UserLibService,
    private grpcRestoLib: GrpcRestaurantLibService,
    private translate: TranslateService,
    private dlgLib: DialogServiceService,
    private snackbar: MatSnackBar,
    private route: Router,
    private storeLib: StorageLibService,
    private convLib: RestaurantMealConversionService,
  ) { }

  ngOnInit(): void {
    const thise = this;
    this.actRoute.paramMap.subscribe( p => {
      thise.grpcRestoLib.getRestaurantProducts({
        Offline: true,
        call: {
          req: p.get('id'),
        }
      }).then( ns => {
        thise.product = ns[0];
        thise.order = new RestaurantOrderLine();
        thise.getRelatedProduct();
      });
    });

    this.mealForm = {
      qtySelect: '1'
    };
  }

  private getRelatedProduct() {
    this.related = [];
    this.product.getRelatedproductsList().forEach( p => {
      this.grpcRestoLib.getRestaurantProducts({
        Offline: true,
        call: {
          req: p,
        }
      }).then( ns => {
          if (ns[0]) { this.related.push( ns[0]); }
      }).catch( () => {});
    });
  }

  private setOrder() {
    this.order.setProductid( this.product.getId());
    this.order.setPhoto( (this.product.getImagesList() || [null])[0]);
    this.order.setProductname( this.product.getName());
    this.order.setAlcoholyn( this.product.getAlcoholyn());
    this.order.setQuantity( +this.orderQty );
    this.order.setTotalprice( +this.orderQty * this.product.getPrice());
  }

  get orderQty(): string {
    return this.mealForm.qtyOK || this.mealForm.qtySelect;
  }

  updateOrder() {
    if (this.mealForm.qtySelect === 'menu-confirm.Other') {
      this.mealForm.qtyOK = undefined;
      this.mealForm.show = true;
      return;
    }

    this.mealForm.qtyOK = undefined;
    this.order?.setQuantity( +this.orderQty);
  }
  get total(): number {
    return +this.orderQty * this.product?.getPrice();
  }

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }

  get diagnostic() {
    if (environment.production) { return; }
    return JSON.stringify(this.mealForm);
  }

  qtyClose() {
    this.mealForm.qtySelect = '1';
  }

  qtyOK() {
    this.mealForm.qtyOK = +this.mealForm.qtyEdit > 0 ? this.mealForm.qtyEdit : '0';
  }

  addProductToMeal() {
    const thise = this;
    this.setOrder();
    if (this.product.getQtyn()) {
      if (this.order.getQuantity() > this.product.getQt()) {

          this.translate.get([
            'meal.qty_over',
            'meal.title',
          ]).toPromise().then( t => {
            thise.dlgLib.show(t['meal.qty_over'].replace('%s', this.product.getQt()), t['meal.title']);
          });
          return;
      }
    }
    this.order.setMealid( this.userLib.mealID );
    this.grpcRestoLib.addProductToMeal(this.order).then( c => {

      thise.keepMeal(c);
      thise.addProductToMealOK();

    }).catch( e => {

      thise.addProductToMealError(e);
    });
  }
  private keepMeal(c: Meal) {
    this.storeLib.set('meal-badge', c.getNbitems());
    this.storeLib.set('meal',
      this.convLib.ToStorage( c ));
  }
  private addProductToMealOK() {
    const thise = this;
    if (this.related.length > 0) {
      this.showRelated = true;
      return;
    }
    this.translate.get([
      'meal.add_to_cart_ok',
    ]).toPromise().then( t => {
      thise.snackbar.open(t['meal.add_to_cart_ok'], null, {
        duration: 1000
      }).afterDismissed().toPromise().then( d => {
        thise.route.navigateByUrl('/resto');
      });
    });
  }
  private addProductToMealError(e: Error) {
    const thise = this;
    this.translate.get([
      'meal.add_to_cart_failed',
      'meal.title'
    ]).toPromise().then( t => {
      thise.dlgLib.show(t['meal.add_to_cart_failed'] +
                        ':' + JSON.stringify(e),
                        t['meal.title']);
    });
  }

  get product_qty() {
    if (this.product?.getQtyn()) {
       return this.product?.getQt();
    }

    return '';
  }
  closeRelated(ok?: boolean) {
    this.showRelated = false;
    this.route.navigateByUrl('/resto');
  }
}
