import { Injectable } from '@angular/core';
import { CountryMap, Country } from '../../../libs/proto/commUnity_pb';

@Injectable({
  providedIn: 'root'
})
export class CountryConversionService {

  private countryKeys?: Map<string, string>;
  constructor() { }

  getName(v: number): string {
    if (this.countryKeys === undefined) {
        const thise = this;
        this.countryKeys = new Map<string, string>();
        Object.keys(Country).map( (c, _) => {
          thise.countryKeys.set( Country[c] + '', c);
        });
    }
    return this.countryKeys.get(v + '');
  }
}
