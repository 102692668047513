<div *ngIf="data.mobileType === 'android'" color="primary" class="android-prompt">
  <button mat-button (click)="installPwa()">
    {{ 'pwa.android' | translate }}
  </button>
  <button mat-icon-button class="float-right" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div *ngIf="data.mobileType === 'ios'" class="ios-prompt">
  <button class="close-ios-inv float-right" mat-button color="white" (click)="close()"><mat-icon>close</mat-icon></button>
  <div *ngIf="!isChrome">
    <div>{{ 'pwa.ios' | translate }}</div>
    <div class="ios-buttons m-auto"><img src="/assets/images/ios-menu-btn.png"> <mat-icon>navigate_next</mat-icon> <img src="/assets/images/ios-add-btn.png"></div>
    <div *ngIf="isNew">
      {{ 'pwa.ios-new' | translate }} >
  </div>
  </div>

  <div *ngIf="isChrome">
    <div>{{ 'pwa.ios-chrome' | translate }}</div>
  </div>

  <button
    (click)="doReload()"
    mat-flat-button>
    <mat-icon>refresh</mat-icon>
    {{ 'pwa.reload' | translate }}
  </button>
</div>

