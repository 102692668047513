import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserLibService } from '../../user/user-lib.service';

export interface DialogComponentData {
  title: string;
  message: string;
  showYesNo: boolean;
  yes: boolean;
  no: boolean;
  yesText: string;
  noText: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.sass']
})
export class DialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    private userLib: UserLibService,
    @Inject(MAT_DIALOG_DATA) public data: DialogComponentData) { }

  ngOnInit(): void {
  }
  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }
  onYes() {
    this.data.yes = true;
    this.dialogRef.close(this.data);
  }
  onNo() {
    this.data.no = true;
    this.dialogRef.close(this.data);
  }
  onClose() {
    this.dialogRef.close();
  }
}
