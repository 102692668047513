import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GrpcPlaceLibService } from '../../service/grpc/place/grpc-place-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { UserLibService } from '../../service/user/user-lib.service';
import { Place } from '../../libs/proto/commUnity_pb';
import { Subscription } from 'rxjs';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { AddressConversionService } from '../../service/conversion/address/address-conversion.service';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import SwiperCore, { Navigation } from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation]);

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.sass']
})
export class PlaceComponent implements OnInit, OnDestroy {

  source: string;
  sourceId: string;
  sizeW = window.innerWidth;

  imageObject: Array<object> = [];
  imageSize: object = {
    width: this.sizeW - 20
  };

  eventI = 0;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;


  place: Place;
  private actRouter$: Subscription;

  constructor(
    private actRoute: ActivatedRoute,
    private grpcPlaceLib: GrpcPlaceLibService,
    private storeLib: StorageLibService,
    private userLib: UserLibService,
    private addressConvLib: AddressConversionService,
    private detector: DeviceLibService,
  ) { }

  ngOnInit(): void {
    const thise = this;

    this.actRouter$ = this.actRoute.paramMap.subscribe( p => {
      thise.source = p.get('source');
      thise.sourceId = p.get('sourceid');

      thise.grpcPlaceLib.getPlace({
        Offline: this.storeLib.cache.place || false,
        call: {
          req: p.get('id'),
        }
      }).then( ns => {
        thise.place = ns[0];
        thise.prepareImage();
      });
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
       this.isLandscape = this.landscapeEvt.matches;
       this.menuType = this.getmenuType();
    });
  }
  private prepareImage() {
    this.place.getImagesList().forEach( p => {
      this.imageObject.push(
        {
          image: p,
          thumbImage: p
        }
      );
    });
  }
  ngOnDestroy(): void {
    this.actRouter$.unsubscribe();
  }
  toIcon(t: string) {
    let img = t;
    switch (t) {
      default:
        img = t;
    }
    return '/assets/images/place/place_' + img + '.imageset.png';
  }

  toPlaceData(t: string, p: any): string {
    const pl = p as Place;
    if (pl) {
      switch (t) {
        case 'description':
          return pl.getDescription();

        case 'address':
          return this.addressConvLib.toAddressText(pl.getAddress());

        case 'phone':
          return pl.getAddress().getPhone();

        case 'mobile':
          return pl.getAddress().getMobile();

        case 'url':
          return pl.getUrl();

        case 'youtube':
          return pl.getYoutube();

        case 'facebook':
          return pl.getSocialnetwork().getFacebook();

        case 'twitter':
          return pl.getSocialnetwork().getTwitter();

        case 'pinterest':
          return pl.getSocialnetwork().getPinterest();

        case 'instagram':
          return pl.getSocialnetwork().getInstagram();

        case 'snapshat':
          return pl.getSocialnetwork().getSnapshat();

        case 'skype':
          return pl.getSocialnetwork().getSkype();

        case 'whatsapp':
          return pl.getSocialnetwork().getWhatsapp();

        case 'mail':
          return pl.getAddress().getEmail();

        case 'director':
          return pl.getDirector();

        case 'restaurant':
          return pl.getRestaurant();

        case 'architect':
          return pl.getArchitect();

        case 'year':
          return pl.getYearofcreation() ? pl.getYearofcreation() + '' : '';

        case 'area':
          return pl.getArea() ? pl.getArea() + '' : '';
      }
    }
  }
  action(t: string, p: any) {
    const pl = p as Place;
    if (pl) {
      switch (t) {
        case 'address':
          const loc = pl.getAddress().getLoc().getCoordinatesList();
          if (loc.length === 2) {
            window.open('https://www.google.com/maps/@' + loc[1] + ',' +
                loc[0] + ',15z');
          }
          break;
        case 'phone':
          window.open('tel:' + pl.getAddress().getPhone(), '_system');
          break;
        case 'mobile':
            window.open('tel:' + pl.getAddress().getMobile(), '_system');
            break;
        case 'facebook':
          window.open( 'https://www.facebook.com/' + pl.getSocialnetwork().getFacebook());
          break;
        case 'twitter':
          window.open( 'https://www.twitter.com/' + pl.getSocialnetwork().getTwitter());
          break;
        case 'pinterest':
          window.open( 'https://www.pinterest.com/pin/' + pl.getSocialnetwork().getPinterest());
          break;
        case 'linkedin':
          window.open( 'https://in.linkedin.com/in/' + pl.getSocialnetwork().getLinkedin());
          break;
        case 'url':
          window.open( pl.getUrl());
          break;
        case 'youtube':
          window.open( pl.getYoutube());
          break;
        case 'instagram':
          window.open( 'https://www.instagram.com/' + pl.getSocialnetwork().getInstagram());
          break;
        case 'mail':
          window.open( 'mailto://' + pl.getAddress().getEmail());
          break;
        }
    }
  }
  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }

}
