import { Component, OnInit, Directive, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserLibService } from '../../service/user/user-lib.service';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { communityConfig } from '../../config/app';
import { communityVersion, communityDate } from '../../config/version';
import { GrpcOrderLibService } from '../../service/grpc/order/grpc-order-lib.service';
import { PaymentStatus } from '../../libs/proto/shop_pb';
import { sanitizeIdentifier } from '@angular/compiler';
import { DomSanitizer } from '@angular/platform-browser';
import { OrderHistoryComponent } from '../order/order-history/order-history.component';
import { GrpcMenuLibService } from '../../service/grpc/menu/grpc-menu-lib.service';
import { Router } from '@angular/router';
import { NewsPathConverter } from '../../service/conversion/news/news-path-converter';
import { LinksPathConverter } from 'src/app/service/conversion/links/links-path-converter';

import { StreamPathConverter } from 'src/app/service/conversion/stream/stream-path-converter';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.sass']
})
export class ToolbarComponent implements OnInit {

  @Input() page: 'home' |
                 'disclaimer' |
                 'news' | 'new' |
                 'benefits' | 'benefit' |
                 'links' | 'link' |
                 'streams' | 'stream' |
                 'events' | 'event' |
                 'shop' | 'product' |
                 'product-confirm' | 'cart' | 'checkout' | 'payment' |
                 'account' |
                 'about' |
                 'cpsurls' |
                 'albums' | 'album' |
                 'places' | 'place' |
                 'forum' |
                 'members' | 'member-page' |
                 'leaderboard' |
                 'order-history' |
                 'competitions' |  'competition' |
                 'menus' |  'menu' | 'menu-confirm' | 'meal' | 'meal-checkout' | 'meal-payment' |
                 'search' = 'home';
  @ViewChild('titleContainer') titleContainer: ElementRef;
  @ViewChild('titleFront') titleFront: ElementRef;
  @ViewChild('titleBack') titleBack: ElementRef;

  private mtitle?: string;
  private atitle?: string;

  @Input() ml: string;
  @Input() mr: string;
  @Input() data: string;
  @Input() noCart: string;
  @Input() source: string;
  @Input() pageTitle: string;
  @Input() noMeal: string;
  @Output() closePay = new EventEmitter();
  @Output() eventsType = new EventEmitter();

  hasUnpaid = false;
  hasOrder = false;
  emptyBadge: any;
  isEventList = true;
  isTitleOverflow: boolean = false;

  scrollSpeed: number = 100;

  constructor(
    private translate: TranslateService,
    private userLib: UserLibService,
    private storeLib: StorageLibService,
    private snackbar: MatSnackBar,
    private grpcLib: GrpcOrderLibService,
    private route: Router,
    private grpcMenuLib: GrpcMenuLibService,
    private newsLib: NewsPathConverter,
    private linksLib: LinksPathConverter,
    private streamLib: StreamPathConverter,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  getMenuAlias() {
    switch (this.page) {
      case 'about':
          return this.userLib.Data.menuAlias?.getAboutus();
      case 'album':
      case 'albums':
        return this.userLib.Data.menuAlias?.getAlbum();
      case 'benefit':
      case 'benefits':
        return this.userLib.Data.menuAlias?.getBenefit();
      case 'competition':
      case 'competitions':
        return this.userLib.Data.menuAlias?.getCompetition();
      case 'event':
      case 'events':
        return this.userLib.Data.menuAlias?.getEvent();
      case 'forum':
        return this.userLib.Data.menuAlias?.getForum();
      case 'leaderboard':
        return this.userLib.Data.menuAlias?.getLeaderboard();
      case 'link':
      case 'links':
        return this.menuLinkTitle;
      case 'stream':
      case 'streams':
        return this.menuStreamTitle;
      case 'new':
      case 'news':
        return this.menuNewsTitle;
      case 'place':
      case 'places':
        return this.userLib.Data.menuAlias?.getPlace();
      case 'shop':
        return this.userLib.Data.menuAlias?.getShop();
      case 'menus':
        return this.userLib.Data.menuAlias?.getRestaurant();
      default:
          return '';
    }
  }
  private get menuLinkTitle() {
    switch (this.linksLib.linksIndex) {
      case '2': return this.userLib.Data.menuAlias.getLink2();
      case '3': return this.userLib.Data.menuAlias.getLink3();
      case '4': return this.userLib.Data.menuAlias.getLink4();
      default : return this.userLib.Data.menuAlias.getLink();
    }
  }
  private get menuStreamTitle() {
    switch (this.streamLib.StreamIndex) {
      case '2': return this.userLib.Data.menuAlias.getStream2();
      case '3': return this.userLib.Data.menuAlias.getStream3();
      case '4': return this.userLib.Data.menuAlias.getStream4();
      default : return this.userLib.Data.menuAlias.getStream();
    }
  }
  private get menuNewsTitle() {
    switch (this.newsLib.newsIndex) {
      case '2': return this.userLib.Data.menuAlias.getNews2();
      case '3': return this.userLib.Data.menuAlias.getNews3();
      case '4': return this.userLib.Data.menuAlias.getNews4();
      default : return this.userLib.Data.menuAlias.getNews();
    }
  }
  private callMenuAlias(){
    this.grpcMenuLib.getMenuAlias({
      Offline: true,
    }).then( v => {
      this.userLib.Data.menuAlias = v;
    });
  }
  ngOnInit(): void {
    this.callMenuAlias();
    this.translate.get([
      this.page + '.title',
      'about.title',
    ]).toPromise().then( t => {
      this.mtitle = this.getMenuAlias() || t[this.page + '.title'];
      this.atitle = this.userLib.Data.menuAlias?.getAboutus() || t['about.title'];
    });

    this.check_product_order();
    this.check_meal_order();
  }

  ngAfterViewChecked(): void {
    const titleContainerElem = this.titleContainer?.nativeElement;
    if (titleContainerElem?.offsetWidth < titleContainerElem?.scrollWidth) {
      this.isTitleOverflow = true;

      // Calculate animation duration
      let duration: number = titleContainerElem?.scrollWidth / this.scrollSpeed;

      // Set the speed duration based on the animation duration
      if (this.titleFront?.nativeElement && this.titleBack?.nativeElement) {
        this.renderer.setStyle(this.titleFront?.nativeElement, 'animation-duration', `${duration}s`);
        this.renderer.setStyle(this.titleBack?.nativeElement, 'animation-duration', `${duration}s`);
      }
    } else {
      this.isTitleOverflow = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  private check_meal_order() {
    if (['menu',
        'meal',
        'menu-confirm',
        'menus'].indexOf(this.page) < 0) { return ; }

    // this.grpcLib.getProductOrders({
    //   Offline: this.storeLib.cache.cart || false,
    // }).then( oo => {
    //   this.hasOrder = oo.length > 0;
    //   for (const o of oo) {
    //     if (OrderHistoryComponent.canPay(o)) {
    //        this.storeLib.set('order-badge', 'Y');
    //        this.hasUnpaid = true;
    //        break;
    //     }
    //   }
    // });
  }
  private check_product_order() {
    if (['product',
        'shop',
        'product-confirm',
        'cart'].indexOf(this.page) < 0) { return ; }
    if (this.userLib.Data.token?.getProfile().getIsguest()) { return; }

    this.grpcLib.getProductOrders({
      Offline: this.storeLib.cache.cart || false,
    }).then( oo => {
      this.hasOrder = oo.length > 0;
      for (const o of oo) {
        if (OrderHistoryComponent.canPay(o)) {
           this.storeLib.set('order-badge', 'Y');
           this.hasUnpaid = true;
           break;
        }
      }
    });
  }
  has_table_from_scan() {
    return (this.storeLib.get('table-id') || '') !== '';
  }
  showBack() {
    return ['new',
            'event',
            'benefit',
            'album',
            'competition',
            'search',
            'product',
            'link',
            'links',
            'stream',
            'streams',
            'product-confirm',
            'checkout',
            'payment',
            'place',
            'places',
            'order-history',
            'cart',
            'news',
            'benefits',
            'events',
            'albums',
            'members',
            'member-page',
            'shop',
            'account',
            'forum',
            'leaderboard',
            'competitions',
            'menu',
            'menu-confirm',
            'meal',
            'meal-checkout',
            'menus' + (this.has_table_from_scan() ? 'x' : ''),
            'about'].indexOf(this.page) >= 0;
  }
  private backURLNews() {
    return '/news' + this.newsLib.newsIndex;
  }
  private backURLLinks() {
    return '/links' + this.linksLib.linksIndex;
  }
  private backURLStream() {
    return '/streams' + this.streamLib.StreamIndex;
  }
  backURL() {
    if (this.page === 'new') { return this.backURLNews(); }
    if (this.page === 'link') { return this.backURLLinks(); }
    if (this.page === 'stream') { return this.backURLStream(); }
    if (this.page === 'member-page') { return '/members'; }
    if (this.page === 'event') { return '/events'; }
    if (this.page === 'album') { return '/albums'; }
    if (this.page === 'benefit') {return '/benefits'; }
    if (this.page === 'cart' && this.noCart) { return '/order/history'; }
    if (this.page === 'order-history') { return '/shop'; }
    if (this.page === 'competition') { return '/competitions'; }
    if (this.page === 'menus') { return '/'; }

    if (['menu',
         'meal',
         'meal-checkout'].indexOf(this.page) >= 0) { return '/resto'; }
    if (this.page === 'menu-confirm' && this.data) { return '/menu/' + this.data; }

    if (['product',
         'cart',
         'payment',
         'checkout'].indexOf(this.page) >= 0) { return '/shop'; }
    if (this.page === 'product-confirm' && this.data) { return '/product/' + this.data; }

    if (this.page === 'place' && (this.source || '') !== '') {
      if (this.source === 'about') { return '/about'; }
      return '/' + this.source + '/' + this.data;
    } else if (this.page === 'place') {
      return '/places';
    }
  }

  get title() {
    if (this.page === 'place' && (this.source || '') === 'about') {
      return this.atitle;
    }
    return this.pageTitle || this.mtitle;
  }

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }

  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }

  hasCart(): boolean{
    return ((this.noCart || '') === '') && (+this.cartBadge) &&
            !this.userLib.Data.token?.getProfile().getIsguest() &&
           ['product',
            'shop',
            'product-confirm',
            'cart'].indexOf(this.page) >= 0;
  }
  get cartBadge(): string {
    return this.storeLib.get('cart-badge');
  }
  hasMeal(): boolean{
    return ((this.noMeal || '') === '') && (+this.mealBadge) &&
           ['menu',
            'menus',
            'menu-confirm',
            'meal'].indexOf(this.page) >= 0;
  }
  get mealBadge(): string {
    return this.storeLib.get('table-id') ? this.storeLib.get('meal-badge') : '';
  }
  showUnpaid() {
    return this.hasUnpaid && this.page === 'shop';
  }
  showVersion() {
      this.snackbar.open( 'version ' +
                          communityConfig.version +
                          ' build ' + communityVersion +
                          ' (' + communityDate + ')'
                          , 'X', {
                            duration: 1000
                          });
  }
  showEventCalendarIcon() {
    return this.isEventList;
  }
  showEventListIcon() {
    return !this.isEventList;
  }
  showEventCalendar() {
    this.isEventList = false;
    this.eventsType.emit('event.calendar');
  }
  showEventList() {
    this.isEventList = true;
    this.eventsType.emit('event.list');
  }

  get menuAlias() {
    return this.userLib.Data.menuAlias;
  }
  get meal_unpaid() {
    return this.route.url.indexOf('/meal-unpaid') >= 0;
  }
  topayment() {
    this.closePay.emit();
  }
  show_search() {
    return (['album',
             'albums',
             'benefit',
             'benefits',
             'shop',
             'product',
             'product-confirm',
             'members',
             'member-page',
             'new',
             'news',
             'place',
             'places'
        ].indexOf(this.page) >= 0);
  }
  show_cart() {
    return (['shop',
             'product',
             'product-confirm',
           ].indexOf(this.page) >= 0);
  }
  show_meal() {
    return (['menus',
             'menu',
             'menu-confirm',
           ].indexOf(this.page) >= 0);
  }
}

