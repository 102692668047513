import { Component, OnInit } from '@angular/core';
import { Cart, ProductOrderLine, ProductOrder, PaymentStatus } from '../../../libs/proto/shop_pb';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLibService } from '../../../service/user/user-lib.service';
import { StorageLibService } from '../../../service/storage/storage-lib.service';
import { CartConversionService } from '../../../service/conversion/cart/cart-conversion.service';
import { GrpcShopLibService } from '../../../service/grpc/shop/grpc-shop-lib.service';
import { GrpcLibService } from '../../../service/grpc/grpc-lib.service';
import { of } from 'rxjs';
import { DialogServiceService } from '../../../service/dialog/dialog-service.service';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';
import { MatCard } from '@angular/material/card';
import { GrpcOrderLibService } from '../../../service/grpc/order/grpc-order-lib.service';
import { Color } from '../../../libs/proto/commUnity_pb';
import { ProductToRemove } from '../../../libs/proto/mobile_pb';
import { OrderForm } from '../confirm/product-confirm.component';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.sass']
})
export class CartComponent implements OnInit {

  cart: Cart;
  showQty?: OrderForm;
  reOrder?: boolean;
  reOrderId?: string;
  rePaymentURL?: string;
  payLoading?: boolean;
  colors: {k?: string, v?: string} = {};
  T = {};
  tLists: string[] = [];

  constructor(
    private route: Router,
    private actRoute: ActivatedRoute,
    private storeLib: StorageLibService,
    private userLib: UserLibService,
    private convLib: CartConversionService,
    private grpcOrerLib: GrpcOrderLibService,
    private grpcLib: GrpcShopLibService,
    private dlgLib: DialogServiceService,
    private translate: TranslateService,
    private decPipe: DecimalPipe,
    private platform: Platform
  ) { }

  ngOnInit(): void {
    const thise = this;
    Object.keys(Color).map( c => {
      this.colors[Color[c]] = c;
      this.tLists.push('colors.' + c);
    });

    this.actRoute.paramMap.subscribe( p => {
      if (p.get('id')) {
          thise.loadReOrder(p.get('id'));
      } else {
          thise.loadCart();
      }
    });
    this.showQty = {};
  }

  private loadCart() {
    const thise = this;
    this.grpcLib.getCart({
      Offline: this.storeLib.cache.cart || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        thise.userLib.clear();
        thise.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    }).then( (c: Cart) => {
      thise.cart = c;

      // translate
      this.translate.get(this.tLists).toPromise().then( t => {
      this.T = t;
      });

    });
  }

  private loadReOrder(id: string) {
    this.grpcOrerLib.getProductOrders({
      Offline: true,
      call: {
        req: id,
      }
    }).then( ns => {
      if (ns.length === 0) {
        this.loadCart();
      } else {
        this.copyCart(ns[0]);
      }
    }).catch( e => {
      this.loadCart();
    });

  }

  private copyCart(o: ProductOrder) {
    this.reOrder = true;
    this.reOrderId = o.getId();
    this.cart = new Cart();
    this.cart.setProductsList( o.getProductsList() );
    this.cart.setTotalprice( o.getTotalprice() );
    this.cart.setNbitems( o.getNbitems() );
    if (o.getPaymentstatus() === PaymentStatus.UNPAID) {
    this.rePaymentURL = o.getPaymenturl();
    }
  }

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }

  get cartItems(): number {
    return this.cart.getNbitems();
  }

  qtyOK() {
    if (+this.showQty?.qtyEdit === 0) {return; }
    this.addProductToCart(
        this.showQty?.lValue,
        this.showQty?.qtyEdit,
        this.showQty?.qElem,
        this.showQty?.tElem);
  }

  addProductToCart(line: ProductOrderLine, q: string,
                   qtyElem: HTMLSpanElement,
                   totalElem: HTMLSpanElement) {
    if (q === 'product-confirm.Other') {
      this.showQty.lValue = line;
      this.showQty.qtyEdit = line.getQuantity() + '';
      this.showQty.qElem = qtyElem;
      this.showQty.tElem =  totalElem;
      this.showQty.show = true;

      return;
    }

    const thise = this;
    line.setQuantity( +q - line.getQuantity());

    this.grpcLib.addProductToCart(line).then( c => {

      thise.keepCart(c);
      thise.cart = c;
      thise.addProductToCartOK(c, line, qtyElem, totalElem);

    }).catch( e => {

      thise.addProductToCartError(e);
    });
  }

  private addProductToCartOK(c: Cart,
                             l: ProductOrderLine,
                             qtyElem: HTMLSpanElement,
                             totalElem: HTMLSpanElement) {
          this.ngOnInit();

  }

  private addProductToCartError(e: Error) {
    const thise = this;
    this.translate.get([
      'shop.add_to_cart_failed',
      'shop.title'
    ]).toPromise().then( t => {
      thise.dlgLib.show(t['shop.add_to_cart_failed'] +
                        ':' + JSON.stringify(e),
                        t['shop.title']);
    });
  }

  deleteItem(line: ProductOrderLine) {
    const thise = this;
    this.translate.get([
      'cart.title',
      'cart.delete',
      'cart.delete_ko'
    ]).toPromise().then( t => {

      thise.dlgLib.confirm(t['cart.delete'], r => {

        if (r.no) { return; }
        const req = new ProductToRemove();
        req.setProductid(line.getProductid());
        req.setColor(line.getColor());
        req.setSize(line.getSize());

        thise.grpcLib.dropProductFromCart(req).then( c => {

          thise.keepCart(c);
          if (c.getNbitems() === 0) {
              thise.route.navigateByUrl('/shop');
              return;
           }
          thise.ngOnInit();

        }).catch( e => {
          thise.dlgLib.show(t['cart.delete_ko'], t['cart.title']);
        });

      }, t['cart.title']);
    });
  }

  private keepCart(c: Cart) {
    this.storeLib.set('cart-badge', c.getNbitems());
    this.storeLib.set('cart',
      this.convLib.ToStorage( c ));
  }

  clickButton() {
    if (this.rePaymentURL) {
      this.payLoading = true;
      window.location.href = this.rePaymentURL;
      return;
    }

    if (this.reOrder) {
      this.doReOrder();
      return;
    }

    this.route.navigateByUrl('/checkout');
  }

  private doReOrder() {
    const thise = this;
    this.grpcLib.resetCart(this.reOrderId).then( c => {

      thise.keepCart( c );
      thise.route.navigateByUrl('/shop');

    }).catch( e => {
      this.translate.get([
        'cart.title',
        'order-history.reset_cart_failed',
      ]).toPromise().then( t => {

        this.dlgLib.show(t['order-history.reset_cart_failed'],
                         t['cart.title']);
      });
    });
  }
  get butttonTag(): string {
    if (this.rePaymentURL) { return 'order-history.order_pay'; }
    if (this.reOrder) { return 'order-history.reorder'; }

    return 'cart.checkout';
  }

  ColorText(c: number) {
    return this.T['colors.' + this.colors[c]];
  }
  get isSafari() {
    return this.platform.SAFARI;
  }
}
