import { Injectable } from '@angular/core';
import { communityConfig } from '../../config/app';
import { MobApp } from '../../libs/proto/mobile_pb';
import { MobileApp } from '../../libs/proto/commUnity_pb';
import { communityAppType } from '../../config/type';
import { Platform } from '@angular/cdk/platform';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class DeviceLibService {

  constructor(
    private detector: DeviceDetectorService,
    private platform: Platform
  ) {  }

  isMobile() {
    let ismobile = this.detector.isMobile();
    if (ismobile && !this.platform.SAFARI) {
        if (this.detector.userAgent.toLowerCase().indexOf('mobile') === -1) {
          ismobile = false;
        }
    }
    return ismobile;
  }
  get orientation() {
    return this.detector.orientation;
  }
}
