<app-toolbar page="meal" noCart="{{ reOrder ? 'Y' : '' }}"></app-toolbar>

<div class="form-list with-bottom">
  <mat-action-list [class]="'cart-list'" *ngFor="let line of meal?.getProductsList()">
    <mat-card class="card-bottom">
      <div class="cart-table">
        <div class="cart-row">
          <div mat-card-avatar class="cart-col cart-col1 item-img"
            [ngStyle]="{'background-image': 'url(' + line.getPhoto() + ')'}" >
          </div>
          <div class="cart-col cart-col2">
            <div class="cart-title"> {{ line.getProductname() }}</div>
            <div class="cart-price">
            <span #totalElem>
              {{ (line.getTotalprice() | number:'1.2-2')?.replace(',',' ').replace('.',',') }}
            </span> &euro;</div>
          </div>
          <div class="cart-col cart-col3 right-box">
            <span class="qty">
              <button class=""
                [disabled]="reOrder"
                [matMenuTriggerFor]="reOrder ? empBox : qtyBox">
                <span [ngClass]="{'fix-safari': isSafari}" #qtyElem>{{ line.getQuantity() }}</span>
              </button>
            </span>
            <mat-menu #empBox ></mat-menu>
            <mat-menu #qtyBox xPosition="before">
              <button (click)="addProductToMeal(line, s, qtyElem, totalElem)"
              *ngFor="let s of '1,2,3,4,5,product-confirm.Other'.split(',')"
              mat-menu-item>{{ s | translate }}</button>
            </mat-menu>
            <div class="delete" *ngIf="!reOrder">
              <button (click)="deleteItem(line)">
                <img src="/assets/images/trash.png" />
              </button>
            </div>
          </div>
        </div>
        <div style="margin-top: 5px;" *ngIf="line.getRelatedproductname()">
          <mat-icon>attach_file</mat-icon>
          <label style="position: absolute;">{{ line.getRelatedproductname() }}</label>
        </div>
    </div>
    </mat-card>

  </mat-action-list>
</div>


<div class="cart-bottom">
  <div class="bg"></div>
  <div class="bottom-table">
  <div class="row">
    <div class="col col1">
      <span>
      {{ meal?.getNbitems() }}
      {{ ('meal.item' + (meal?.getNbitems() > 1 ? 's': '')) | translate }}
      </span>
    </div>
    <div class="col col2">
      <span>
      {{ ((meal?.getTotalprice() | number:'1.2-2') || '')
          .replace(',', ' ').replace('.',',') }} &euro;
      </span>
    </div>
    <div class="col col3">
      <button mat-button class="checkout-button" (click)="clickButton()" >
        {{ butttonTag  | translate }}
      </button>
    </div>
  </div>
</div>
</div>

<app-ctl-qty-dialog
  [showQty]="showQty"
  (okclick)="qtyOK()"
  *ngIf="showQty"></app-ctl-qty-dialog>

<div class="pay-loading" *ngIf="payLoading">
  <div class="bg"></div>
  <mat-spinner class="wait"></mat-spinner>
</div>
