import { Component, OnInit, AfterViewInit, AfterContentChecked, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLibService } from '../../../service/user/user-lib.service';
import { GrpcLibService } from '../../../service/grpc/grpc-lib.service';
import { fromEvent, of } from 'rxjs';
import { StorageLibService } from '../../../service/storage/storage-lib.service';
import { GrpcRestaurantLibService } from '../../../service/grpc/restaurant/grpc-resto-lib.service';
import { RestaurantProduct, RestaurantSettings } from '../../../libs/proto/restaurant_pb';
import { DialogServiceService } from '../../../service/dialog/dialog-service.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocationClass } from '../../../class/location/location';
import { desktopMode } from '../../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.sass']
})
export class MenusComponent implements OnInit, AfterContentChecked {

  products$: Promise<RestaurantProduct[]>;
  defaultCollapse: string;
  onceScroll = false;

  restName = '';
  restClose = '';
  tableCode = '';
  showWaiterButton = true;
  waiterText = '';
  inLocation = false;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;

  constructor(
    private route: Router,
    private grpcRestoLib: GrpcRestaurantLibService,
    private userLib: UserLibService,
    private storeLib: StorageLibService,
    private actRoute: ActivatedRoute,
    private dlgLib: DialogServiceService,
    private translate: TranslateService,
    private snackbar: MatSnackBar,
    private detector: DeviceLibService,
  ) { }

  isCollapsed(c: string) {
    if (this.defaultCollapse) { return this.defaultCollapse === 'y'; }
    return this.userLib.Data.restaurantPage?.collapsed?.indexOf(c) >= 0;
  }

  private check_location(p: RestaurantSettings) {
    const loc = new LocationClass();
    loc.checkLocation({
      forceLocation: true,
      positions: [p.getLongitude(), p.getLatitude()],
      radius: p.getRadius()
    }).then( r => {
      if (r.ok) { this.inLocation = true; }
    }).catch( () => {});
  }

  ngOnInit(): void {

    this.actRoute.paramMap.subscribe( p => {
      // reset when scan new table
      if (p.get('table')) { this.storeLib.set('table-id', p.get('table')); }
    });

    this.grpcRestoLib.getRestaurantSettings({
      Offline: this.storeLib.cache.restaurantSettings || false,
    }).then( s => {
      this.restName = s.getName();
      this.restClose = s.getServiceclosedtext();
      this.waiterText = s.getCallwaitertext();

      if (this.userLib.Data.restaurantPage?.collapsed) {

      } else {
        this.defaultCollapse = s.getDefaultcollapse() ? 'y' : 'n';
      }

      this.check_location(s);
      this.getTable();
    }).catch( (e: Error) => {
      if (this.fail_redirect(e)) { return; }
      // return to complete
      return of([]).toPromise();
    });

    // hide waiter button
    fromEvent(window, 'scroll').pipe(
      tap(() => this.showWaiterButton = false),
      debounceTime(100)
    ).subscribe(() => {
        this.showWaiterButton = true;
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }

  private getTable() {
    if ((this.storeLib.get('table-id') || '') === '') {
      this.getMenus();
      return;
    }

    this.grpcRestoLib.getTable({
      Offline: this.storeLib.cache.restaurantTable || false,
      call: {
        req: this.storeLib.get('table-id'),
      }
    }).then( s => {
      if (s && s.length > 0 && s[0].getId()) {
          this.tableCode = s[0].getCode();
      } else {
        this.storeLib.set('table-id', '');
      }
      this.getMenus();
    }).catch( (e: Error) => {
      if (this.fail_redirect(e)) { return; }

      if (e.message === 'Restaurant not serving now') {
          this.translate.get([
            'meal-checkout.ko-no-serve'
          ]).toPromise().then( t => {
            this.storeLib.set('table-id', '');
            this.dlgLib.show(this.restClose || t['meal-checkout.ko-no-serve'],
                             this.restName);
          });
          this.getMenus();
          return of([]).toPromise();
      }

      // return to complete
      return of([]).toPromise();
    });
  }

  private fail_redirect(e: Error) {
    if (e.message === GrpcLibService.ERR_SIGIN) {

      this.userLib.clear();
      this.route.navigateByUrl('/login');
      return true;
    }
    return false;
  }

  checkVisible(elm: HTMLElement) {
    if (!elm) { return true; }
    const rect = elm.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  private scrollToPrev() {
    const c = document.getElementById(this.userLib.Data.producstPage?.id);
    if (this.checkVisible(c)) { return; }

    c?.scrollIntoView();
  }

  ngAfterContentChecked() {
    if (this.onceScroll) { return; }

    this.scrollToPrev();
  }

  private getMenus() {
    if (this.storeLib.get('rproduct-s')) {
        this.storeLib.set('rproduct-s', null);
        this.storeLib.cache.product = false;
    }
    this.products$ = this.grpcRestoLib.getRestaurantProducts({
      Offline: this.storeLib.cache.restaurantProduct || false,
    }).then( pp => {
      if (this.defaultCollapse === 'y') {
        for (const p of pp) {
          this.expanded(p.getCategoryid(), 2);
        }
        this.defaultCollapse = undefined;
      }
      return of(pp).toPromise();
    }).catch( (e: Error) => {
      if (this.fail_redirect(e)) { return; }

      // return to complete
      return of([]).toPromise();
    });
  }

  open(id: string){
    this.route.navigate(['/menu', id]);
  }

  get mealBadge() {
    return this.storeLib.get('meal-badge');
  }
  clearScrollFlag() {
    console.log('scroll...');
    this.onceScroll = true;
  }
  expanded(id: string, t: number){
    if (t !== 2) { this.onceScroll = true; }
    if (this.userLib.Data.restaurantPage === undefined) {
      this.userLib.Data.restaurantPage = {};
    }
    if (this.userLib.Data.restaurantPage.collapsed === undefined ){
      this.userLib.Data.restaurantPage.collapsed = [];
    }
    const ii = this.userLib.Data.restaurantPage?.collapsed.indexOf(id);
    if (t === 1) {
       this.userLib.Data.restaurantPage?.collapsed.splice(ii, 1);
    } else {
      if (ii === -1) {
        this.userLib.Data.restaurantPage?.collapsed.push(
        id
        );
      }
    }
  }
  call_waiter() {
    this.translate.get([
      'menus.confirm-waiter',
      'menus.waiter-called'
    ]).toPromise().then( t => {

      this.dlgLib.confirm(t['menus.confirm-waiter'], (r) => {

        if (r.no) { return; }

        this.do_call_waiter(t);
      }, this.restName);
    });
  }
  do_call_waiter(t: any) {
    this.grpcRestoLib.callWaiter(this.storeLib.get('table-id')).then( () => {
      this.snackbar.open(this.waiterText || t['menus.waiter-called'], '', { duration: 1500});
    }).catch( () => {
    });
  }
  get has_table(){
    return (this.storeLib.get('table-id') || '') !== '';
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }
 }
