import { Component, OnInit, OnDestroy, SecurityContext, AfterViewChecked } from '@angular/core';
import { GrpcNewsLibService } from '../../service/grpc/news/grpc-news-lib.service';
import { Stream, News } from '../../libs/proto/commUnity_pb';
import { TranslateService } from '@ngx-translate/core';
import { UserLibService } from '../../service/user/user-lib.service';
import { ActivatedRoute } from '@angular/router';
import { Subscribable, Subscription } from 'rxjs';
import { GrpcLibServiceOption } from '../../service/grpc/grpc-lib.service';
import { NewsPathConverter } from '../../service/conversion/news/news-path-converter';
import { GrpcStreamLibService } from 'src/app/service/grpc/stream/grpc-stream-lib.service';
import { StreamPathConverter } from 'src/app/service/conversion/stream/stream-path-converter';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';

declare let start_play: any;

@Component({
  selector: 'app-everstream',
  templateUrl: './everstream.component.html',
  styleUrls: ['./everstream.component.sass']
})
export class EverstreamComponent implements OnInit, OnDestroy {

  private Everstream: Stream;
  private actRouter$: Subscription;
  private murl = '';
  autostart = false;
  ptitle = '';

  constructor(
    private grpcEverstreamsLib: GrpcStreamLibService,
    private translate: TranslateService,
    private userLib: UserLibService,
    private actRoute: ActivatedRoute,
    private everstreamsLib: StreamPathConverter,
    private httpService: HttpClient,
    private domSan: DomSanitizer,
    private platform: Platform
  ) {
    userLib.setPageLanguage( this.translate );
  }

  get url() {
    return this.murl;
  }

  ngOnInit(): void {
    const thise = this;
    this.actRouter$ = this.actRoute.paramMap.subscribe( p => {
      thise.everstreamsLib.fnGetStream({
        Offline: true,
        call: {
          req: p.get('id'),
        }
      }).then( ns => {
        thise.Everstream = ns[0];
        thise.murl = thise.Everstream.getUrl();
        thise.autostart = thise.Everstream.getAutostart();
        thise.ptitle = thise.Everstream.getName();
        console.log(thise.murl);
        // if (!(thise.isChrome && thise.platform.ANDROID)) { return; }
        // thise.httpService.get(thise.Everstream.getUrl() + '&redirect=no').subscribe((d:any) => {
        //   thise.murl = d.url;
        //   console.log(thise.murl);
        // });
        const ccast = thise.Everstream.getShowchromecast();
        start_play(thise.murl, thise.autostart, ccast);
      });
    });
  }

  get isChromeOrAndroid() {
    return navigator.userAgent.indexOf('CriOS/') >= 0 || this.platform.ANDROID;
  }

  ngOnDestroy(): void {
    this.actRouter$.unsubscribe();
  }

  get dateFormat(): string {
    return this.userLib.dateFormat;
  }
}
