import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductOrderLine, ProductOrder, PaymentStatus } from '../../../libs/proto/shop_pb';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLibService } from '../../../service/user/user-lib.service';
import { StorageLibService } from '../../../service/storage/storage-lib.service';
import { DialogServiceService } from '../../../service/dialog/dialog-service.service';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';
import { GrpcOrderLibService } from '../../../service/grpc/order/grpc-order-lib.service';
import { Color } from '../../../libs/proto/commUnity_pb';
import { ProductToRemove } from '../../../libs/proto/mobile_pb';
import { Platform } from '@angular/cdk/platform';
import { MealForm } from '../confirm/menu-confirm.component';
import { RestaurantMealConversionService } from '../../../service/conversion/restaurant/resto-meal-conversion.service';
import { Meal, RestaurantOrderLine, RestaurantProduct } from '../../../libs/proto/restaurant_pb';
import { GrpcRestaurantLibService } from '../../../service/grpc/restaurant/grpc-resto-lib.service';
import { GrpcLibService } from '../../../service/grpc/grpc-lib.service';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-related-product',
  templateUrl: './related-product.component.html',
  styleUrls: ['./related-product.component.sass']
})
export class RelatedProductComponent implements OnInit {

  @Output() closeRelated = new EventEmitter();
  @Input() product: RestaurantProduct;
  @Input() set products(v: RestaurantProduct[]) {
    this.mproducts = v;
    this.lines = [];
    this.qtys = [];
    this.products.forEach( p => {
      const l = new RestaurantOrderLine();

      l.setProductname( p.getName());
      l.setPhoto( p.getImagesList()[0]);
      l.setProductid(p.getId());
      l.setTotalprice( p.getPrice());
      l.setQuantity(1);
      l.setAlcoholyn( p.getAlcoholyn());

      this.lines.push(l);
      this.qtys.push('1');
    });
  }
  get products() {
    return this.mproducts;
  }
  T = {};
  tLists: string[] = [];
  showQty?: MealForm;

  mproducts: RestaurantProduct[] = [];
  qtys: string[] = [];
  lines: RestaurantOrderLine[] = [];

  constructor(
    private route: Router,
    private actRoute: ActivatedRoute,
    private storeLib: StorageLibService,
    private userLib: UserLibService,
    private convLib: RestaurantMealConversionService,
    private grpcOrerLib: GrpcOrderLibService,
    private grpcLib: GrpcRestaurantLibService,
    private dlgLib: DialogServiceService,
    private translate: TranslateService,
    private snackbar: MatSnackBar,
    private platform: Platform
  ) { }

  ngOnInit(): void {}

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }
  get isSafari() {
    return this.platform.SAFARI;
  }
  addProductToMeal(line: RestaurantOrderLine, q: string,
                   qtyElem: HTMLSpanElement,
                   totalElem: HTMLSpanElement) {
    line.setMealid(this.userLib.mealID);
    line.setRelatedproductid(this.product.getId());
    line.setRelatedproductname(this.product.getName());
    this.grpcLib.addProductToMeal(line).then( c => {

      this.keepMeal(c);
      this.addProductToMealOK(c, line, qtyElem, totalElem);

    }).catch( e => {

      this.addProductToMealError(e);
    });
  }
  private keepMeal(c: Meal) {
    this.storeLib.set('meal-badge', c.getNbitems());
    this.storeLib.set('meal',
      this.convLib.ToStorage( c ));
  }
  private addProductToMealOK(c: Meal,
                             l: RestaurantOrderLine,
                             qtyElem: HTMLSpanElement,
                             totalElem: HTMLSpanElement) {
      this.translate.get([
        'meal.add_to_cart_ok',
      ]).toPromise().then( t => {
        this.snackbar.open(t['meal.add_to_cart_ok'], null, {
          duration: 1000
        });
    });
  }

  close(ok?: boolean) {
    this.closeRelated.emit(ok);
  }

  private addProductToMealError(e: Error) {
    const thise = this;
    this.translate.get([
      'meal.add_to_Meal_failed',
      'meal.title'
    ]).toPromise().then( t => {
      thise.dlgLib.show(t['meal.add_to_Meal_failed'] +
                        ':' + JSON.stringify(e),
                        t['meal.title']);
    });
  }
  addProductToQty(line: number, q: string, qtyElem: HTMLSpanElement,
                  totalElem: HTMLSpanElement) {
    if (q === 'menu-confirm.Other') {
      this.showQty = {};
      this.showQty.line = line;
      this.showQty.qtyEdit = this.qtys[line];
      this.showQty.qElem = qtyElem;
      this.showQty.tElem =  totalElem;
      this.showQty.show = true;

      return;
    }

    this.qtys[line] = q;
    this.lines[line].setQuantity(+q);
    this.lines[line].setTotalprice( this.products[line].getPrice() * +q);
  }
  qtyOK() {
    if (+this.showQty?.qtyEdit === 0) {return; }
    this.addProductToQty(
        this.showQty?.line,
        this.showQty?.qtyEdit,
        this.showQty?.qElem,
        this.showQty?.tElem);
  }
}
