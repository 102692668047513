import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'pipeDatefmt' })
export class DatefmtPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      return moment(value, 'YYYYMMDD').format('DD/MM/YYYY');
    } else {
      return '';
    }
  }
}
