import { Component, OnInit, Input } from '@angular/core';
import { OrderInfo, DeliveryMethod, Cart, DeliveryAddress, CheckOut, DeliveryMethodMap, ProductOrder } from '../../../libs/proto/shop_pb';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { GrpcShopLibService } from '../../../service/grpc/shop/grpc-shop-lib.service';
import { StorageLibService } from '../../../service/storage/storage-lib.service';
import { GrpcLibService } from '../../../service/grpc/grpc-lib.service';
import { UserLibService } from '../../../service/user/user-lib.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { DialogRole } from '@angular/material/dialog';
import { DialogServiceService } from '../../../service/dialog/dialog-service.service';
import { TranslateService } from '@ngx-translate/core';
import { MatListItem } from '@angular/material/list';
import { Address, Country } from '../../../libs/proto/commUnity_pb';
import { OrderConversionService } from '../../../service/conversion/order/order-conversion.service';
import { OrderInfoConversionService } from '../../../service/conversion/order/order-info-conversion.service';
import { CountryConversionService } from '../../../service/conversion/country/country-conversion.service';
import { AddressConversionService } from '../../../service/conversion/address/address-conversion.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';

interface OrderForm {
  type: string;
  delay?: boolean;
  date: string;
  dateV: moment.Moment;
  note?: string;

  pickupLoading?: boolean;
  pickupMsg?: string;

  deliveryLoading?: boolean;
  deliveryPrice?: number;

  addressLoading?: boolean;
  addressShow: boolean;
  addresses?: DeliveryAddress[];
  address?: DeliveryAddress;

  addressAdd?: boolean;
  addressForm: {
    name?: string;
    street?: string;
    city?: string;
    postcode?: string;
    phone?: string;
  };

  confirmLoading?: boolean;
}
const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.sass'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class CheckoutComponent implements OnInit {

  private cart: Cart;
  orderForm: OrderForm;
  canDelivery = false;
  canPickup = false;
  canDelay = false;

  constructor(
    private route: Router,
    private grpcLib: GrpcShopLibService,
    private storeLib: StorageLibService,
    private userLib: UserLibService,
    private decPipe: DecimalPipe,
    private dlgLib: DialogServiceService,
    private translate: TranslateService,
    private dateAdp: DateAdapter<Date>,
    private convLib: OrderConversionService,
    private addressConvLib: AddressConversionService
  ) { }

  ngOnInit(): void {
    const thise = this;

    if (!this.validateLastPayment()) { return; }

    this.dateAdp.setLocale(this.userLib.Data.lg || 'en');
    thise.orderForm = {
      type: '',
      date: moment().format('DD.MM.YYYY'),
      dateV: moment(),
      deliveryLoading: true,
      addressLoading: true,
      addressShow: false,
      pickupLoading: false,
      addressForm: {}
    };

    this.grpcLib.getShopSettings().then( s => {
      thise.orderForm.dateV.add(s.getMindelaydelivery(), 'day' );
      thise.orderForm.date = thise.orderForm.dateV.format('DD.MM.YYYY');
      thise.orderForm.pickupMsg = s.getPickupmessage() || '-';

      thise.canDelivery = s.getCandeliver();
      thise.canPickup = s.getCanpickup();
      thise.canDelay = s.getCanscheduledelivery();

      if (!thise.canDelivery && thise.canPickup) {
        thise.orderForm.type = DeliveryMethod.PICKUP + '';
      } else {
        thise.orderForm.type = DeliveryMethod.DELIVERY + '';
      }

      thise.getCart();
    });

  }
  private validateLastPayment() {

    if (this.storeLib.get('pay-id')) {
       this.route.navigateByUrl('/payment?orderid' + this.storeLib.get('pay-id'));
       return;
    }


    return true;
  }
  private getCart(){
    const thise = this;

    this.grpcLib.getCart({
      Offline: this.storeLib.cache.cart || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        thise.userLib.clear();
        thise.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    }).then( (c: Cart) => {
      thise.cart = c;
      if (c.getProductsList().length === 0) {
        thise.route.navigateByUrl('/shop');
        return;
      }
    });

    thise.loadAddress();
    thise.loadDelivery();
  }

  private loadAddress() {
    this.orderForm.addressLoading = true;
    this.grpcLib.getDeliveryAddresses().then( ds => {
      this.orderForm.addressLoading = false;
      this.orderForm.addresses = ds;
    });
  }

  private loadDelivery() {
    this.orderForm.deliveryLoading = true;
    this.grpcLib.getDeliveryCost().then( p => {
      this.orderForm.deliveryLoading = false;
      this.orderForm.deliveryPrice = p.getDeliverycost();
    });
  }

  get isDelvery() {
    return this.orderForm.type === DeliveryMethod.DELIVERY + '';
  }

  get isPickup() {
    return this.orderForm.type === DeliveryMethod.PICKUP + '';
  }

  get waitPickup() {
    return this.orderForm.pickupLoading;
  }
  get deliveryCost() {
    return (this.decPipe.transform( this.orderForm.deliveryPrice || 0
                                    , '1.2-2'))?.replace(/,/g, ' ')
                                    .replace('.', ',');
  }
  private get Total() {
    return this.cart?.getTotalprice() + this.orderForm.deliveryPrice || 0;
  }
  get TotalText() {
    return (this.decPipe.transform( this.Total
                                  , '1.2-2'))?.replace(/,/g, ' ')
                                  .replace('.', ',');
  }

  changeType() {
    this.orderForm.deliveryPrice = 0;
    if (this.isDelvery) {
      this.loadDelivery();
      this.loadAddress();
      return;
    }

    this.loadPickupMsg();
  }

  private loadPickupMsg() {

  }
  selectAddress() {
    if (this.orderForm.addresses.length === 0) { return; }

    this.orderForm.addressShow = true;
  }

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }
  deleteAddress(e: MouseEvent, a: string, me: any) {
    e.stopPropagation();

    const thise = this;
    this.translate.get([
      'select_addr.delete_addr_title',
      'select_addr.delete_addr',
    ]).toPromise().then( t => {

      thise.dlgLib.confirm(t['select_addr.delete_addr'], (r => {

        if (r.no) { return; }

        thise.removeAddress(a, me);

      }), t['select_addr.delete_addr_title']);
    });
  }

  removeAddress(id: string, item: MatListItem){
    const thise = this;
    this.grpcLib.dropDeliveryAddress(id).catch( e => {
      thise.translate.get([
        'select_addr.delete_addr_title',
        'select_addr.delete_addr_ko',
      ]).toPromise().then( t => {
        thise.dlgLib.show(t['select_addr.delete_addr_ko'],
                          t['select_addr.delete_addr_title']);
      });
    }).then( v => {
      // reload list
      item?._getHostElement().remove();
      thise.loadAddress();
      if (thise.orderForm.addresses.length === 0) {
        this.orderForm.addressShow = false;
        this.orderForm.address = null;
      }
    });
  }

  chooseAddress(a: DeliveryAddress) {
    this.orderForm.addressShow = false;
    this.orderForm.address = a;
  }


  addAddress() {
    this.orderForm.addressForm.phone =
      this.userLib.Data.token?.getProfile().getProfessionaladdress().getMobile() ||
      this.userLib.Data.token?.getProfile().getProfessionaladdress().getPhone();
    this.orderForm.addressAdd = true;
  }
  cancelAdd() {
    this.orderForm.addressAdd = false;
  }
  doAdd(){
    const req = new DeliveryAddress();
    req.setName(this.orderForm.addressForm.name);
    const reqa = new Address();
    reqa.setStreet(this.orderForm.addressForm.street);
    reqa.setCity(this.orderForm.addressForm.city);
    reqa.setPostcode(this.orderForm.addressForm.postcode);
    reqa.setMobile(this.orderForm.addressForm.phone);

    req.setAddress(reqa);
    const thise = this;
    this.grpcLib.addDeliveryAddress(req).catch( e => {
      thise.translate.get([
        'add_addr.title',
        'add_addr.add_addr_ko',
      ]).toPromise().then( t => {
        thise.dlgLib.show(t['add_addr.add_addr_ko'],
                          t['add_addr.title']);
      });
    }).then( v => {
      thise.orderForm.addressAdd = false;
      thise.orderForm.addressForm = {};
      thise.loadAddress();
    });
  }
  get canAdd(): boolean {
    return ((this.orderForm?.addressForm.name || '') !== '') &&
           ((this.orderForm?.addressForm.street || '') !== '') &&
           ((this.orderForm?.addressForm.city || '') !== '') &&
           ((this.orderForm?.addressForm.postcode || '') !== '') &&
           (this.orderForm?.addressForm.phone.trim().length >= 9);
  }
  checkOut() {
    this.orderForm.confirmLoading = true;
    const req = new CheckOut();
    req.setDeliveryaddress( this.orderForm.address );
    req.setDeliverymethod( +this.orderForm.type as 0|1 );
    req.setNote( this.orderForm.note );

    const retURL = window.location.href.replace('/checkout', '/payment');
    req.setUrlok( retURL );
    req.setUrlnotok( retURL );

    if (this.orderForm.delay) {
      req.setScheduleddeliverydate( +this.orderForm.dateV.format('YYYYMMDD'));
    }
    const thise = this;
    this.grpcLib.checkOutCart(req).then( o => {
      thise.checkoutOK(o);
    })
    .catch( e => {
      thise.orderForm.confirmLoading = false;
      thise.translate.get([
        'checkout.title',
        'checkout.ko',
      ]).toPromise().then( t => {
        thise.dlgLib.show(t['checkout.ko'],
                          t['checkout.title']);
      });

    });
  }
  private checkoutOK(o: OrderInfo) {
    const thise = this;

    this.storeLib.set('cart', null);
    this.storeLib.set('cart-badge', null);

    if (o.getPaymenturl()) {
      this.showPayment(o);
      return;
    }

    this.translate.get([
      'checkout.title',
      'payment.msg_unpaid',
    ]).toPromise().then( t => {
      thise.storeLib.cache.cart = undefined;
      thise.dlgLib.show(t['payment.msg_unpaid'].replace('%s', o.getOrdernumber()),
                        t['checkout.title'], r => {
          thise.route.navigateByUrl('/shop');
      });
    });
  }

  private showPayment(o: OrderInfo) {
    this.storeLib.set('pay-id', o.getOrderid());
    window.location.href = o.getPaymenturl();
  }
  get selectAddressText() {
    return this.addressConvLib.toAddressText( this.orderForm.address?.getAddress() );
  }
}
