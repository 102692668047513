import { SignUpMobileRequest } from '../../libs/proto/mobile_pb';

export class SignupForm {

  email?: string;
  firstName?: string;
  lastName?: string;
  mobile?: string;
  customerId: string;

  toSignUpMobileRequest(): SignUpMobileRequest {
    const req = new SignUpMobileRequest();
    req.setEmail(this.email);
    req.setFirstname(this.firstName);
    req.setLastname(this.lastName);
    let mb = this.mobile;
    // remove +33
    mb = mb.replace(/^\+33/, '')
    mb = mb.replace(/\+/g, '')

    req.setMobile('+33' + mb);
    req.setCustomerid(this.customerId);
    return req;
  }
}

