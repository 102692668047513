import { Component, OnInit } from '@angular/core';
import { Product, ProductOrder, ProductOrderLine, Cart } from '../../../libs/proto/shop_pb';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLibService } from '../../../service/user/user-lib.service';
import { GrpcProductLibService } from '../../../service/grpc/product/grpc-product-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { Color } from '../../../libs/proto/commUnity_pb';
import { environment } from '../../../../environments/environment';
import { GrpcShopLibService } from '../../../service/grpc/shop/grpc-shop-lib.service';
import { DialogServiceService } from '../../../service/dialog/dialog-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageLibService } from '../../../service/storage/storage-lib.service';
import { CartConversionService } from '../../../service/conversion/cart/cart-conversion.service';

export interface OrderForm {
  size?: string;
  color?: string;
  qtySelect?: string;
  qtyEdit?: string;
  qtyOK?: string;
  show?: boolean;

  lValue?: ProductOrderLine;
  qElem?: HTMLSpanElement;
  tElem?: HTMLSpanElement;
}

@Component({
  selector: 'app-product-confirm',
  templateUrl: './product-confirm.component.html',
  styleUrls: ['./product-confirm.component.sass']
})
export class ProductConfirmComponent implements OnInit {

  order: ProductOrderLine;
  orderForm: OrderForm;
  product: Product;
  colors: {k?: string, v?: string} = {};
  colorL: {key: string, label: string}[] = [];
  /**
   * translations
   */
  T = {};

  constructor(
    private actRoute: ActivatedRoute,
    private userLib: UserLibService,
    private grpcProductLib: GrpcProductLibService,
    private grpcShopLib: GrpcShopLibService,
    private translate: TranslateService,
    private dlgLib: DialogServiceService,
    private snackbar: MatSnackBar,
    private route: Router,
    private storeLib: StorageLibService,
    private convLib: CartConversionService,
  ) { }

  ngOnInit(): void {
    const thise = this;
    this.actRoute.paramMap.subscribe( p => {
      thise.grpcProductLib.getProducts({
        Offline: true,
        call: {
          req: p.get('id'),
        }
      }).then( ns => {
        thise.product = ns[0];
        thise.loadColors();
      });
    });

    this.orderForm = {
      qtySelect: '1'
    };
  }
  private loadColors(){
    // keep key
    const tLists: string[] = [];
    Object.keys(Color).map( c => {
      if (c !== 'COLOR_NO') {
        this.colors[Color[c]] = c;
        tLists.push('colors.' + c);
      }
    });

    const thise = this;
    // translate
    this.translate.get(tLists).toPromise().then( t => {
      thise.T = t;
      thise.colorL = thise.colorList(thise.product);
      if (thise.orderForm.color === undefined) {
        thise.orderForm.color = (thise.colorL || [{}])[0].key;
      }
    });

    if (thise.product) { thise.orderForm.size = (thise.product.getSizesList() || [''])[0]; }
    this.setOrder();
  }

  private setOrder() {
    this.order = new ProductOrderLine();
    this.order.setProductid( this.product.getId());
    this.order.setPhoto( (this.product.getImagesList() || [null])[0]);
    this.order.setProductname( this.product.getName());

    this.order.setQuantity( +this.orderQty );
    this.order.setColor( Color[this.colors[this.orderForm.color]] );
    this.order.setSize( this.orderForm.size );

    this.order.setTotalprice( +this.orderQty * this.product.getPrice());
  }

  get orderQty(): string {
    return this.orderForm.qtyOK || this.orderForm.qtySelect;
  }

  updateOrder() {
    if (this.orderForm.qtySelect === 'product-confirm.Other') {
      this.orderForm.qtyOK = undefined;
      this.orderForm.show = true;
      return;
    }

    this.orderForm.qtyOK = undefined;
    this.order.setQuantity( +this.orderQty);
  }
  get total(): number {
    return +this.orderQty * this.product?.getPrice();
  }

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }

  private colorList(p?: Product): {key: string, label: string}[] {
    return p?.getColorsList().map( v => (
      {key: v + '', label: this.T['colors.' + this.colors[v]] }
    ));
  }
  get diagnostic() {
    if (environment.production) { return; }
    return JSON.stringify(this.orderForm);
  }

  qtyClose() {
    this.orderForm.qtySelect = '1';
  }

  qtyOK() {
    this.orderForm.qtyOK = this.orderForm.qtyEdit;
  }

  addProductToCart() {
    const thise = this;
    this.setOrder();
    if (this.product.getQtyn()) {
      if (this.order.getQuantity() > this.product.getQt()) {

          this.translate.get([
            'shop.qty_over',
            'shop.title',
          ]).toPromise().then( t => {
            thise.dlgLib.show(t['shop.qty_over'].replace('%s', this.product.getQt()), t['shop.title']);
          });
          return;
      }
    }

    this.grpcShopLib.addProductToCart(this.order).then( c => {

      thise.keepCart(c);
      thise.addProductToCartOK();

    }).catch( e => {

      thise.addProductToCartError(e);
    });
  }
  private keepCart(c: Cart) {
    this.storeLib.set('cart-badge', c.getNbitems());
    this.storeLib.set('cart',
      this.convLib.ToStorage( c ));
  }
  private addProductToCartOK() {
    const thise = this;
    this.translate.get([
      'shop.add_to_cart_ok',
    ]).toPromise().then( t => {
      thise.snackbar.open(t['shop.add_to_cart_ok'], null, {
        duration: 1000
      }).afterDismissed().toPromise().then( d => {
        thise.route.navigateByUrl('/shop');
      });
    });
  }
  private addProductToCartError(e: Error) {
    const thise = this;
    this.translate.get([
      'shop.add_to_cart_failed',
      'shop.title'
    ]).toPromise().then( t => {
      thise.dlgLib.show(t['shop.add_to_cart_failed'] +
                        ':' + JSON.stringify(e),
                        t['shop.title']);
    });
  }

  get product_qty() {
    if (this.product.getQtyn()) {
       return this.product.getQt();
    }

    return '';
  }
}
