// source: commUnity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.commUnity.Ad', null, global);
goog.exportSymbol('proto.commUnity.Address', null, global);
goog.exportSymbol('proto.commUnity.Album', null, global);
goog.exportSymbol('proto.commUnity.Alert', null, global);
goog.exportSymbol('proto.commUnity.Amenities', null, global);
goog.exportSymbol('proto.commUnity.Attendee', null, global);
goog.exportSymbol('proto.commUnity.Attendee.AttendeeTypeCase', null, global);
goog.exportSymbol('proto.commUnity.Attending', null, global);
goog.exportSymbol('proto.commUnity.Benefit', null, global);
goog.exportSymbol('proto.commUnity.BusinessCard', null, global);
goog.exportSymbol('proto.commUnity.ChgPasswordRequest', null, global);
goog.exportSymbol('proto.commUnity.Color', null, global);
goog.exportSymbol('proto.commUnity.Country', null, global);
goog.exportSymbol('proto.commUnity.Customer', null, global);
goog.exportSymbol('proto.commUnity.CustomizedMenu', null, global);
goog.exportSymbol('proto.commUnity.DesktopMenuType', null, global);
goog.exportSymbol('proto.commUnity.Disclaimer', null, global);
goog.exportSymbol('proto.commUnity.Document', null, global);
goog.exportSymbol('proto.commUnity.DocumentCategory', null, global);
goog.exportSymbol('proto.commUnity.EmailRequest', null, global);
goog.exportSymbol('proto.commUnity.EmailUsMem', null, global);
goog.exportSymbol('proto.commUnity.Empty', null, global);
goog.exportSymbol('proto.commUnity.Event', null, global);
goog.exportSymbol('proto.commUnity.EventType', null, global);
goog.exportSymbol('proto.commUnity.Font', null, global);
goog.exportSymbol('proto.commUnity.FullTextSearchRequest', null, global);
goog.exportSymbol('proto.commUnity.Group', null, global);
goog.exportSymbol('proto.commUnity.Guest', null, global);
goog.exportSymbol('proto.commUnity.GuestRequest', null, global);
goog.exportSymbol('proto.commUnity.GuestText', null, global);
goog.exportSymbol('proto.commUnity.Language', null, global);
goog.exportSymbol('proto.commUnity.Leaderboard', null, global);
goog.exportSymbol('proto.commUnity.Link', null, global);
goog.exportSymbol('proto.commUnity.Loc', null, global);
goog.exportSymbol('proto.commUnity.LoginTemplate', null, global);
goog.exportSymbol('proto.commUnity.Member', null, global);
goog.exportSymbol('proto.commUnity.MemberProfileSettings', null, global);
goog.exportSymbol('proto.commUnity.MemberRemoveRequest', null, global);
goog.exportSymbol('proto.commUnity.MemberType', null, global);
goog.exportSymbol('proto.commUnity.MenuAlias', null, global);
goog.exportSymbol('proto.commUnity.MenuName', null, global);
goog.exportSymbol('proto.commUnity.MobileApp', null, global);
goog.exportSymbol('proto.commUnity.MobileGroupMenuType', null, global);
goog.exportSymbol('proto.commUnity.MobileMenuType', null, global);
goog.exportSymbol('proto.commUnity.News', null, global);
goog.exportSymbol('proto.commUnity.OS', null, global);
goog.exportSymbol('proto.commUnity.Place', null, global);
goog.exportSymbol('proto.commUnity.PoloSize', null, global);
goog.exportSymbol('proto.commUnity.RequestID', null, global);
goog.exportSymbol('proto.commUnity.RequestMembers', null, global);
goog.exportSymbol('proto.commUnity.SignUpToken', null, global);
goog.exportSymbol('proto.commUnity.SocialNetwork', null, global);
goog.exportSymbol('proto.commUnity.Stream', null, global);
goog.exportSymbol('proto.commUnity.TemplateMail', null, global);
goog.exportSymbol('proto.commUnity.Title', null, global);
goog.exportSymbol('proto.commUnity.YesNo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.FullTextSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.FullTextSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.FullTextSearchRequest.displayName = 'proto.commUnity.FullTextSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.GuestText = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.GuestText, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.GuestText.displayName = 'proto.commUnity.GuestText';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.GuestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.GuestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.GuestRequest.displayName = 'proto.commUnity.GuestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.MemberRemoveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.MemberRemoveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.MemberRemoveRequest.displayName = 'proto.commUnity.MemberRemoveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.ChgPasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.ChgPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.ChgPasswordRequest.displayName = 'proto.commUnity.ChgPasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.EmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.EmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.EmailRequest.displayName = 'proto.commUnity.EmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.SignUpToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.SignUpToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.SignUpToken.displayName = 'proto.commUnity.SignUpToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.YesNo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.YesNo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.YesNo.displayName = 'proto.commUnity.YesNo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Loc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Loc.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Loc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Loc.displayName = 'proto.commUnity.Loc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Place = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Place.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Place, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Place.displayName = 'proto.commUnity.Place';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Attendee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.commUnity.Attendee.oneofGroups_);
};
goog.inherits(proto.commUnity.Attendee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Attendee.displayName = 'proto.commUnity.Attendee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Guest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.Guest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Guest.displayName = 'proto.commUnity.Guest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Benefit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Benefit.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Benefit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Benefit.displayName = 'proto.commUnity.Benefit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Ad = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Ad.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Ad, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Ad.displayName = 'proto.commUnity.Ad';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.News = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.News.repeatedFields_, null);
};
goog.inherits(proto.commUnity.News, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.News.displayName = 'proto.commUnity.News';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.SocialNetwork = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.SocialNetwork, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.SocialNetwork.displayName = 'proto.commUnity.SocialNetwork';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Leaderboard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.Leaderboard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Leaderboard.displayName = 'proto.commUnity.Leaderboard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Alert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Alert.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Alert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Alert.displayName = 'proto.commUnity.Alert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.RequestID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.RequestID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.RequestID.displayName = 'proto.commUnity.RequestID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Group = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.Group, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Group.displayName = 'proto.commUnity.Group';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Customer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.commUnity.Customer.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Customer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Customer.displayName = 'proto.commUnity.Customer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.TemplateMail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.TemplateMail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.TemplateMail.displayName = 'proto.commUnity.TemplateMail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.RequestMembers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.RequestMembers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.RequestMembers.displayName = 'proto.commUnity.RequestMembers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Empty.displayName = 'proto.commUnity.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Album = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Album.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Album, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Album.displayName = 'proto.commUnity.Album';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Member = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Member.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Member, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Member.displayName = 'proto.commUnity.Member';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.EventType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.EventType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.EventType.displayName = 'proto.commUnity.EventType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Event.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Event.displayName = 'proto.commUnity.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Address.displayName = 'proto.commUnity.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Disclaimer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.Disclaimer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Disclaimer.displayName = 'proto.commUnity.Disclaimer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.MemberType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.MemberType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.MemberType.displayName = 'proto.commUnity.MemberType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.MemberProfileSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.MemberProfileSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.MemberProfileSettings.displayName = 'proto.commUnity.MemberProfileSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Link = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Link.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Link, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Link.displayName = 'proto.commUnity.Link';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Stream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Stream.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Stream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Stream.displayName = 'proto.commUnity.Stream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.CustomizedMenu = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.CustomizedMenu.repeatedFields_, null);
};
goog.inherits(proto.commUnity.CustomizedMenu, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.CustomizedMenu.displayName = 'proto.commUnity.CustomizedMenu';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.MenuAlias = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.MenuAlias, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.MenuAlias.displayName = 'proto.commUnity.MenuAlias';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.BusinessCard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.BusinessCard.repeatedFields_, null);
};
goog.inherits(proto.commUnity.BusinessCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.BusinessCard.displayName = 'proto.commUnity.BusinessCard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Document.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Document.displayName = 'proto.commUnity.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.DocumentCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.DocumentCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.DocumentCategory.displayName = 'proto.commUnity.DocumentCategory';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.FullTextSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.FullTextSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.FullTextSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.FullTextSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.FullTextSearchRequest}
 */
proto.commUnity.FullTextSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.FullTextSearchRequest;
  return proto.commUnity.FullTextSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.FullTextSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.FullTextSearchRequest}
 */
proto.commUnity.FullTextSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.FullTextSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.FullTextSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.FullTextSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.FullTextSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Text = 1;
 * @return {string}
 */
proto.commUnity.FullTextSearchRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.FullTextSearchRequest} returns this
 */
proto.commUnity.FullTextSearchRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.GuestText.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.GuestText.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.GuestText} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.GuestText.toObject = function(includeInstance, msg) {
  var f, obj = {
    welcome: jspb.Message.getFieldWithDefault(msg, 1, ""),
    presentation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    signup: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.GuestText}
 */
proto.commUnity.GuestText.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.GuestText;
  return proto.commUnity.GuestText.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.GuestText} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.GuestText}
 */
proto.commUnity.GuestText.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWelcome(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPresentation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.GuestText.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.GuestText.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.GuestText} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.GuestText.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWelcome();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPresentation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSignup();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string Welcome = 1;
 * @return {string}
 */
proto.commUnity.GuestText.prototype.getWelcome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.GuestText} returns this
 */
proto.commUnity.GuestText.prototype.setWelcome = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Presentation = 2;
 * @return {string}
 */
proto.commUnity.GuestText.prototype.getPresentation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.GuestText} returns this
 */
proto.commUnity.GuestText.prototype.setPresentation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string SignUp = 3;
 * @return {string}
 */
proto.commUnity.GuestText.prototype.getSignup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.GuestText} returns this
 */
proto.commUnity.GuestText.prototype.setSignup = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.GuestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.GuestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.GuestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.GuestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    guest: (f = msg.getGuest()) && proto.commUnity.Guest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.GuestRequest}
 */
proto.commUnity.GuestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.GuestRequest;
  return proto.commUnity.GuestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.GuestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.GuestRequest}
 */
proto.commUnity.GuestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventid(value);
      break;
    case 2:
      var value = new proto.commUnity.Guest;
      reader.readMessage(value,proto.commUnity.Guest.deserializeBinaryFromReader);
      msg.setGuest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.GuestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.GuestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.GuestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.GuestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGuest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.commUnity.Guest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string EventID = 1;
 * @return {string}
 */
proto.commUnity.GuestRequest.prototype.getEventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.GuestRequest} returns this
 */
proto.commUnity.GuestRequest.prototype.setEventid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Guest Guest = 2;
 * @return {?proto.commUnity.Guest}
 */
proto.commUnity.GuestRequest.prototype.getGuest = function() {
  return /** @type{?proto.commUnity.Guest} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.Guest, 2));
};


/**
 * @param {?proto.commUnity.Guest|undefined} value
 * @return {!proto.commUnity.GuestRequest} returns this
*/
proto.commUnity.GuestRequest.prototype.setGuest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.GuestRequest} returns this
 */
proto.commUnity.GuestRequest.prototype.clearGuest = function() {
  return this.setGuest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.GuestRequest.prototype.hasGuest = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.MemberRemoveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.MemberRemoveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.MemberRemoveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MemberRemoveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    memberid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.MemberRemoveRequest}
 */
proto.commUnity.MemberRemoveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.MemberRemoveRequest;
  return proto.commUnity.MemberRemoveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.MemberRemoveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.MemberRemoveRequest}
 */
proto.commUnity.MemberRemoveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.MemberRemoveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.MemberRemoveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.MemberRemoveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MemberRemoveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string eventID = 1;
 * @return {string}
 */
proto.commUnity.MemberRemoveRequest.prototype.getEventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MemberRemoveRequest} returns this
 */
proto.commUnity.MemberRemoveRequest.prototype.setEventid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string memberID = 2;
 * @return {string}
 */
proto.commUnity.MemberRemoveRequest.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MemberRemoveRequest} returns this
 */
proto.commUnity.MemberRemoveRequest.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.ChgPasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.ChgPasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.ChgPasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ChgPasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldpwd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newpwd: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.ChgPasswordRequest}
 */
proto.commUnity.ChgPasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.ChgPasswordRequest;
  return proto.commUnity.ChgPasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.ChgPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.ChgPasswordRequest}
 */
proto.commUnity.ChgPasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldpwd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewpwd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.ChgPasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.ChgPasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.ChgPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ChgPasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldpwd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewpwd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string oldPwd = 1;
 * @return {string}
 */
proto.commUnity.ChgPasswordRequest.prototype.getOldpwd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ChgPasswordRequest} returns this
 */
proto.commUnity.ChgPasswordRequest.prototype.setOldpwd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string newPwd = 2;
 * @return {string}
 */
proto.commUnity.ChgPasswordRequest.prototype.getNewpwd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ChgPasswordRequest} returns this
 */
proto.commUnity.ChgPasswordRequest.prototype.setNewpwd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.EmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.EmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.EmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.EmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    emailusmem: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.EmailRequest}
 */
proto.commUnity.EmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.EmailRequest;
  return proto.commUnity.EmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.EmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.EmailRequest}
 */
proto.commUnity.EmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {!proto.commUnity.EmailUsMem} */ (reader.readEnum());
      msg.setEmailusmem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.EmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.EmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.EmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.EmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmailusmem();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.commUnity.EmailRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.EmailRequest} returns this
 */
proto.commUnity.EmailRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EmailUsMem EmailUsMem = 2;
 * @return {!proto.commUnity.EmailUsMem}
 */
proto.commUnity.EmailRequest.prototype.getEmailusmem = function() {
  return /** @type {!proto.commUnity.EmailUsMem} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.commUnity.EmailUsMem} value
 * @return {!proto.commUnity.EmailRequest} returns this
 */
proto.commUnity.EmailRequest.prototype.setEmailusmem = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.SignUpToken.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.SignUpToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.SignUpToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.SignUpToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.SignUpToken}
 */
proto.commUnity.SignUpToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.SignUpToken;
  return proto.commUnity.SignUpToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.SignUpToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.SignUpToken}
 */
proto.commUnity.SignUpToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.SignUpToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.SignUpToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.SignUpToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.SignUpToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.commUnity.SignUpToken.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SignUpToken} returns this
 */
proto.commUnity.SignUpToken.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.YesNo.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.YesNo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.YesNo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.YesNo.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.YesNo}
 */
proto.commUnity.YesNo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.YesNo;
  return proto.commUnity.YesNo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.YesNo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.YesNo}
 */
proto.commUnity.YesNo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOk(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.YesNo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.YesNo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.YesNo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.YesNo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool ok = 1;
 * @return {boolean}
 */
proto.commUnity.YesNo.prototype.getOk = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.YesNo} returns this
 */
proto.commUnity.YesNo.prototype.setOk = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Loc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Loc.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Loc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Loc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Loc.toObject = function(includeInstance, msg) {
  var f, obj = {
    coordinatesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    type: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Loc}
 */
proto.commUnity.Loc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Loc;
  return proto.commUnity.Loc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Loc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Loc}
 */
proto.commUnity.Loc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCoordinates(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Loc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Loc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Loc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Loc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoordinatesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated double coordinates = 1;
 * @return {!Array<number>}
 */
proto.commUnity.Loc.prototype.getCoordinatesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.commUnity.Loc} returns this
 */
proto.commUnity.Loc.prototype.setCoordinatesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Loc} returns this
 */
proto.commUnity.Loc.prototype.addCoordinates = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Loc} returns this
 */
proto.commUnity.Loc.prototype.clearCoordinatesList = function() {
  return this.setCoordinatesList([]);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.commUnity.Loc.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Loc} returns this
 */
proto.commUnity.Loc.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Place.repeatedFields_ = [12,18,105];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Place.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Place.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Place} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Place.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: (f = msg.getAddress()) && proto.commUnity.Address.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    url: jspb.Message.getFieldWithDefault(msg, 7, ""),
    socialnetwork: (f = msg.getSocialnetwork()) && proto.commUnity.SocialNetwork.toObject(includeInstance, f),
    accommodation: jspb.Message.getFieldWithDefault(msg, 11, ""),
    imagesList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    restaurant: jspb.Message.getFieldWithDefault(msg, 13, ""),
    shortdescription: jspb.Message.getFieldWithDefault(msg, 15, ""),
    architect: jspb.Message.getFieldWithDefault(msg, 16, ""),
    yearofcreation: jspb.Message.getFieldWithDefault(msg, 17, 0),
    amenitiesList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    area: jspb.Message.getFieldWithDefault(msg, 20, 0),
    length: jspb.Message.getFieldWithDefault(msg, 21, 0),
    youtube: jspb.Message.getFieldWithDefault(msg, 22, ""),
    director: jspb.Message.getFieldWithDefault(msg, 23, ""),
    ispickupplace: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
    membertypesList: (f = jspb.Message.getRepeatedField(msg, 105)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Place}
 */
proto.commUnity.Place.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Place;
  return proto.commUnity.Place.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Place} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Place}
 */
proto.commUnity.Place.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.commUnity.Address;
      reader.readMessage(value,proto.commUnity.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 10:
      var value = new proto.commUnity.SocialNetwork;
      reader.readMessage(value,proto.commUnity.SocialNetwork.deserializeBinaryFromReader);
      msg.setSocialnetwork(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccommodation(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addImages(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setRestaurant(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortdescription(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setArchitect(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYearofcreation(value);
      break;
    case 18:
      var values = /** @type {!Array<!proto.commUnity.Amenities>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAmenities(values[i]);
      }
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setArea(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLength(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setYoutube(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirector(value);
      break;
    case 100:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspickupplace(value);
      break;
    case 105:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembertypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Place.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Place.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Place} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Place.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.commUnity.Address.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSocialnetwork();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.commUnity.SocialNetwork.serializeBinaryToWriter
    );
  }
  f = message.getAccommodation();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getRestaurant();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getShortdescription();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getArchitect();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getYearofcreation();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getAmenitiesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      18,
      f
    );
  }
  f = message.getArea();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
  f = message.getLength();
  if (f !== 0) {
    writer.writeUint32(
      21,
      f
    );
  }
  f = message.getYoutube();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getDirector();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getIspickupplace();
  if (f) {
    writer.writeBool(
      100,
      f
    );
  }
  f = message.getMembertypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      105,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Place.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.commUnity.Place.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Address Address = 3;
 * @return {?proto.commUnity.Address}
 */
proto.commUnity.Place.prototype.getAddress = function() {
  return /** @type{?proto.commUnity.Address} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.Address, 3));
};


/**
 * @param {?proto.commUnity.Address|undefined} value
 * @return {!proto.commUnity.Place} returns this
*/
proto.commUnity.Place.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.Place.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string Description = 4;
 * @return {string}
 */
proto.commUnity.Place.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string URL = 7;
 * @return {string}
 */
proto.commUnity.Place.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional SocialNetwork SocialNetwork = 10;
 * @return {?proto.commUnity.SocialNetwork}
 */
proto.commUnity.Place.prototype.getSocialnetwork = function() {
  return /** @type{?proto.commUnity.SocialNetwork} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.SocialNetwork, 10));
};


/**
 * @param {?proto.commUnity.SocialNetwork|undefined} value
 * @return {!proto.commUnity.Place} returns this
*/
proto.commUnity.Place.prototype.setSocialnetwork = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.clearSocialnetwork = function() {
  return this.setSocialnetwork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.Place.prototype.hasSocialnetwork = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string Accommodation = 11;
 * @return {string}
 */
proto.commUnity.Place.prototype.getAccommodation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setAccommodation = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated string Images = 12;
 * @return {!Array<string>}
 */
proto.commUnity.Place.prototype.getImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setImagesList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.addImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string Restaurant = 13;
 * @return {string}
 */
proto.commUnity.Place.prototype.getRestaurant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setRestaurant = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string ShortDescription = 15;
 * @return {string}
 */
proto.commUnity.Place.prototype.getShortdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setShortdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string Architect = 16;
 * @return {string}
 */
proto.commUnity.Place.prototype.getArchitect = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setArchitect = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional uint32 YearOfCreation = 17;
 * @return {number}
 */
proto.commUnity.Place.prototype.getYearofcreation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setYearofcreation = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * repeated Amenities Amenities = 18;
 * @return {!Array<!proto.commUnity.Amenities>}
 */
proto.commUnity.Place.prototype.getAmenitiesList = function() {
  return /** @type {!Array<!proto.commUnity.Amenities>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<!proto.commUnity.Amenities>} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setAmenitiesList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {!proto.commUnity.Amenities} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.addAmenities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.clearAmenitiesList = function() {
  return this.setAmenitiesList([]);
};


/**
 * optional uint32 Area = 20;
 * @return {number}
 */
proto.commUnity.Place.prototype.getArea = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setArea = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint32 Length = 21;
 * @return {number}
 */
proto.commUnity.Place.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional string Youtube = 22;
 * @return {string}
 */
proto.commUnity.Place.prototype.getYoutube = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setYoutube = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string Director = 23;
 * @return {string}
 */
proto.commUnity.Place.prototype.getDirector = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setDirector = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional bool IsPickupPlace = 100;
 * @return {boolean}
 */
proto.commUnity.Place.prototype.getIspickupplace = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setIspickupplace = function(value) {
  return jspb.Message.setProto3BooleanField(this, 100, value);
};


/**
 * repeated string MemberTypes = 105;
 * @return {!Array<string>}
 */
proto.commUnity.Place.prototype.getMembertypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 105));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.setMembertypesList = function(value) {
  return jspb.Message.setField(this, 105, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.addMembertypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 105, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Place} returns this
 */
proto.commUnity.Place.prototype.clearMembertypesList = function() {
  return this.setMembertypesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.commUnity.Attendee.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.commUnity.Attendee.AttendeeTypeCase = {
  ATTENDEE_TYPE_NOT_SET: 0,
  MEMBER: 1,
  GUEST: 2,
  NOT_COMING_MEMBER: 3
};

/**
 * @return {proto.commUnity.Attendee.AttendeeTypeCase}
 */
proto.commUnity.Attendee.prototype.getAttendeeTypeCase = function() {
  return /** @type {proto.commUnity.Attendee.AttendeeTypeCase} */(jspb.Message.computeOneofCase(this, proto.commUnity.Attendee.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Attendee.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Attendee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Attendee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Attendee.toObject = function(includeInstance, msg) {
  var f, obj = {
    member: (f = msg.getMember()) && proto.commUnity.Member.toObject(includeInstance, f),
    guest: (f = msg.getGuest()) && proto.commUnity.Guest.toObject(includeInstance, f),
    notComingMember: (f = msg.getNotComingMember()) && proto.commUnity.Member.toObject(includeInstance, f),
    registerdate: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Attendee}
 */
proto.commUnity.Attendee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Attendee;
  return proto.commUnity.Attendee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Attendee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Attendee}
 */
proto.commUnity.Attendee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.commUnity.Member;
      reader.readMessage(value,proto.commUnity.Member.deserializeBinaryFromReader);
      msg.setMember(value);
      break;
    case 2:
      var value = new proto.commUnity.Guest;
      reader.readMessage(value,proto.commUnity.Guest.deserializeBinaryFromReader);
      msg.setGuest(value);
      break;
    case 3:
      var value = new proto.commUnity.Member;
      reader.readMessage(value,proto.commUnity.Member.deserializeBinaryFromReader);
      msg.setNotComingMember(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRegisterdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Attendee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Attendee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Attendee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Attendee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMember();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.commUnity.Member.serializeBinaryToWriter
    );
  }
  f = message.getGuest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.commUnity.Guest.serializeBinaryToWriter
    );
  }
  f = message.getNotComingMember();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.commUnity.Member.serializeBinaryToWriter
    );
  }
  f = message.getRegisterdate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional Member member = 1;
 * @return {?proto.commUnity.Member}
 */
proto.commUnity.Attendee.prototype.getMember = function() {
  return /** @type{?proto.commUnity.Member} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.Member, 1));
};


/**
 * @param {?proto.commUnity.Member|undefined} value
 * @return {!proto.commUnity.Attendee} returns this
*/
proto.commUnity.Attendee.prototype.setMember = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.commUnity.Attendee.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.Attendee} returns this
 */
proto.commUnity.Attendee.prototype.clearMember = function() {
  return this.setMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.Attendee.prototype.hasMember = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Guest guest = 2;
 * @return {?proto.commUnity.Guest}
 */
proto.commUnity.Attendee.prototype.getGuest = function() {
  return /** @type{?proto.commUnity.Guest} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.Guest, 2));
};


/**
 * @param {?proto.commUnity.Guest|undefined} value
 * @return {!proto.commUnity.Attendee} returns this
*/
proto.commUnity.Attendee.prototype.setGuest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.commUnity.Attendee.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.Attendee} returns this
 */
proto.commUnity.Attendee.prototype.clearGuest = function() {
  return this.setGuest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.Attendee.prototype.hasGuest = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Member not_coming_member = 3;
 * @return {?proto.commUnity.Member}
 */
proto.commUnity.Attendee.prototype.getNotComingMember = function() {
  return /** @type{?proto.commUnity.Member} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.Member, 3));
};


/**
 * @param {?proto.commUnity.Member|undefined} value
 * @return {!proto.commUnity.Attendee} returns this
*/
proto.commUnity.Attendee.prototype.setNotComingMember = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.commUnity.Attendee.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.Attendee} returns this
 */
proto.commUnity.Attendee.prototype.clearNotComingMember = function() {
  return this.setNotComingMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.Attendee.prototype.hasNotComingMember = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 registerDate = 5;
 * @return {number}
 */
proto.commUnity.Attendee.prototype.getRegisterdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Attendee} returns this
 */
proto.commUnity.Attendee.prototype.setRegisterdate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Guest.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Guest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Guest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Guest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    index: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    license: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Guest}
 */
proto.commUnity.Guest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Guest;
  return proto.commUnity.Guest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Guest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Guest}
 */
proto.commUnity.Guest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIndex(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicense(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Guest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Guest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Guest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Guest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getLicense();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string lastName = 1;
 * @return {string}
 */
proto.commUnity.Guest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Guest} returns this
 */
proto.commUnity.Guest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.commUnity.Guest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Guest} returns this
 */
proto.commUnity.Guest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mobile = 3;
 * @return {string}
 */
proto.commUnity.Guest.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Guest} returns this
 */
proto.commUnity.Guest.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.commUnity.Guest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Guest} returns this
 */
proto.commUnity.Guest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double index = 6;
 * @return {number}
 */
proto.commUnity.Guest.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Guest} returns this
 */
proto.commUnity.Guest.prototype.setIndex = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string License = 7;
 * @return {string}
 */
proto.commUnity.Guest.prototype.getLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Guest} returns this
 */
proto.commUnity.Guest.prototype.setLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Benefit.repeatedFields_ = [100];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Benefit.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Benefit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Benefit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Benefit.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortdescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    image: jspb.Message.getFieldWithDefault(msg, 4, ""),
    publishdate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    url: jspb.Message.getFieldWithDefault(msg, 7, ""),
    endpublishdate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    order: jspb.Message.getFieldWithDefault(msg, 10, 0),
    membertypesList: (f = jspb.Message.getRepeatedField(msg, 100)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Benefit}
 */
proto.commUnity.Benefit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Benefit;
  return proto.commUnity.Benefit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Benefit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Benefit}
 */
proto.commUnity.Benefit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortdescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPublishdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndpublishdate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembertypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Benefit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Benefit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Benefit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Benefit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortdescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPublishdate();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEndpublishdate();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getMembertypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Benefit.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Benefit} returns this
 */
proto.commUnity.Benefit.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.commUnity.Benefit.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Benefit} returns this
 */
proto.commUnity.Benefit.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string shortDescription = 3;
 * @return {string}
 */
proto.commUnity.Benefit.prototype.getShortdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Benefit} returns this
 */
proto.commUnity.Benefit.prototype.setShortdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image = 4;
 * @return {string}
 */
proto.commUnity.Benefit.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Benefit} returns this
 */
proto.commUnity.Benefit.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 publishDate = 5;
 * @return {number}
 */
proto.commUnity.Benefit.prototype.getPublishdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Benefit} returns this
 */
proto.commUnity.Benefit.prototype.setPublishdate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.commUnity.Benefit.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Benefit} returns this
 */
proto.commUnity.Benefit.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string URL = 7;
 * @return {string}
 */
proto.commUnity.Benefit.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Benefit} returns this
 */
proto.commUnity.Benefit.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 endPublishDate = 8;
 * @return {number}
 */
proto.commUnity.Benefit.prototype.getEndpublishdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Benefit} returns this
 */
proto.commUnity.Benefit.prototype.setEndpublishdate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 Order = 10;
 * @return {number}
 */
proto.commUnity.Benefit.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Benefit} returns this
 */
proto.commUnity.Benefit.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated string MemberTypes = 100;
 * @return {!Array<string>}
 */
proto.commUnity.Benefit.prototype.getMembertypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 100));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Benefit} returns this
 */
proto.commUnity.Benefit.prototype.setMembertypesList = function(value) {
  return jspb.Message.setField(this, 100, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Benefit} returns this
 */
proto.commUnity.Benefit.prototype.addMembertypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 100, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Benefit} returns this
 */
proto.commUnity.Benefit.prototype.clearMembertypesList = function() {
  return this.setMembertypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Ad.repeatedFields_ = [100];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Ad.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Ad.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Ad} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Ad.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    image: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 7, ""),
    publishdate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    endpublishdate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    membertypesList: (f = jspb.Message.getRepeatedField(msg, 100)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Ad}
 */
proto.commUnity.Ad.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Ad;
  return proto.commUnity.Ad.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Ad} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Ad}
 */
proto.commUnity.Ad.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPublishdate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndpublishdate(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembertypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Ad.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Ad.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Ad} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Ad.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPublishdate();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getEndpublishdate();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getMembertypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Ad.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Ad} returns this
 */
proto.commUnity.Ad.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image = 2;
 * @return {string}
 */
proto.commUnity.Ad.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Ad} returns this
 */
proto.commUnity.Ad.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string URL = 7;
 * @return {string}
 */
proto.commUnity.Ad.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Ad} returns this
 */
proto.commUnity.Ad.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 publishDate = 5;
 * @return {number}
 */
proto.commUnity.Ad.prototype.getPublishdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Ad} returns this
 */
proto.commUnity.Ad.prototype.setPublishdate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 endPublishDate = 8;
 * @return {number}
 */
proto.commUnity.Ad.prototype.getEndpublishdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Ad} returns this
 */
proto.commUnity.Ad.prototype.setEndpublishdate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated string MemberTypes = 100;
 * @return {!Array<string>}
 */
proto.commUnity.Ad.prototype.getMembertypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 100));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Ad} returns this
 */
proto.commUnity.Ad.prototype.setMembertypesList = function(value) {
  return jspb.Message.setField(this, 100, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Ad} returns this
 */
proto.commUnity.Ad.prototype.addMembertypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 100, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Ad} returns this
 */
proto.commUnity.Ad.prototype.clearMembertypesList = function() {
  return this.setMembertypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.News.repeatedFields_ = [100];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.News.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.News.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.News} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.News.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortdescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    image: jspb.Message.getFieldWithDefault(msg, 4, ""),
    publishdate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    url: jspb.Message.getFieldWithDefault(msg, 7, ""),
    endpublishdate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    youtube: jspb.Message.getFieldWithDefault(msg, 9, ""),
    order: jspb.Message.getFieldWithDefault(msg, 10, 0),
    placeid: jspb.Message.getFieldWithDefault(msg, 20, ""),
    placename: jspb.Message.getFieldWithDefault(msg, 21, ""),
    membertypesList: (f = jspb.Message.getRepeatedField(msg, 100)) == null ? undefined : f,
    newsnb: jspb.Message.getFieldWithDefault(msg, 200, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.News}
 */
proto.commUnity.News.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.News;
  return proto.commUnity.News.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.News} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.News}
 */
proto.commUnity.News.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortdescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPublishdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndpublishdate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setYoutube(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceid(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacename(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembertypes(value);
      break;
    case 200:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNewsnb(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.News.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.News.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.News} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.News.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortdescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPublishdate();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEndpublishdate();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getYoutube();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getPlaceid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPlacename();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getMembertypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      100,
      f
    );
  }
  f = message.getNewsnb();
  if (f !== 0) {
    writer.writeInt32(
      200,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.News.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.commUnity.News.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string shortDescription = 3;
 * @return {string}
 */
proto.commUnity.News.prototype.getShortdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.setShortdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image = 4;
 * @return {string}
 */
proto.commUnity.News.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 publishDate = 5;
 * @return {number}
 */
proto.commUnity.News.prototype.getPublishdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.setPublishdate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.commUnity.News.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string URL = 7;
 * @return {string}
 */
proto.commUnity.News.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 endPublishDate = 8;
 * @return {number}
 */
proto.commUnity.News.prototype.getEndpublishdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.setEndpublishdate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string youtube = 9;
 * @return {string}
 */
proto.commUnity.News.prototype.getYoutube = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.setYoutube = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional uint32 Order = 10;
 * @return {number}
 */
proto.commUnity.News.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string PlaceID = 20;
 * @return {string}
 */
proto.commUnity.News.prototype.getPlaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.setPlaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string PlaceName = 21;
 * @return {string}
 */
proto.commUnity.News.prototype.getPlacename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.setPlacename = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated string MemberTypes = 100;
 * @return {!Array<string>}
 */
proto.commUnity.News.prototype.getMembertypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 100));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.setMembertypesList = function(value) {
  return jspb.Message.setField(this, 100, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.addMembertypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 100, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.clearMembertypesList = function() {
  return this.setMembertypesList([]);
};


/**
 * optional int32 NewsNb = 200;
 * @return {number}
 */
proto.commUnity.News.prototype.getNewsnb = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 200, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.News} returns this
 */
proto.commUnity.News.prototype.setNewsnb = function(value) {
  return jspb.Message.setProto3IntField(this, 200, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.SocialNetwork.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.SocialNetwork.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.SocialNetwork} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.SocialNetwork.toObject = function(includeInstance, msg) {
  var f, obj = {
    facebook: jspb.Message.getFieldWithDefault(msg, 1, ""),
    twitter: jspb.Message.getFieldWithDefault(msg, 2, ""),
    linkedin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    instagram: jspb.Message.getFieldWithDefault(msg, 4, ""),
    snapshat: jspb.Message.getFieldWithDefault(msg, 5, ""),
    telegram: jspb.Message.getFieldWithDefault(msg, 6, ""),
    skype: jspb.Message.getFieldWithDefault(msg, 7, ""),
    whatsapp: jspb.Message.getFieldWithDefault(msg, 8, ""),
    pinterest: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.SocialNetwork}
 */
proto.commUnity.SocialNetwork.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.SocialNetwork;
  return proto.commUnity.SocialNetwork.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.SocialNetwork} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.SocialNetwork}
 */
proto.commUnity.SocialNetwork.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacebook(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTwitter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkedin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstagram(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapshat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelegram(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkype(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhatsapp(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPinterest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.SocialNetwork.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.SocialNetwork.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.SocialNetwork} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.SocialNetwork.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFacebook();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTwitter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLinkedin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInstagram();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSnapshat();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTelegram();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSkype();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getWhatsapp();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPinterest();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string Facebook = 1;
 * @return {string}
 */
proto.commUnity.SocialNetwork.prototype.getFacebook = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SocialNetwork} returns this
 */
proto.commUnity.SocialNetwork.prototype.setFacebook = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Twitter = 2;
 * @return {string}
 */
proto.commUnity.SocialNetwork.prototype.getTwitter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SocialNetwork} returns this
 */
proto.commUnity.SocialNetwork.prototype.setTwitter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Linkedin = 3;
 * @return {string}
 */
proto.commUnity.SocialNetwork.prototype.getLinkedin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SocialNetwork} returns this
 */
proto.commUnity.SocialNetwork.prototype.setLinkedin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Instagram = 4;
 * @return {string}
 */
proto.commUnity.SocialNetwork.prototype.getInstagram = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SocialNetwork} returns this
 */
proto.commUnity.SocialNetwork.prototype.setInstagram = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Snapshat = 5;
 * @return {string}
 */
proto.commUnity.SocialNetwork.prototype.getSnapshat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SocialNetwork} returns this
 */
proto.commUnity.SocialNetwork.prototype.setSnapshat = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Telegram = 6;
 * @return {string}
 */
proto.commUnity.SocialNetwork.prototype.getTelegram = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SocialNetwork} returns this
 */
proto.commUnity.SocialNetwork.prototype.setTelegram = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Skype = 7;
 * @return {string}
 */
proto.commUnity.SocialNetwork.prototype.getSkype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SocialNetwork} returns this
 */
proto.commUnity.SocialNetwork.prototype.setSkype = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Whatsapp = 8;
 * @return {string}
 */
proto.commUnity.SocialNetwork.prototype.getWhatsapp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SocialNetwork} returns this
 */
proto.commUnity.SocialNetwork.prototype.setWhatsapp = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string Pinterest = 9;
 * @return {string}
 */
proto.commUnity.SocialNetwork.prototype.getPinterest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SocialNetwork} returns this
 */
proto.commUnity.SocialNetwork.prototype.setPinterest = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Leaderboard.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Leaderboard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Leaderboard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Leaderboard.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    image: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    brut: jspb.Message.getFieldWithDefault(msg, 6, 0),
    net: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Leaderboard}
 */
proto.commUnity.Leaderboard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Leaderboard;
  return proto.commUnity.Leaderboard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Leaderboard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Leaderboard}
 */
proto.commUnity.Leaderboard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBrut(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Leaderboard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Leaderboard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Leaderboard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Leaderboard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBrut();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getNet();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Leaderboard.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Leaderboard} returns this
 */
proto.commUnity.Leaderboard.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Image = 2;
 * @return {string}
 */
proto.commUnity.Leaderboard.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Leaderboard} returns this
 */
proto.commUnity.Leaderboard.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 4;
 * @return {string}
 */
proto.commUnity.Leaderboard.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Leaderboard} returns this
 */
proto.commUnity.Leaderboard.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string firstName = 5;
 * @return {string}
 */
proto.commUnity.Leaderboard.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Leaderboard} returns this
 */
proto.commUnity.Leaderboard.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 brut = 6;
 * @return {number}
 */
proto.commUnity.Leaderboard.prototype.getBrut = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Leaderboard} returns this
 */
proto.commUnity.Leaderboard.prototype.setBrut = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 net = 7;
 * @return {number}
 */
proto.commUnity.Leaderboard.prototype.getNet = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Leaderboard} returns this
 */
proto.commUnity.Leaderboard.prototype.setNet = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Alert.repeatedFields_ = [100];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Alert.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Alert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Alert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Alert.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    senton: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sentby: jspb.Message.getFieldWithDefault(msg, 5, ""),
    membertypesList: (f = jspb.Message.getRepeatedField(msg, 100)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Alert}
 */
proto.commUnity.Alert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Alert;
  return proto.commUnity.Alert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Alert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Alert}
 */
proto.commUnity.Alert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSenton(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSentby(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembertypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Alert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Alert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Alert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Alert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSenton();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSentby();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMembertypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Alert.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Alert} returns this
 */
proto.commUnity.Alert.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.commUnity.Alert.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Alert} returns this
 */
proto.commUnity.Alert.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.commUnity.Alert.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Alert} returns this
 */
proto.commUnity.Alert.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 sentOn = 4;
 * @return {number}
 */
proto.commUnity.Alert.prototype.getSenton = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Alert} returns this
 */
proto.commUnity.Alert.prototype.setSenton = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string sentBy = 5;
 * @return {string}
 */
proto.commUnity.Alert.prototype.getSentby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Alert} returns this
 */
proto.commUnity.Alert.prototype.setSentby = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string MemberTypes = 100;
 * @return {!Array<string>}
 */
proto.commUnity.Alert.prototype.getMembertypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 100));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Alert} returns this
 */
proto.commUnity.Alert.prototype.setMembertypesList = function(value) {
  return jspb.Message.setField(this, 100, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Alert} returns this
 */
proto.commUnity.Alert.prototype.addMembertypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 100, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Alert} returns this
 */
proto.commUnity.Alert.prototype.clearMembertypesList = function() {
  return this.setMembertypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.RequestID.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.RequestID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.RequestID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RequestID.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.RequestID}
 */
proto.commUnity.RequestID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.RequestID;
  return proto.commUnity.RequestID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.RequestID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.RequestID}
 */
proto.commUnity.RequestID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.RequestID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.RequestID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.RequestID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RequestID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.RequestID.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RequestID} returns this
 */
proto.commUnity.RequestID.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Group.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Group.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Group} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Group.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customertextcolor: jspb.Message.getFieldWithDefault(msg, 10, ""),
    backgroundphoto: jspb.Message.getFieldWithDefault(msg, 28, ""),
    desktopbannerphoto: jspb.Message.getFieldWithDefault(msg, 29, ""),
    backgroundphotoskretch: jspb.Message.getBooleanFieldWithDefault(msg, 126, false),
    showcustomername: jspb.Message.getBooleanFieldWithDefault(msg, 130, false),
    mobilemenutype: jspb.Message.getFieldWithDefault(msg, 320, 0),
    logo: jspb.Message.getFieldWithDefault(msg, 19, ""),
    mobileapp: jspb.Message.getFieldWithDefault(msg, 20, 0),
    refererurl: jspb.Message.getFieldWithDefault(msg, 30, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Group}
 */
proto.commUnity.Group.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Group;
  return proto.commUnity.Group.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Group} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Group}
 */
proto.commUnity.Group.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomertextcolor(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundphoto(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesktopbannerphoto(value);
      break;
    case 126:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBackgroundphotoskretch(value);
      break;
    case 130:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowcustomername(value);
      break;
    case 320:
      var value = /** @type {!proto.commUnity.MobileGroupMenuType} */ (reader.readEnum());
      msg.setMobilemenutype(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 20:
      var value = /** @type {!proto.commUnity.MobileApp} */ (reader.readEnum());
      msg.setMobileapp(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefererurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Group.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Group.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Group} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Group.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomertextcolor();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBackgroundphoto();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getDesktopbannerphoto();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getBackgroundphotoskretch();
  if (f) {
    writer.writeBool(
      126,
      f
    );
  }
  f = message.getShowcustomername();
  if (f) {
    writer.writeBool(
      130,
      f
    );
  }
  f = message.getMobilemenutype();
  if (f !== 0.0) {
    writer.writeEnum(
      320,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getMobileapp();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getRefererurl();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Group.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Group} returns this
 */
proto.commUnity.Group.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.commUnity.Group.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Group} returns this
 */
proto.commUnity.Group.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string CustomerTextColor = 10;
 * @return {string}
 */
proto.commUnity.Group.prototype.getCustomertextcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Group} returns this
 */
proto.commUnity.Group.prototype.setCustomertextcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string BackgroundPhoto = 28;
 * @return {string}
 */
proto.commUnity.Group.prototype.getBackgroundphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Group} returns this
 */
proto.commUnity.Group.prototype.setBackgroundphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string DesktopBannerPhoto = 29;
 * @return {string}
 */
proto.commUnity.Group.prototype.getDesktopbannerphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Group} returns this
 */
proto.commUnity.Group.prototype.setDesktopbannerphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional bool BackgroundPhotoSkretch = 126;
 * @return {boolean}
 */
proto.commUnity.Group.prototype.getBackgroundphotoskretch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 126, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Group} returns this
 */
proto.commUnity.Group.prototype.setBackgroundphotoskretch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 126, value);
};


/**
 * optional bool ShowCustomerName = 130;
 * @return {boolean}
 */
proto.commUnity.Group.prototype.getShowcustomername = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 130, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Group} returns this
 */
proto.commUnity.Group.prototype.setShowcustomername = function(value) {
  return jspb.Message.setProto3BooleanField(this, 130, value);
};


/**
 * optional MobileGroupMenuType MobileMenuType = 320;
 * @return {!proto.commUnity.MobileGroupMenuType}
 */
proto.commUnity.Group.prototype.getMobilemenutype = function() {
  return /** @type {!proto.commUnity.MobileGroupMenuType} */ (jspb.Message.getFieldWithDefault(this, 320, 0));
};


/**
 * @param {!proto.commUnity.MobileGroupMenuType} value
 * @return {!proto.commUnity.Group} returns this
 */
proto.commUnity.Group.prototype.setMobilemenutype = function(value) {
  return jspb.Message.setProto3EnumField(this, 320, value);
};


/**
 * optional string Logo = 19;
 * @return {string}
 */
proto.commUnity.Group.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Group} returns this
 */
proto.commUnity.Group.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional MobileApp MobileApp = 20;
 * @return {!proto.commUnity.MobileApp}
 */
proto.commUnity.Group.prototype.getMobileapp = function() {
  return /** @type {!proto.commUnity.MobileApp} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.commUnity.MobileApp} value
 * @return {!proto.commUnity.Group} returns this
 */
proto.commUnity.Group.prototype.setMobileapp = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional string RefererURL = 30;
 * @return {string}
 */
proto.commUnity.Group.prototype.getRefererurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Group} returns this
 */
proto.commUnity.Group.prototype.setRefererurl = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Customer.repeatedFields_ = [300,500];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Customer.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Customer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Customer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Customer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    groupid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    groupname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    category: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bitlyapi: jspb.Message.getFieldWithDefault(msg, 10, ""),
    logindesktopbackgroundphoto: jspb.Message.getFieldWithDefault(msg, 18, ""),
    font: jspb.Message.getFieldWithDefault(msg, 19, 0),
    fontsize: jspb.Message.getFieldWithDefault(msg, 25, 0),
    loginbackgroundphoto: jspb.Message.getFieldWithDefault(msg, 37, ""),
    desktopbannerphoto: jspb.Message.getFieldWithDefault(msg, 29, ""),
    desktopbackgroundphoto: jspb.Message.getFieldWithDefault(msg, 36, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 24, ""),
    backgroundphoto: jspb.Message.getFieldWithDefault(msg, 20, ""),
    color: jspb.Message.getFieldWithDefault(msg, 21, ""),
    backgroundprofile: jspb.Message.getFieldWithDefault(msg, 22, ""),
    address: (f = msg.getAddress()) && proto.commUnity.Address.toObject(includeInstance, f),
    refererurl: jspb.Message.getFieldWithDefault(msg, 30, ""),
    showcustomizedmenu: jspb.Message.getBooleanFieldWithDefault(msg, 98, false),
    showlinks: jspb.Message.getBooleanFieldWithDefault(msg, 99, false),
    showevent: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
    showbenefit: jspb.Message.getBooleanFieldWithDefault(msg, 101, false),
    shownews: jspb.Message.getBooleanFieldWithDefault(msg, 102, false),
    showshop: jspb.Message.getBooleanFieldWithDefault(msg, 103, false),
    showshoppwa: jspb.Message.getBooleanFieldWithDefault(msg, 156, false),
    showshoptoguest: jspb.Message.getBooleanFieldWithDefault(msg, 150, false),
    showforum: jspb.Message.getBooleanFieldWithDefault(msg, 104, false),
    showalert: jspb.Message.getBooleanFieldWithDefault(msg, 105, false),
    showcompetition: jspb.Message.getBooleanFieldWithDefault(msg, 106, false),
    showad: jspb.Message.getBooleanFieldWithDefault(msg, 107, false),
    showalbum: jspb.Message.getBooleanFieldWithDefault(msg, 108, false),
    showleaderboard: jspb.Message.getBooleanFieldWithDefault(msg, 109, false),
    showmember: jspb.Message.getBooleanFieldWithDefault(msg, 110, false),
    showdocument: jspb.Message.getBooleanFieldWithDefault(msg, 146, false),
    showdocumentpwa: jspb.Message.getBooleanFieldWithDefault(msg, 147, false),
    showbusinesscard: jspb.Message.getBooleanFieldWithDefault(msg, 148, false),
    showbusinesscardpwa: jspb.Message.getBooleanFieldWithDefault(msg, 149, false),
    showmyaccount: jspb.Message.getBooleanFieldWithDefault(msg, 135, false),
    showrestaurant: jspb.Message.getBooleanFieldWithDefault(msg, 136, false),
    cansignup: jspb.Message.getBooleanFieldWithDefault(msg, 111, false),
    showplace: jspb.Message.getBooleanFieldWithDefault(msg, 112, false),
    showsearch: jspb.Message.getBooleanFieldWithDefault(msg, 113, false),
    showpromotion: jspb.Message.getBooleanFieldWithDefault(msg, 131, false),
    showcustomername: jspb.Message.getBooleanFieldWithDefault(msg, 119, false),
    menubackground: jspb.Message.getBooleanFieldWithDefault(msg, 114, false),
    menubackgroundcolor: jspb.Message.getFieldWithDefault(msg, 115, ""),
    menuforegroundcolor: jspb.Message.getFieldWithDefault(msg, 116, ""),
    menubackgroundopacity: jspb.Message.getFieldWithDefault(msg, 117, 0),
    needsubscription: jspb.Message.getBooleanFieldWithDefault(msg, 118, false),
    mobileapp: jspb.Message.getFieldWithDefault(msg, 120, 0),
    lydiaapi: jspb.Message.getFieldWithDefault(msg, 121, ""),
    lydiaprod: jspb.Message.getBooleanFieldWithDefault(msg, 128, false),
    showshopprofile: jspb.Message.getBooleanFieldWithDefault(msg, 129, false),
    showplacemenu: jspb.Message.getBooleanFieldWithDefault(msg, 145, false),
    guestusername: jspb.Message.getFieldWithDefault(msg, 123, ""),
    guestuserpwd: jspb.Message.getFieldWithDefault(msg, 124, ""),
    autoguest: jspb.Message.getBooleanFieldWithDefault(msg, 125, false),
    backgroundphotoskretch: jspb.Message.getBooleanFieldWithDefault(msg, 126, false),
    loginbackgroundphotoskretch: jspb.Message.getBooleanFieldWithDefault(msg, 127, false),
    backgrounddesktopphotoskretch: jspb.Message.getBooleanFieldWithDefault(msg, 151, false),
    loginbackgrounddesktopphotoskretch: jspb.Message.getBooleanFieldWithDefault(msg, 152, false),
    showattendance: jspb.Message.getBooleanFieldWithDefault(msg, 167, false),
    shownews2: jspb.Message.getBooleanFieldWithDefault(msg, 153, false),
    shownews3: jspb.Message.getBooleanFieldWithDefault(msg, 154, false),
    shownews4: jspb.Message.getBooleanFieldWithDefault(msg, 155, false),
    showcps: jspb.Message.getBooleanFieldWithDefault(msg, 352, false),
    showlink2: jspb.Message.getBooleanFieldWithDefault(msg, 353, false),
    showlink3: jspb.Message.getBooleanFieldWithDefault(msg, 354, false),
    showlink4: jspb.Message.getBooleanFieldWithDefault(msg, 355, false),
    showeventtablereservation: jspb.Message.getBooleanFieldWithDefault(msg, 356, false),
    loginforegroundcolor: jspb.Message.getFieldWithDefault(msg, 130, 0),
    alertbackgroundcolor: jspb.Message.getFieldWithDefault(msg, 140, ""),
    alerttextforegroundcolor: jspb.Message.getFieldWithDefault(msg, 141, ""),
    emailtemplatechangepasswordMap: (f = msg.getEmailtemplatechangepasswordMap()) ? f.toObject(includeInstance, proto.commUnity.TemplateMail.toObject) : [],
    emailtemplatesignupMap: (f = msg.getEmailtemplatesignupMap()) ? f.toObject(includeInstance, proto.commUnity.TemplateMail.toObject) : [],
    emailtemplatewelcomeMap: (f = msg.getEmailtemplatewelcomeMap()) ? f.toObject(includeInstance, proto.commUnity.TemplateMail.toObject) : [],
    emailtemplatereceiveorderMap: (f = msg.getEmailtemplatereceiveorderMap()) ? f.toObject(includeInstance, proto.commUnity.TemplateMail.toObject) : [],
    emailtemplatethankorderMap: (f = msg.getEmailtemplatethankorderMap()) ? f.toObject(includeInstance, proto.commUnity.TemplateMail.toObject) : [],
    emailtemplateshippedorderMap: (f = msg.getEmailtemplateshippedorderMap()) ? f.toObject(includeInstance, proto.commUnity.TemplateMail.toObject) : [],
    emailtemplatealertMap: (f = msg.getEmailtemplatealertMap()) ? f.toObject(includeInstance, proto.commUnity.TemplateMail.toObject) : [],
    emailtemplateeventtablereservationMap: (f = msg.getEmailtemplateeventtablereservationMap()) ? f.toObject(includeInstance, proto.commUnity.TemplateMail.toObject) : [],
    emailtemplateeventtablecancelMap: (f = msg.getEmailtemplateeventtablecancelMap()) ? f.toObject(includeInstance, proto.commUnity.TemplateMail.toObject) : [],
    mobileosList: (f = jspb.Message.getRepeatedField(msg, 300)) == null ? undefined : f,
    mobilemenutype: jspb.Message.getFieldWithDefault(msg, 320, 0),
    desktopmenutype: jspb.Message.getFieldWithDefault(msg, 321, 0),
    desktopredirecturl: jspb.Message.getFieldWithDefault(msg, 325, ""),
    showstreampwa: jspb.Message.getBooleanFieldWithDefault(msg, 362, false),
    showstream2pwa: jspb.Message.getBooleanFieldWithDefault(msg, 363, false),
    showstream3pwa: jspb.Message.getBooleanFieldWithDefault(msg, 364, false),
    showstream4pwa: jspb.Message.getBooleanFieldWithDefault(msg, 365, false),
    telegraphaccesstoken: jspb.Message.getFieldWithDefault(msg, 400, ""),
    everstreamprivatekey: jspb.Message.getFieldWithDefault(msg, 420, ""),
    androidtvapikey: jspb.Message.getFieldWithDefault(msg, 425, ""),
    menuorderList: (f = jspb.Message.getRepeatedField(msg, 500)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Customer}
 */
proto.commUnity.Customer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Customer;
  return proto.commUnity.Customer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Customer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Customer}
 */
proto.commUnity.Customer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBitlyapi(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogindesktopbackgroundphoto(value);
      break;
    case 19:
      var value = /** @type {!proto.commUnity.Font} */ (reader.readEnum());
      msg.setFont(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFontsize(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginbackgroundphoto(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesktopbannerphoto(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesktopbackgroundphoto(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundphoto(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundprofile(value);
      break;
    case 23:
      var value = new proto.commUnity.Address;
      reader.readMessage(value,proto.commUnity.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefererurl(value);
      break;
    case 98:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowcustomizedmenu(value);
      break;
    case 99:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowlinks(value);
      break;
    case 100:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowevent(value);
      break;
    case 101:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowbenefit(value);
      break;
    case 102:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShownews(value);
      break;
    case 103:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowshop(value);
      break;
    case 156:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowshoppwa(value);
      break;
    case 150:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowshoptoguest(value);
      break;
    case 104:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowforum(value);
      break;
    case 105:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowalert(value);
      break;
    case 106:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowcompetition(value);
      break;
    case 107:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowad(value);
      break;
    case 108:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowalbum(value);
      break;
    case 109:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowleaderboard(value);
      break;
    case 110:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowmember(value);
      break;
    case 146:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowdocument(value);
      break;
    case 147:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowdocumentpwa(value);
      break;
    case 148:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowbusinesscard(value);
      break;
    case 149:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowbusinesscardpwa(value);
      break;
    case 135:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowmyaccount(value);
      break;
    case 136:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowrestaurant(value);
      break;
    case 111:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCansignup(value);
      break;
    case 112:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowplace(value);
      break;
    case 113:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowsearch(value);
      break;
    case 131:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowpromotion(value);
      break;
    case 119:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowcustomername(value);
      break;
    case 114:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMenubackground(value);
      break;
    case 115:
      var value = /** @type {string} */ (reader.readString());
      msg.setMenubackgroundcolor(value);
      break;
    case 116:
      var value = /** @type {string} */ (reader.readString());
      msg.setMenuforegroundcolor(value);
      break;
    case 117:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMenubackgroundopacity(value);
      break;
    case 118:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedsubscription(value);
      break;
    case 120:
      var value = /** @type {!proto.commUnity.MobileApp} */ (reader.readEnum());
      msg.setMobileapp(value);
      break;
    case 121:
      var value = /** @type {string} */ (reader.readString());
      msg.setLydiaapi(value);
      break;
    case 128:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLydiaprod(value);
      break;
    case 129:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowshopprofile(value);
      break;
    case 145:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowplacemenu(value);
      break;
    case 123:
      var value = /** @type {string} */ (reader.readString());
      msg.setGuestusername(value);
      break;
    case 124:
      var value = /** @type {string} */ (reader.readString());
      msg.setGuestuserpwd(value);
      break;
    case 125:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoguest(value);
      break;
    case 126:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBackgroundphotoskretch(value);
      break;
    case 127:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLoginbackgroundphotoskretch(value);
      break;
    case 151:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBackgrounddesktopphotoskretch(value);
      break;
    case 152:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLoginbackgrounddesktopphotoskretch(value);
      break;
    case 167:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowattendance(value);
      break;
    case 153:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShownews2(value);
      break;
    case 154:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShownews3(value);
      break;
    case 155:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShownews4(value);
      break;
    case 352:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowcps(value);
      break;
    case 353:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowlink2(value);
      break;
    case 354:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowlink3(value);
      break;
    case 355:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowlink4(value);
      break;
    case 356:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShoweventtablereservation(value);
      break;
    case 130:
      var value = /** @type {!proto.commUnity.LoginTemplate} */ (reader.readEnum());
      msg.setLoginforegroundcolor(value);
      break;
    case 140:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertbackgroundcolor(value);
      break;
    case 141:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlerttextforegroundcolor(value);
      break;
    case 200:
      var value = msg.getEmailtemplatechangepasswordMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.commUnity.TemplateMail.deserializeBinaryFromReader, 0, new proto.commUnity.TemplateMail());
         });
      break;
    case 201:
      var value = msg.getEmailtemplatesignupMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.commUnity.TemplateMail.deserializeBinaryFromReader, 0, new proto.commUnity.TemplateMail());
         });
      break;
    case 202:
      var value = msg.getEmailtemplatewelcomeMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.commUnity.TemplateMail.deserializeBinaryFromReader, 0, new proto.commUnity.TemplateMail());
         });
      break;
    case 203:
      var value = msg.getEmailtemplatereceiveorderMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.commUnity.TemplateMail.deserializeBinaryFromReader, 0, new proto.commUnity.TemplateMail());
         });
      break;
    case 204:
      var value = msg.getEmailtemplatethankorderMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.commUnity.TemplateMail.deserializeBinaryFromReader, 0, new proto.commUnity.TemplateMail());
         });
      break;
    case 205:
      var value = msg.getEmailtemplateshippedorderMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.commUnity.TemplateMail.deserializeBinaryFromReader, 0, new proto.commUnity.TemplateMail());
         });
      break;
    case 206:
      var value = msg.getEmailtemplatealertMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.commUnity.TemplateMail.deserializeBinaryFromReader, 0, new proto.commUnity.TemplateMail());
         });
      break;
    case 207:
      var value = msg.getEmailtemplateeventtablereservationMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.commUnity.TemplateMail.deserializeBinaryFromReader, 0, new proto.commUnity.TemplateMail());
         });
      break;
    case 208:
      var value = msg.getEmailtemplateeventtablecancelMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.commUnity.TemplateMail.deserializeBinaryFromReader, 0, new proto.commUnity.TemplateMail());
         });
      break;
    case 300:
      var values = /** @type {!Array<!proto.commUnity.OS>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMobileos(values[i]);
      }
      break;
    case 320:
      var value = /** @type {!proto.commUnity.MobileMenuType} */ (reader.readEnum());
      msg.setMobilemenutype(value);
      break;
    case 321:
      var value = /** @type {!proto.commUnity.DesktopMenuType} */ (reader.readEnum());
      msg.setDesktopmenutype(value);
      break;
    case 325:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesktopredirecturl(value);
      break;
    case 362:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowstreampwa(value);
      break;
    case 363:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowstream2pwa(value);
      break;
    case 364:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowstream3pwa(value);
      break;
    case 365:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowstream4pwa(value);
      break;
    case 400:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelegraphaccesstoken(value);
      break;
    case 420:
      var value = /** @type {string} */ (reader.readString());
      msg.setEverstreamprivatekey(value);
      break;
    case 425:
      var value = /** @type {string} */ (reader.readString());
      msg.setAndroidtvapikey(value);
      break;
    case 500:
      var values = /** @type {!Array<!proto.commUnity.MenuName>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMenuorder(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Customer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Customer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Customer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Customer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGroupid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGroupname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBitlyapi();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLogindesktopbackgroundphoto();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getFont();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getFontsize();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getLoginbackgroundphoto();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getDesktopbannerphoto();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getDesktopbackgroundphoto();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getBackgroundphoto();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getBackgroundprofile();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.commUnity.Address.serializeBinaryToWriter
    );
  }
  f = message.getRefererurl();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getShowcustomizedmenu();
  if (f) {
    writer.writeBool(
      98,
      f
    );
  }
  f = message.getShowlinks();
  if (f) {
    writer.writeBool(
      99,
      f
    );
  }
  f = message.getShowevent();
  if (f) {
    writer.writeBool(
      100,
      f
    );
  }
  f = message.getShowbenefit();
  if (f) {
    writer.writeBool(
      101,
      f
    );
  }
  f = message.getShownews();
  if (f) {
    writer.writeBool(
      102,
      f
    );
  }
  f = message.getShowshop();
  if (f) {
    writer.writeBool(
      103,
      f
    );
  }
  f = message.getShowshoppwa();
  if (f) {
    writer.writeBool(
      156,
      f
    );
  }
  f = message.getShowshoptoguest();
  if (f) {
    writer.writeBool(
      150,
      f
    );
  }
  f = message.getShowforum();
  if (f) {
    writer.writeBool(
      104,
      f
    );
  }
  f = message.getShowalert();
  if (f) {
    writer.writeBool(
      105,
      f
    );
  }
  f = message.getShowcompetition();
  if (f) {
    writer.writeBool(
      106,
      f
    );
  }
  f = message.getShowad();
  if (f) {
    writer.writeBool(
      107,
      f
    );
  }
  f = message.getShowalbum();
  if (f) {
    writer.writeBool(
      108,
      f
    );
  }
  f = message.getShowleaderboard();
  if (f) {
    writer.writeBool(
      109,
      f
    );
  }
  f = message.getShowmember();
  if (f) {
    writer.writeBool(
      110,
      f
    );
  }
  f = message.getShowdocument();
  if (f) {
    writer.writeBool(
      146,
      f
    );
  }
  f = message.getShowdocumentpwa();
  if (f) {
    writer.writeBool(
      147,
      f
    );
  }
  f = message.getShowbusinesscard();
  if (f) {
    writer.writeBool(
      148,
      f
    );
  }
  f = message.getShowbusinesscardpwa();
  if (f) {
    writer.writeBool(
      149,
      f
    );
  }
  f = message.getShowmyaccount();
  if (f) {
    writer.writeBool(
      135,
      f
    );
  }
  f = message.getShowrestaurant();
  if (f) {
    writer.writeBool(
      136,
      f
    );
  }
  f = message.getCansignup();
  if (f) {
    writer.writeBool(
      111,
      f
    );
  }
  f = message.getShowplace();
  if (f) {
    writer.writeBool(
      112,
      f
    );
  }
  f = message.getShowsearch();
  if (f) {
    writer.writeBool(
      113,
      f
    );
  }
  f = message.getShowpromotion();
  if (f) {
    writer.writeBool(
      131,
      f
    );
  }
  f = message.getShowcustomername();
  if (f) {
    writer.writeBool(
      119,
      f
    );
  }
  f = message.getMenubackground();
  if (f) {
    writer.writeBool(
      114,
      f
    );
  }
  f = message.getMenubackgroundcolor();
  if (f.length > 0) {
    writer.writeString(
      115,
      f
    );
  }
  f = message.getMenuforegroundcolor();
  if (f.length > 0) {
    writer.writeString(
      116,
      f
    );
  }
  f = message.getMenubackgroundopacity();
  if (f !== 0) {
    writer.writeUint32(
      117,
      f
    );
  }
  f = message.getNeedsubscription();
  if (f) {
    writer.writeBool(
      118,
      f
    );
  }
  f = message.getMobileapp();
  if (f !== 0.0) {
    writer.writeEnum(
      120,
      f
    );
  }
  f = message.getLydiaapi();
  if (f.length > 0) {
    writer.writeString(
      121,
      f
    );
  }
  f = message.getLydiaprod();
  if (f) {
    writer.writeBool(
      128,
      f
    );
  }
  f = message.getShowshopprofile();
  if (f) {
    writer.writeBool(
      129,
      f
    );
  }
  f = message.getShowplacemenu();
  if (f) {
    writer.writeBool(
      145,
      f
    );
  }
  f = message.getGuestusername();
  if (f.length > 0) {
    writer.writeString(
      123,
      f
    );
  }
  f = message.getGuestuserpwd();
  if (f.length > 0) {
    writer.writeString(
      124,
      f
    );
  }
  f = message.getAutoguest();
  if (f) {
    writer.writeBool(
      125,
      f
    );
  }
  f = message.getBackgroundphotoskretch();
  if (f) {
    writer.writeBool(
      126,
      f
    );
  }
  f = message.getLoginbackgroundphotoskretch();
  if (f) {
    writer.writeBool(
      127,
      f
    );
  }
  f = message.getBackgrounddesktopphotoskretch();
  if (f) {
    writer.writeBool(
      151,
      f
    );
  }
  f = message.getLoginbackgrounddesktopphotoskretch();
  if (f) {
    writer.writeBool(
      152,
      f
    );
  }
  f = message.getShowattendance();
  if (f) {
    writer.writeBool(
      167,
      f
    );
  }
  f = message.getShownews2();
  if (f) {
    writer.writeBool(
      153,
      f
    );
  }
  f = message.getShownews3();
  if (f) {
    writer.writeBool(
      154,
      f
    );
  }
  f = message.getShownews4();
  if (f) {
    writer.writeBool(
      155,
      f
    );
  }
  f = message.getShowcps();
  if (f) {
    writer.writeBool(
      352,
      f
    );
  }
  f = message.getShowlink2();
  if (f) {
    writer.writeBool(
      353,
      f
    );
  }
  f = message.getShowlink3();
  if (f) {
    writer.writeBool(
      354,
      f
    );
  }
  f = message.getShowlink4();
  if (f) {
    writer.writeBool(
      355,
      f
    );
  }
  f = message.getShoweventtablereservation();
  if (f) {
    writer.writeBool(
      356,
      f
    );
  }
  f = message.getLoginforegroundcolor();
  if (f !== 0.0) {
    writer.writeEnum(
      130,
      f
    );
  }
  f = message.getAlertbackgroundcolor();
  if (f.length > 0) {
    writer.writeString(
      140,
      f
    );
  }
  f = message.getAlerttextforegroundcolor();
  if (f.length > 0) {
    writer.writeString(
      141,
      f
    );
  }
  f = message.getEmailtemplatechangepasswordMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(200, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.commUnity.TemplateMail.serializeBinaryToWriter);
  }
  f = message.getEmailtemplatesignupMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(201, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.commUnity.TemplateMail.serializeBinaryToWriter);
  }
  f = message.getEmailtemplatewelcomeMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(202, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.commUnity.TemplateMail.serializeBinaryToWriter);
  }
  f = message.getEmailtemplatereceiveorderMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(203, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.commUnity.TemplateMail.serializeBinaryToWriter);
  }
  f = message.getEmailtemplatethankorderMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(204, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.commUnity.TemplateMail.serializeBinaryToWriter);
  }
  f = message.getEmailtemplateshippedorderMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(205, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.commUnity.TemplateMail.serializeBinaryToWriter);
  }
  f = message.getEmailtemplatealertMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(206, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.commUnity.TemplateMail.serializeBinaryToWriter);
  }
  f = message.getEmailtemplateeventtablereservationMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(207, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.commUnity.TemplateMail.serializeBinaryToWriter);
  }
  f = message.getEmailtemplateeventtablecancelMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(208, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.commUnity.TemplateMail.serializeBinaryToWriter);
  }
  f = message.getMobileosList();
  if (f.length > 0) {
    writer.writePackedEnum(
      300,
      f
    );
  }
  f = message.getMobilemenutype();
  if (f !== 0.0) {
    writer.writeEnum(
      320,
      f
    );
  }
  f = message.getDesktopmenutype();
  if (f !== 0.0) {
    writer.writeEnum(
      321,
      f
    );
  }
  f = message.getDesktopredirecturl();
  if (f.length > 0) {
    writer.writeString(
      325,
      f
    );
  }
  f = message.getShowstreampwa();
  if (f) {
    writer.writeBool(
      362,
      f
    );
  }
  f = message.getShowstream2pwa();
  if (f) {
    writer.writeBool(
      363,
      f
    );
  }
  f = message.getShowstream3pwa();
  if (f) {
    writer.writeBool(
      364,
      f
    );
  }
  f = message.getShowstream4pwa();
  if (f) {
    writer.writeBool(
      365,
      f
    );
  }
  f = message.getTelegraphaccesstoken();
  if (f.length > 0) {
    writer.writeString(
      400,
      f
    );
  }
  f = message.getEverstreamprivatekey();
  if (f.length > 0) {
    writer.writeString(
      420,
      f
    );
  }
  f = message.getAndroidtvapikey();
  if (f.length > 0) {
    writer.writeString(
      425,
      f
    );
  }
  f = message.getMenuorderList();
  if (f.length > 0) {
    writer.writePackedEnum(
      500,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Email = 3;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string GroupID = 4;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getGroupid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string GroupName = 5;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getGroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setGroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Category = 6;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string BitlyAPI = 10;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getBitlyapi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setBitlyapi = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string LoginDesktopBackgroundPhoto = 18;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getLogindesktopbackgroundphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setLogindesktopbackgroundphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional Font Font = 19;
 * @return {!proto.commUnity.Font}
 */
proto.commUnity.Customer.prototype.getFont = function() {
  return /** @type {!proto.commUnity.Font} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.commUnity.Font} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setFont = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional int32 FontSize = 25;
 * @return {number}
 */
proto.commUnity.Customer.prototype.getFontsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setFontsize = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional string LoginBackgroundPhoto = 37;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getLoginbackgroundphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setLoginbackgroundphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string DesktopBannerPhoto = 29;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getDesktopbannerphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setDesktopbannerphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string DesktopBackgroundPhoto = 36;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getDesktopbackgroundphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setDesktopbackgroundphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string Logo = 24;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string BackgroundPhoto = 20;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getBackgroundphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setBackgroundphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string Color = 21;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string BackGroundProfile = 22;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getBackgroundprofile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setBackgroundprofile = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional Address Address = 23;
 * @return {?proto.commUnity.Address}
 */
proto.commUnity.Customer.prototype.getAddress = function() {
  return /** @type{?proto.commUnity.Address} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.Address, 23));
};


/**
 * @param {?proto.commUnity.Address|undefined} value
 * @return {!proto.commUnity.Customer} returns this
*/
proto.commUnity.Customer.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional string RefererURL = 30;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getRefererurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setRefererurl = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional bool ShowCustomizedMenu = 98;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowcustomizedmenu = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 98, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowcustomizedmenu = function(value) {
  return jspb.Message.setProto3BooleanField(this, 98, value);
};


/**
 * optional bool ShowLinks = 99;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowlinks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 99, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowlinks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 99, value);
};


/**
 * optional bool ShowEvent = 100;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowevent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowevent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 100, value);
};


/**
 * optional bool ShowBenefit = 101;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowbenefit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 101, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowbenefit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 101, value);
};


/**
 * optional bool ShowNews = 102;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShownews = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShownews = function(value) {
  return jspb.Message.setProto3BooleanField(this, 102, value);
};


/**
 * optional bool ShowShop = 103;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowshop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 103, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowshop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 103, value);
};


/**
 * optional bool ShowShopPWA = 156;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowshoppwa = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 156, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowshoppwa = function(value) {
  return jspb.Message.setProto3BooleanField(this, 156, value);
};


/**
 * optional bool ShowShopToGuest = 150;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowshoptoguest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 150, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowshoptoguest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 150, value);
};


/**
 * optional bool ShowForum = 104;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowforum = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 104, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowforum = function(value) {
  return jspb.Message.setProto3BooleanField(this, 104, value);
};


/**
 * optional bool ShowAlert = 105;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowalert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 105, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowalert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 105, value);
};


/**
 * optional bool ShowCompetition = 106;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowcompetition = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 106, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowcompetition = function(value) {
  return jspb.Message.setProto3BooleanField(this, 106, value);
};


/**
 * optional bool ShowAd = 107;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowad = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 107, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowad = function(value) {
  return jspb.Message.setProto3BooleanField(this, 107, value);
};


/**
 * optional bool ShowAlbum = 108;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowalbum = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 108, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowalbum = function(value) {
  return jspb.Message.setProto3BooleanField(this, 108, value);
};


/**
 * optional bool ShowLeaderBoard = 109;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowleaderboard = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 109, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowleaderboard = function(value) {
  return jspb.Message.setProto3BooleanField(this, 109, value);
};


/**
 * optional bool ShowMember = 110;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowmember = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 110, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowmember = function(value) {
  return jspb.Message.setProto3BooleanField(this, 110, value);
};


/**
 * optional bool ShowDocument = 146;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowdocument = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 146, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowdocument = function(value) {
  return jspb.Message.setProto3BooleanField(this, 146, value);
};


/**
 * optional bool ShowDocumentPWA = 147;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowdocumentpwa = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 147, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowdocumentpwa = function(value) {
  return jspb.Message.setProto3BooleanField(this, 147, value);
};


/**
 * optional bool ShowBusinessCard = 148;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowbusinesscard = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 148, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowbusinesscard = function(value) {
  return jspb.Message.setProto3BooleanField(this, 148, value);
};


/**
 * optional bool ShowBusinessCardPWA = 149;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowbusinesscardpwa = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 149, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowbusinesscardpwa = function(value) {
  return jspb.Message.setProto3BooleanField(this, 149, value);
};


/**
 * optional bool ShowMyAccount = 135;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowmyaccount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 135, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowmyaccount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 135, value);
};


/**
 * optional bool ShowRestaurant = 136;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowrestaurant = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 136, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowrestaurant = function(value) {
  return jspb.Message.setProto3BooleanField(this, 136, value);
};


/**
 * optional bool CanSignUp = 111;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getCansignup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 111, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setCansignup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 111, value);
};


/**
 * optional bool ShowPlace = 112;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowplace = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 112, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowplace = function(value) {
  return jspb.Message.setProto3BooleanField(this, 112, value);
};


/**
 * optional bool ShowSearch = 113;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowsearch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 113, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowsearch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 113, value);
};


/**
 * optional bool ShowPromotion = 131;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowpromotion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 131, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowpromotion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 131, value);
};


/**
 * optional bool ShowCustomerName = 119;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowcustomername = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 119, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowcustomername = function(value) {
  return jspb.Message.setProto3BooleanField(this, 119, value);
};


/**
 * optional bool MenuBackground = 114;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getMenubackground = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 114, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setMenubackground = function(value) {
  return jspb.Message.setProto3BooleanField(this, 114, value);
};


/**
 * optional string MenuBackgroundColor = 115;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getMenubackgroundcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 115, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setMenubackgroundcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 115, value);
};


/**
 * optional string MenuForegroundColor = 116;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getMenuforegroundcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 116, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setMenuforegroundcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 116, value);
};


/**
 * optional uint32 MenuBackgroundOpacity = 117;
 * @return {number}
 */
proto.commUnity.Customer.prototype.getMenubackgroundopacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 117, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setMenubackgroundopacity = function(value) {
  return jspb.Message.setProto3IntField(this, 117, value);
};


/**
 * optional bool NeedSubscription = 118;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getNeedsubscription = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 118, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setNeedsubscription = function(value) {
  return jspb.Message.setProto3BooleanField(this, 118, value);
};


/**
 * optional MobileApp MobileApp = 120;
 * @return {!proto.commUnity.MobileApp}
 */
proto.commUnity.Customer.prototype.getMobileapp = function() {
  return /** @type {!proto.commUnity.MobileApp} */ (jspb.Message.getFieldWithDefault(this, 120, 0));
};


/**
 * @param {!proto.commUnity.MobileApp} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setMobileapp = function(value) {
  return jspb.Message.setProto3EnumField(this, 120, value);
};


/**
 * optional string LydiaAPI = 121;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getLydiaapi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 121, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setLydiaapi = function(value) {
  return jspb.Message.setProto3StringField(this, 121, value);
};


/**
 * optional bool LydiaProd = 128;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getLydiaprod = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 128, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setLydiaprod = function(value) {
  return jspb.Message.setProto3BooleanField(this, 128, value);
};


/**
 * optional bool ShowShopProfile = 129;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowshopprofile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 129, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowshopprofile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 129, value);
};


/**
 * optional bool ShowPlaceMenu = 145;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowplacemenu = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 145, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowplacemenu = function(value) {
  return jspb.Message.setProto3BooleanField(this, 145, value);
};


/**
 * optional string GuestUserName = 123;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getGuestusername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 123, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setGuestusername = function(value) {
  return jspb.Message.setProto3StringField(this, 123, value);
};


/**
 * optional string GuestUserPwd = 124;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getGuestuserpwd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 124, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setGuestuserpwd = function(value) {
  return jspb.Message.setProto3StringField(this, 124, value);
};


/**
 * optional bool AutoGuest = 125;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getAutoguest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 125, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setAutoguest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 125, value);
};


/**
 * optional bool BackgroundPhotoSkretch = 126;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getBackgroundphotoskretch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 126, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setBackgroundphotoskretch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 126, value);
};


/**
 * optional bool LoginBackgroundPhotoSkretch = 127;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getLoginbackgroundphotoskretch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 127, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setLoginbackgroundphotoskretch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 127, value);
};


/**
 * optional bool BackgroundDesktopPhotoSkretch = 151;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getBackgrounddesktopphotoskretch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 151, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setBackgrounddesktopphotoskretch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 151, value);
};


/**
 * optional bool LoginBackgroundDesktopPhotoSkretch = 152;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getLoginbackgrounddesktopphotoskretch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 152, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setLoginbackgrounddesktopphotoskretch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 152, value);
};


/**
 * optional bool ShowAttendance = 167;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowattendance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 167, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowattendance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 167, value);
};


/**
 * optional bool ShowNews2 = 153;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShownews2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 153, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShownews2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 153, value);
};


/**
 * optional bool ShowNews3 = 154;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShownews3 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 154, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShownews3 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 154, value);
};


/**
 * optional bool ShowNews4 = 155;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShownews4 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 155, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShownews4 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 155, value);
};


/**
 * optional bool ShowCPS = 352;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowcps = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 352, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowcps = function(value) {
  return jspb.Message.setProto3BooleanField(this, 352, value);
};


/**
 * optional bool ShowLink2 = 353;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowlink2 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 353, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowlink2 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 353, value);
};


/**
 * optional bool ShowLink3 = 354;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowlink3 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 354, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowlink3 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 354, value);
};


/**
 * optional bool ShowLink4 = 355;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowlink4 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 355, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowlink4 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 355, value);
};


/**
 * optional bool ShowEventTableReservation = 356;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShoweventtablereservation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 356, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShoweventtablereservation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 356, value);
};


/**
 * optional LoginTemplate LoginForegroundColor = 130;
 * @return {!proto.commUnity.LoginTemplate}
 */
proto.commUnity.Customer.prototype.getLoginforegroundcolor = function() {
  return /** @type {!proto.commUnity.LoginTemplate} */ (jspb.Message.getFieldWithDefault(this, 130, 0));
};


/**
 * @param {!proto.commUnity.LoginTemplate} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setLoginforegroundcolor = function(value) {
  return jspb.Message.setProto3EnumField(this, 130, value);
};


/**
 * optional string AlertBackgroundColor = 140;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getAlertbackgroundcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 140, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setAlertbackgroundcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 140, value);
};


/**
 * optional string AlertTextForegroundColor = 141;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getAlerttextforegroundcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 141, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setAlerttextforegroundcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 141, value);
};


/**
 * map<int32, TemplateMail> EmailTemplateChangePassword = 200;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.commUnity.TemplateMail>}
 */
proto.commUnity.Customer.prototype.getEmailtemplatechangepasswordMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.commUnity.TemplateMail>} */ (
      jspb.Message.getMapField(this, 200, opt_noLazyCreate,
      proto.commUnity.TemplateMail));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.clearEmailtemplatechangepasswordMap = function() {
  this.getEmailtemplatechangepasswordMap().clear();
  return this;};


/**
 * map<int32, TemplateMail> EmailTemplateSignUp = 201;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.commUnity.TemplateMail>}
 */
proto.commUnity.Customer.prototype.getEmailtemplatesignupMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.commUnity.TemplateMail>} */ (
      jspb.Message.getMapField(this, 201, opt_noLazyCreate,
      proto.commUnity.TemplateMail));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.clearEmailtemplatesignupMap = function() {
  this.getEmailtemplatesignupMap().clear();
  return this;};


/**
 * map<int32, TemplateMail> EmailTemplateWelcome = 202;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.commUnity.TemplateMail>}
 */
proto.commUnity.Customer.prototype.getEmailtemplatewelcomeMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.commUnity.TemplateMail>} */ (
      jspb.Message.getMapField(this, 202, opt_noLazyCreate,
      proto.commUnity.TemplateMail));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.clearEmailtemplatewelcomeMap = function() {
  this.getEmailtemplatewelcomeMap().clear();
  return this;};


/**
 * map<int32, TemplateMail> EmailTemplateReceiveOrder = 203;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.commUnity.TemplateMail>}
 */
proto.commUnity.Customer.prototype.getEmailtemplatereceiveorderMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.commUnity.TemplateMail>} */ (
      jspb.Message.getMapField(this, 203, opt_noLazyCreate,
      proto.commUnity.TemplateMail));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.clearEmailtemplatereceiveorderMap = function() {
  this.getEmailtemplatereceiveorderMap().clear();
  return this;};


/**
 * map<int32, TemplateMail> EmailTemplateThankOrder = 204;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.commUnity.TemplateMail>}
 */
proto.commUnity.Customer.prototype.getEmailtemplatethankorderMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.commUnity.TemplateMail>} */ (
      jspb.Message.getMapField(this, 204, opt_noLazyCreate,
      proto.commUnity.TemplateMail));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.clearEmailtemplatethankorderMap = function() {
  this.getEmailtemplatethankorderMap().clear();
  return this;};


/**
 * map<int32, TemplateMail> EmailTemplateShippedOrder = 205;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.commUnity.TemplateMail>}
 */
proto.commUnity.Customer.prototype.getEmailtemplateshippedorderMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.commUnity.TemplateMail>} */ (
      jspb.Message.getMapField(this, 205, opt_noLazyCreate,
      proto.commUnity.TemplateMail));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.clearEmailtemplateshippedorderMap = function() {
  this.getEmailtemplateshippedorderMap().clear();
  return this;};


/**
 * map<int32, TemplateMail> EmailTemplateAlert = 206;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.commUnity.TemplateMail>}
 */
proto.commUnity.Customer.prototype.getEmailtemplatealertMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.commUnity.TemplateMail>} */ (
      jspb.Message.getMapField(this, 206, opt_noLazyCreate,
      proto.commUnity.TemplateMail));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.clearEmailtemplatealertMap = function() {
  this.getEmailtemplatealertMap().clear();
  return this;};


/**
 * map<int32, TemplateMail> EmailTemplateEventTableReservation = 207;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.commUnity.TemplateMail>}
 */
proto.commUnity.Customer.prototype.getEmailtemplateeventtablereservationMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.commUnity.TemplateMail>} */ (
      jspb.Message.getMapField(this, 207, opt_noLazyCreate,
      proto.commUnity.TemplateMail));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.clearEmailtemplateeventtablereservationMap = function() {
  this.getEmailtemplateeventtablereservationMap().clear();
  return this;};


/**
 * map<int32, TemplateMail> EmailTemplateEventTableCancel = 208;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.commUnity.TemplateMail>}
 */
proto.commUnity.Customer.prototype.getEmailtemplateeventtablecancelMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.commUnity.TemplateMail>} */ (
      jspb.Message.getMapField(this, 208, opt_noLazyCreate,
      proto.commUnity.TemplateMail));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.clearEmailtemplateeventtablecancelMap = function() {
  this.getEmailtemplateeventtablecancelMap().clear();
  return this;};


/**
 * repeated OS MobileOS = 300;
 * @return {!Array<!proto.commUnity.OS>}
 */
proto.commUnity.Customer.prototype.getMobileosList = function() {
  return /** @type {!Array<!proto.commUnity.OS>} */ (jspb.Message.getRepeatedField(this, 300));
};


/**
 * @param {!Array<!proto.commUnity.OS>} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setMobileosList = function(value) {
  return jspb.Message.setField(this, 300, value || []);
};


/**
 * @param {!proto.commUnity.OS} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.addMobileos = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 300, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.clearMobileosList = function() {
  return this.setMobileosList([]);
};


/**
 * optional MobileMenuType MobileMenuType = 320;
 * @return {!proto.commUnity.MobileMenuType}
 */
proto.commUnity.Customer.prototype.getMobilemenutype = function() {
  return /** @type {!proto.commUnity.MobileMenuType} */ (jspb.Message.getFieldWithDefault(this, 320, 0));
};


/**
 * @param {!proto.commUnity.MobileMenuType} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setMobilemenutype = function(value) {
  return jspb.Message.setProto3EnumField(this, 320, value);
};


/**
 * optional DesktopMenuType DesktopMenuType = 321;
 * @return {!proto.commUnity.DesktopMenuType}
 */
proto.commUnity.Customer.prototype.getDesktopmenutype = function() {
  return /** @type {!proto.commUnity.DesktopMenuType} */ (jspb.Message.getFieldWithDefault(this, 321, 0));
};


/**
 * @param {!proto.commUnity.DesktopMenuType} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setDesktopmenutype = function(value) {
  return jspb.Message.setProto3EnumField(this, 321, value);
};


/**
 * optional string DesktopRedirectURL = 325;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getDesktopredirecturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 325, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setDesktopredirecturl = function(value) {
  return jspb.Message.setProto3StringField(this, 325, value);
};


/**
 * optional bool ShowStreamPWA = 362;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowstreampwa = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 362, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowstreampwa = function(value) {
  return jspb.Message.setProto3BooleanField(this, 362, value);
};


/**
 * optional bool ShowStream2PWA = 363;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowstream2pwa = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 363, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowstream2pwa = function(value) {
  return jspb.Message.setProto3BooleanField(this, 363, value);
};


/**
 * optional bool ShowStream3PWA = 364;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowstream3pwa = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 364, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowstream3pwa = function(value) {
  return jspb.Message.setProto3BooleanField(this, 364, value);
};


/**
 * optional bool ShowStream4PWA = 365;
 * @return {boolean}
 */
proto.commUnity.Customer.prototype.getShowstream4pwa = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 365, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setShowstream4pwa = function(value) {
  return jspb.Message.setProto3BooleanField(this, 365, value);
};


/**
 * optional string TelegraphAccessToken = 400;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getTelegraphaccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 400, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setTelegraphaccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 400, value);
};


/**
 * optional string EverStreamPrivatekey = 420;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getEverstreamprivatekey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 420, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setEverstreamprivatekey = function(value) {
  return jspb.Message.setProto3StringField(this, 420, value);
};


/**
 * optional string AndroidTVAPIKey = 425;
 * @return {string}
 */
proto.commUnity.Customer.prototype.getAndroidtvapikey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 425, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setAndroidtvapikey = function(value) {
  return jspb.Message.setProto3StringField(this, 425, value);
};


/**
 * repeated MenuName MenuOrder = 500;
 * @return {!Array<!proto.commUnity.MenuName>}
 */
proto.commUnity.Customer.prototype.getMenuorderList = function() {
  return /** @type {!Array<!proto.commUnity.MenuName>} */ (jspb.Message.getRepeatedField(this, 500));
};


/**
 * @param {!Array<!proto.commUnity.MenuName>} value
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.setMenuorderList = function(value) {
  return jspb.Message.setField(this, 500, value || []);
};


/**
 * @param {!proto.commUnity.MenuName} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.addMenuorder = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 500, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Customer} returns this
 */
proto.commUnity.Customer.prototype.clearMenuorderList = function() {
  return this.setMenuorderList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.TemplateMail.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.TemplateMail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.TemplateMail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.TemplateMail.toObject = function(includeInstance, msg) {
  var f, obj = {
    subject: jspb.Message.getFieldWithDefault(msg, 1, ""),
    body: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.TemplateMail}
 */
proto.commUnity.TemplateMail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.TemplateMail;
  return proto.commUnity.TemplateMail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.TemplateMail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.TemplateMail}
 */
proto.commUnity.TemplateMail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.TemplateMail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.TemplateMail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.TemplateMail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.TemplateMail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Subject = 1;
 * @return {string}
 */
proto.commUnity.TemplateMail.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.TemplateMail} returns this
 */
proto.commUnity.TemplateMail.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Body = 2;
 * @return {string}
 */
proto.commUnity.TemplateMail.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.TemplateMail} returns this
 */
proto.commUnity.TemplateMail.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.RequestMembers.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.RequestMembers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.RequestMembers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RequestMembers.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    membertype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    customerid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    company: jspb.Message.getFieldWithDefault(msg, 5, ""),
    renewbefore: jspb.Message.getFieldWithDefault(msg, 34, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.RequestMembers}
 */
proto.commUnity.RequestMembers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.RequestMembers;
  return proto.commUnity.RequestMembers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.RequestMembers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.RequestMembers}
 */
proto.commUnity.RequestMembers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembertype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRenewbefore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.RequestMembers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.RequestMembers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.RequestMembers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RequestMembers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMembertype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCustomerid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRenewbefore();
  if (f !== 0) {
    writer.writeUint32(
      34,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.RequestMembers.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RequestMembers} returns this
 */
proto.commUnity.RequestMembers.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.commUnity.RequestMembers.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RequestMembers} returns this
 */
proto.commUnity.RequestMembers.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string MemberType = 3;
 * @return {string}
 */
proto.commUnity.RequestMembers.prototype.getMembertype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RequestMembers} returns this
 */
proto.commUnity.RequestMembers.prototype.setMembertype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string CustomerID = 4;
 * @return {string}
 */
proto.commUnity.RequestMembers.prototype.getCustomerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RequestMembers} returns this
 */
proto.commUnity.RequestMembers.prototype.setCustomerid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Company = 5;
 * @return {string}
 */
proto.commUnity.RequestMembers.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RequestMembers} returns this
 */
proto.commUnity.RequestMembers.prototype.setCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 RenewBefore = 34;
 * @return {number}
 */
proto.commUnity.RequestMembers.prototype.getRenewbefore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.RequestMembers} returns this
 */
proto.commUnity.RequestMembers.prototype.setRenewbefore = function(value) {
  return jspb.Message.setProto3IntField(this, 34, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Empty}
 */
proto.commUnity.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Empty;
  return proto.commUnity.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Empty}
 */
proto.commUnity.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Album.repeatedFields_ = [4,100];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Album.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Album.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Album} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Album.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    shortdescription: jspb.Message.getFieldWithDefault(msg, 8, ""),
    imagesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    youtube: jspb.Message.getFieldWithDefault(msg, 5, ""),
    publishdate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    endpublishdate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    placeid: jspb.Message.getFieldWithDefault(msg, 20, ""),
    placename: jspb.Message.getFieldWithDefault(msg, 21, ""),
    membertypesList: (f = jspb.Message.getRepeatedField(msg, 100)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Album}
 */
proto.commUnity.Album.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Album;
  return proto.commUnity.Album.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Album} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Album}
 */
proto.commUnity.Album.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortdescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addImages(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setYoutube(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPublishdate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndpublishdate(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceid(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacename(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembertypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Album.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Album.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Album} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Album.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShortdescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getYoutube();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPublishdate();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getEndpublishdate();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getPlaceid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPlacename();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getMembertypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Album.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.commUnity.Album.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.commUnity.Album.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string shortDescription = 8;
 * @return {string}
 */
proto.commUnity.Album.prototype.getShortdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.setShortdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string images = 4;
 * @return {!Array<string>}
 */
proto.commUnity.Album.prototype.getImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.setImagesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.addImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string youtube = 5;
 * @return {string}
 */
proto.commUnity.Album.prototype.getYoutube = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.setYoutube = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 publishDate = 6;
 * @return {number}
 */
proto.commUnity.Album.prototype.getPublishdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.setPublishdate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 endPublishDate = 7;
 * @return {number}
 */
proto.commUnity.Album.prototype.getEndpublishdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.setEndpublishdate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string PlaceID = 20;
 * @return {string}
 */
proto.commUnity.Album.prototype.getPlaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.setPlaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string PlaceName = 21;
 * @return {string}
 */
proto.commUnity.Album.prototype.getPlacename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.setPlacename = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated string MemberTypes = 100;
 * @return {!Array<string>}
 */
proto.commUnity.Album.prototype.getMembertypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 100));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.setMembertypesList = function(value) {
  return jspb.Message.setField(this, 100, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.addMembertypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 100, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Album} returns this
 */
proto.commUnity.Album.prototype.clearMembertypesList = function() {
  return this.setMembertypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Member.repeatedFields_ = [2,26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Member.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Member.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Member} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Member.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    membertypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    title: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    company: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pb_function: jspb.Message.getFieldWithDefault(msg, 7, ""),
    photo: jspb.Message.getFieldWithDefault(msg, 8, ""),
    activitysector: jspb.Message.getFieldWithDefault(msg, 10, ""),
    description: jspb.Message.getFieldWithDefault(msg, 11, ""),
    url: jspb.Message.getFieldWithDefault(msg, 12, ""),
    shortdescription: jspb.Message.getFieldWithDefault(msg, 13, ""),
    professionaladdress: (f = msg.getProfessionaladdress()) && proto.commUnity.Address.toObject(includeInstance, f),
    personaladdress: (f = msg.getPersonaladdress()) && proto.commUnity.Address.toObject(includeInstance, f),
    language: jspb.Message.getFieldWithDefault(msg, 20, 0),
    birthday: jspb.Message.getFieldWithDefault(msg, 21, 0),
    username: jspb.Message.getFieldWithDefault(msg, 22, ""),
    socialnetwork: (f = msg.getSocialnetwork()) && proto.commUnity.SocialNetwork.toObject(includeInstance, f),
    interestList: (f = jspb.Message.getRepeatedField(msg, 26)) == null ? undefined : f,
    mobileos: jspb.Message.getFieldWithDefault(msg, 27, 0),
    index: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    license: jspb.Message.getFieldWithDefault(msg, 31, ""),
    membersince: jspb.Message.getFieldWithDefault(msg, 33, 0),
    renewon: jspb.Message.getFieldWithDefault(msg, 34, 0),
    customerid: jspb.Message.getFieldWithDefault(msg, 35, ""),
    customername: jspb.Message.getFieldWithDefault(msg, 37, ""),
    sponsor: jspb.Message.getFieldWithDefault(msg, 40, ""),
    polosize: jspb.Message.getFieldWithDefault(msg, 42, 0),
    paidok: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
    cecard: jspb.Message.getBooleanFieldWithDefault(msg, 44, false),
    isguest: jspb.Message.getBooleanFieldWithDefault(msg, 45, false),
    memo: jspb.Message.getFieldWithDefault(msg, 50, ""),
    fcmtoken: jspb.Message.getFieldWithDefault(msg, 60, ""),
    lastusage: jspb.Message.getFieldWithDefault(msg, 61, 0),
    pb_private: jspb.Message.getBooleanFieldWithDefault(msg, 65, false),
    acceptdisclaimeron: jspb.Message.getFieldWithDefault(msg, 70, 0),
    groupid: jspb.Message.getFieldWithDefault(msg, 100, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Member}
 */
proto.commUnity.Member.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Member;
  return proto.commUnity.Member.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Member} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Member}
 */
proto.commUnity.Member.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembertypes(value);
      break;
    case 3:
      var value = /** @type {!proto.commUnity.Title} */ (reader.readEnum());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFunction(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoto(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivitysector(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortdescription(value);
      break;
    case 15:
      var value = new proto.commUnity.Address;
      reader.readMessage(value,proto.commUnity.Address.deserializeBinaryFromReader);
      msg.setProfessionaladdress(value);
      break;
    case 16:
      var value = new proto.commUnity.Address;
      reader.readMessage(value,proto.commUnity.Address.deserializeBinaryFromReader);
      msg.setPersonaladdress(value);
      break;
    case 20:
      var value = /** @type {!proto.commUnity.Language} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBirthday(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 25:
      var value = new proto.commUnity.SocialNetwork;
      reader.readMessage(value,proto.commUnity.SocialNetwork.deserializeBinaryFromReader);
      msg.setSocialnetwork(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.addInterest(value);
      break;
    case 27:
      var value = /** @type {!proto.commUnity.OS} */ (reader.readEnum());
      msg.setMobileos(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIndex(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicense(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMembersince(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRenewon(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerid(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomername(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setSponsor(value);
      break;
    case 42:
      var value = /** @type {!proto.commUnity.PoloSize} */ (reader.readEnum());
      msg.setPolosize(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaidok(value);
      break;
    case 44:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCecard(value);
      break;
    case 45:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsguest(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemo(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setFcmtoken(value);
      break;
    case 61:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastusage(value);
      break;
    case 65:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    case 70:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcceptdisclaimeron(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Member.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Member.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Member} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Member.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMembertypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFunction();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhoto();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getActivitysector();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getShortdescription();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getProfessionaladdress();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.commUnity.Address.serializeBinaryToWriter
    );
  }
  f = message.getPersonaladdress();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.commUnity.Address.serializeBinaryToWriter
    );
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getBirthday();
  if (f !== 0) {
    writer.writeUint32(
      21,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getSocialnetwork();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.commUnity.SocialNetwork.serializeBinaryToWriter
    );
  }
  f = message.getInterestList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      26,
      f
    );
  }
  f = message.getMobileos();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getLicense();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getMembersince();
  if (f !== 0) {
    writer.writeUint32(
      33,
      f
    );
  }
  f = message.getRenewon();
  if (f !== 0) {
    writer.writeUint32(
      34,
      f
    );
  }
  f = message.getCustomerid();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getCustomername();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getSponsor();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getPolosize();
  if (f !== 0.0) {
    writer.writeEnum(
      42,
      f
    );
  }
  f = message.getPaidok();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getCecard();
  if (f) {
    writer.writeBool(
      44,
      f
    );
  }
  f = message.getIsguest();
  if (f) {
    writer.writeBool(
      45,
      f
    );
  }
  f = message.getMemo();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getFcmtoken();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getLastusage();
  if (f !== 0) {
    writer.writeInt64(
      61,
      f
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      65,
      f
    );
  }
  f = message.getAcceptdisclaimeron();
  if (f !== 0) {
    writer.writeInt64(
      70,
      f
    );
  }
  f = message.getGroupid();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Member.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string MemberTypes = 2;
 * @return {!Array<string>}
 */
proto.commUnity.Member.prototype.getMembertypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setMembertypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.addMembertypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.clearMembertypesList = function() {
  return this.setMembertypesList([]);
};


/**
 * optional Title Title = 3;
 * @return {!proto.commUnity.Title}
 */
proto.commUnity.Member.prototype.getTitle = function() {
  return /** @type {!proto.commUnity.Title} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.commUnity.Title} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setTitle = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string LastName = 4;
 * @return {string}
 */
proto.commUnity.Member.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string FirstName = 5;
 * @return {string}
 */
proto.commUnity.Member.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Company = 6;
 * @return {string}
 */
proto.commUnity.Member.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Function = 7;
 * @return {string}
 */
proto.commUnity.Member.prototype.getFunction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setFunction = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Photo = 8;
 * @return {string}
 */
proto.commUnity.Member.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string ActivitySector = 10;
 * @return {string}
 */
proto.commUnity.Member.prototype.getActivitysector = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setActivitysector = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string Description = 11;
 * @return {string}
 */
proto.commUnity.Member.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string URL = 12;
 * @return {string}
 */
proto.commUnity.Member.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string ShortDescription = 13;
 * @return {string}
 */
proto.commUnity.Member.prototype.getShortdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setShortdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional Address ProfessionalAddress = 15;
 * @return {?proto.commUnity.Address}
 */
proto.commUnity.Member.prototype.getProfessionaladdress = function() {
  return /** @type{?proto.commUnity.Address} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.Address, 15));
};


/**
 * @param {?proto.commUnity.Address|undefined} value
 * @return {!proto.commUnity.Member} returns this
*/
proto.commUnity.Member.prototype.setProfessionaladdress = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.clearProfessionaladdress = function() {
  return this.setProfessionaladdress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.Member.prototype.hasProfessionaladdress = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Address PersonalAddress = 16;
 * @return {?proto.commUnity.Address}
 */
proto.commUnity.Member.prototype.getPersonaladdress = function() {
  return /** @type{?proto.commUnity.Address} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.Address, 16));
};


/**
 * @param {?proto.commUnity.Address|undefined} value
 * @return {!proto.commUnity.Member} returns this
*/
proto.commUnity.Member.prototype.setPersonaladdress = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.clearPersonaladdress = function() {
  return this.setPersonaladdress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.Member.prototype.hasPersonaladdress = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Language Language = 20;
 * @return {!proto.commUnity.Language}
 */
proto.commUnity.Member.prototype.getLanguage = function() {
  return /** @type {!proto.commUnity.Language} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.commUnity.Language} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional uint32 Birthday = 21;
 * @return {number}
 */
proto.commUnity.Member.prototype.getBirthday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional string UserName = 22;
 * @return {string}
 */
proto.commUnity.Member.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional SocialNetwork SocialNetwork = 25;
 * @return {?proto.commUnity.SocialNetwork}
 */
proto.commUnity.Member.prototype.getSocialnetwork = function() {
  return /** @type{?proto.commUnity.SocialNetwork} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.SocialNetwork, 25));
};


/**
 * @param {?proto.commUnity.SocialNetwork|undefined} value
 * @return {!proto.commUnity.Member} returns this
*/
proto.commUnity.Member.prototype.setSocialnetwork = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.clearSocialnetwork = function() {
  return this.setSocialnetwork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.Member.prototype.hasSocialnetwork = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * repeated string Interest = 26;
 * @return {!Array<string>}
 */
proto.commUnity.Member.prototype.getInterestList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 26));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setInterestList = function(value) {
  return jspb.Message.setField(this, 26, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.addInterest = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 26, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.clearInterestList = function() {
  return this.setInterestList([]);
};


/**
 * optional OS MobileOS = 27;
 * @return {!proto.commUnity.OS}
 */
proto.commUnity.Member.prototype.getMobileos = function() {
  return /** @type {!proto.commUnity.OS} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {!proto.commUnity.OS} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setMobileos = function(value) {
  return jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * optional double Index = 30;
 * @return {number}
 */
proto.commUnity.Member.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setIndex = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional string License = 31;
 * @return {string}
 */
proto.commUnity.Member.prototype.getLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional uint32 MemberSince = 33;
 * @return {number}
 */
proto.commUnity.Member.prototype.getMembersince = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setMembersince = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * optional uint32 RenewOn = 34;
 * @return {number}
 */
proto.commUnity.Member.prototype.getRenewon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setRenewon = function(value) {
  return jspb.Message.setProto3IntField(this, 34, value);
};


/**
 * optional string CustomerID = 35;
 * @return {string}
 */
proto.commUnity.Member.prototype.getCustomerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setCustomerid = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string CustomerName = 37;
 * @return {string}
 */
proto.commUnity.Member.prototype.getCustomername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setCustomername = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string Sponsor = 40;
 * @return {string}
 */
proto.commUnity.Member.prototype.getSponsor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setSponsor = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional PoloSize PoloSize = 42;
 * @return {!proto.commUnity.PoloSize}
 */
proto.commUnity.Member.prototype.getPolosize = function() {
  return /** @type {!proto.commUnity.PoloSize} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {!proto.commUnity.PoloSize} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setPolosize = function(value) {
  return jspb.Message.setProto3EnumField(this, 42, value);
};


/**
 * optional bool PaidOK = 43;
 * @return {boolean}
 */
proto.commUnity.Member.prototype.getPaidok = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setPaidok = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional bool CECard = 44;
 * @return {boolean}
 */
proto.commUnity.Member.prototype.getCecard = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 44, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setCecard = function(value) {
  return jspb.Message.setProto3BooleanField(this, 44, value);
};


/**
 * optional bool IsGuest = 45;
 * @return {boolean}
 */
proto.commUnity.Member.prototype.getIsguest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 45, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setIsguest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 45, value);
};


/**
 * optional string Memo = 50;
 * @return {string}
 */
proto.commUnity.Member.prototype.getMemo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setMemo = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string FCMToken = 60;
 * @return {string}
 */
proto.commUnity.Member.prototype.getFcmtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setFcmtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional int64 LastUsage = 61;
 * @return {number}
 */
proto.commUnity.Member.prototype.getLastusage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 61, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setLastusage = function(value) {
  return jspb.Message.setProto3IntField(this, 61, value);
};


/**
 * optional bool Private = 65;
 * @return {boolean}
 */
proto.commUnity.Member.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 65, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setPrivate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 65, value);
};


/**
 * optional int64 AcceptDisclaimerOn = 70;
 * @return {number}
 */
proto.commUnity.Member.prototype.getAcceptdisclaimeron = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 70, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setAcceptdisclaimeron = function(value) {
  return jspb.Message.setProto3IntField(this, 70, value);
};


/**
 * optional string GroupID = 100;
 * @return {string}
 */
proto.commUnity.Member.prototype.getGroupid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Member} returns this
 */
proto.commUnity.Member.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.EventType.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.EventType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.EventType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.EventType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 8, ""),
    color: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.EventType}
 */
proto.commUnity.EventType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.EventType;
  return proto.commUnity.EventType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.EventType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.EventType}
 */
proto.commUnity.EventType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = /** @type {!proto.commUnity.Color} */ (reader.readEnum());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.EventType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.EventType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.EventType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.EventType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getColor();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.EventType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.EventType} returns this
 */
proto.commUnity.EventType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 8;
 * @return {string}
 */
proto.commUnity.EventType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.EventType} returns this
 */
proto.commUnity.EventType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Color Color = 10;
 * @return {!proto.commUnity.Color}
 */
proto.commUnity.EventType.prototype.getColor = function() {
  return /** @type {!proto.commUnity.Color} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.commUnity.Color} value
 * @return {!proto.commUnity.EventType} returns this
 */
proto.commUnity.EventType.prototype.setColor = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Event.repeatedFields_ = [100];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: jspb.Message.getFieldWithDefault(msg, 2, 0),
    address: (f = msg.getAddress()) && proto.commUnity.Address.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    url: jspb.Message.getFieldWithDefault(msg, 5, ""),
    image: jspb.Message.getFieldWithDefault(msg, 6, ""),
    eventtypeid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    title: jspb.Message.getFieldWithDefault(msg, 8, ""),
    shortdescription: jspb.Message.getFieldWithDefault(msg, 9, ""),
    color: jspb.Message.getFieldWithDefault(msg, 10, 0),
    timefrom: jspb.Message.getFieldWithDefault(msg, 15, ""),
    timeto: jspb.Message.getFieldWithDefault(msg, 16, ""),
    placeid: jspb.Message.getFieldWithDefault(msg, 20, ""),
    placename: jspb.Message.getFieldWithDefault(msg, 21, ""),
    inforeservation: jspb.Message.getFieldWithDefault(msg, 22, ""),
    resulturl: jspb.Message.getFieldWithDefault(msg, 30, ""),
    resultpdf: jspb.Message.getFieldWithDefault(msg, 31, ""),
    iscompetition: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
    hastablereservation: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    eventtypename: jspb.Message.getFieldWithDefault(msg, 34, ""),
    qrcode: jspb.Message.getFieldWithDefault(msg, 40, ""),
    membertypesList: (f = jspb.Message.getRepeatedField(msg, 100)) == null ? undefined : f,
    cancanceleventuntil: jspb.Message.getFieldWithDefault(msg, 105, 0),
    qt: jspb.Message.getFieldWithDefault(msg, 110, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Event}
 */
proto.commUnity.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Event;
  return proto.commUnity.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Event}
 */
proto.commUnity.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDate(value);
      break;
    case 3:
      var value = new proto.commUnity.Address;
      reader.readMessage(value,proto.commUnity.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventtypeid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortdescription(value);
      break;
    case 10:
      var value = /** @type {!proto.commUnity.Color} */ (reader.readEnum());
      msg.setColor(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimefrom(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeto(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceid(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacename(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setInforeservation(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setResulturl(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultpdf(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscompetition(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHastablereservation(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventtypename(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setQrcode(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembertypes(value);
      break;
    case 105:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCancanceleventuntil(value);
      break;
    case 110:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.commUnity.Address.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEventtypeid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getShortdescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getColor();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getTimefrom();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTimeto();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPlaceid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPlacename();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getInforeservation();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getResulturl();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getResultpdf();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getIscompetition();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getHastablereservation();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getEventtypename();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getQrcode();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getMembertypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      100,
      f
    );
  }
  f = message.getCancanceleventuntil();
  if (f !== 0) {
    writer.writeInt64(
      105,
      f
    );
  }
  f = message.getQt();
  if (f !== 0) {
    writer.writeInt32(
      110,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Event.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 Date = 2;
 * @return {number}
 */
proto.commUnity.Event.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Address Address = 3;
 * @return {?proto.commUnity.Address}
 */
proto.commUnity.Event.prototype.getAddress = function() {
  return /** @type{?proto.commUnity.Address} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.Address, 3));
};


/**
 * @param {?proto.commUnity.Address|undefined} value
 * @return {!proto.commUnity.Event} returns this
*/
proto.commUnity.Event.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.Event.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string Description = 4;
 * @return {string}
 */
proto.commUnity.Event.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string URL = 5;
 * @return {string}
 */
proto.commUnity.Event.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string image = 6;
 * @return {string}
 */
proto.commUnity.Event.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string EventTypeID = 7;
 * @return {string}
 */
proto.commUnity.Event.prototype.getEventtypeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setEventtypeid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Title = 8;
 * @return {string}
 */
proto.commUnity.Event.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string ShortDescription = 9;
 * @return {string}
 */
proto.commUnity.Event.prototype.getShortdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setShortdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional Color Color = 10;
 * @return {!proto.commUnity.Color}
 */
proto.commUnity.Event.prototype.getColor = function() {
  return /** @type {!proto.commUnity.Color} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.commUnity.Color} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setColor = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string TimeFrom = 15;
 * @return {string}
 */
proto.commUnity.Event.prototype.getTimefrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setTimefrom = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string TimeTo = 16;
 * @return {string}
 */
proto.commUnity.Event.prototype.getTimeto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setTimeto = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string PlaceID = 20;
 * @return {string}
 */
proto.commUnity.Event.prototype.getPlaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setPlaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string PlaceName = 21;
 * @return {string}
 */
proto.commUnity.Event.prototype.getPlacename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setPlacename = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string InfoReservation = 22;
 * @return {string}
 */
proto.commUnity.Event.prototype.getInforeservation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setInforeservation = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string ResultURL = 30;
 * @return {string}
 */
proto.commUnity.Event.prototype.getResulturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setResulturl = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string ResultPDF = 31;
 * @return {string}
 */
proto.commUnity.Event.prototype.getResultpdf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setResultpdf = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional bool IsCompetition = 32;
 * @return {boolean}
 */
proto.commUnity.Event.prototype.getIscompetition = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setIscompetition = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional bool HasTableReservation = 33;
 * @return {boolean}
 */
proto.commUnity.Event.prototype.getHastablereservation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setHastablereservation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional string EventTypeName = 34;
 * @return {string}
 */
proto.commUnity.Event.prototype.getEventtypename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setEventtypename = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string QRcode = 40;
 * @return {string}
 */
proto.commUnity.Event.prototype.getQrcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setQrcode = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * repeated string MemberTypes = 100;
 * @return {!Array<string>}
 */
proto.commUnity.Event.prototype.getMembertypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 100));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setMembertypesList = function(value) {
  return jspb.Message.setField(this, 100, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.addMembertypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 100, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.clearMembertypesList = function() {
  return this.setMembertypesList([]);
};


/**
 * optional int64 CanCancelEventUntil = 105;
 * @return {number}
 */
proto.commUnity.Event.prototype.getCancanceleventuntil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 105, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setCancanceleventuntil = function(value) {
  return jspb.Message.setProto3IntField(this, 105, value);
};


/**
 * optional int32 Qt = 110;
 * @return {number}
 */
proto.commUnity.Event.prototype.getQt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 110, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Event} returns this
 */
proto.commUnity.Event.prototype.setQt = function(value) {
  return jspb.Message.setProto3IntField(this, 110, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    street: jspb.Message.getFieldWithDefault(msg, 1, ""),
    street2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    postcode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    region: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: jspb.Message.getFieldWithDefault(msg, 6, 0),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 8, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 9, ""),
    loc: (f = msg.getLoc()) && proto.commUnity.Loc.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Address}
 */
proto.commUnity.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Address;
  return proto.commUnity.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Address}
 */
proto.commUnity.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostcode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 6:
      var value = /** @type {!proto.commUnity.Country} */ (reader.readEnum());
      msg.setCountry(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 10:
      var value = new proto.commUnity.Loc;
      reader.readMessage(value,proto.commUnity.Loc.deserializeBinaryFromReader);
      msg.setLoc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStreet2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLoc();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.commUnity.Loc.serializeBinaryToWriter
    );
  }
};


/**
 * optional string street = 1;
 * @return {string}
 */
proto.commUnity.Address.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Address} returns this
 */
proto.commUnity.Address.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string street2 = 2;
 * @return {string}
 */
proto.commUnity.Address.prototype.getStreet2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Address} returns this
 */
proto.commUnity.Address.prototype.setStreet2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string postCode = 3;
 * @return {string}
 */
proto.commUnity.Address.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Address} returns this
 */
proto.commUnity.Address.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.commUnity.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Address} returns this
 */
proto.commUnity.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string region = 5;
 * @return {string}
 */
proto.commUnity.Address.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Address} returns this
 */
proto.commUnity.Address.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Country country = 6;
 * @return {!proto.commUnity.Country}
 */
proto.commUnity.Address.prototype.getCountry = function() {
  return /** @type {!proto.commUnity.Country} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.commUnity.Country} value
 * @return {!proto.commUnity.Address} returns this
 */
proto.commUnity.Address.prototype.setCountry = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.commUnity.Address.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Address} returns this
 */
proto.commUnity.Address.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phone = 8;
 * @return {string}
 */
proto.commUnity.Address.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Address} returns this
 */
proto.commUnity.Address.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string mobile = 9;
 * @return {string}
 */
proto.commUnity.Address.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Address} returns this
 */
proto.commUnity.Address.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional Loc loc = 10;
 * @return {?proto.commUnity.Loc}
 */
proto.commUnity.Address.prototype.getLoc = function() {
  return /** @type{?proto.commUnity.Loc} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.Loc, 10));
};


/**
 * @param {?proto.commUnity.Loc|undefined} value
 * @return {!proto.commUnity.Address} returns this
*/
proto.commUnity.Address.prototype.setLoc = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.Address} returns this
 */
proto.commUnity.Address.prototype.clearLoc = function() {
  return this.setLoc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.Address.prototype.hasLoc = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Disclaimer.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Disclaimer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Disclaimer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Disclaimer.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Disclaimer}
 */
proto.commUnity.Disclaimer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Disclaimer;
  return proto.commUnity.Disclaimer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Disclaimer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Disclaimer}
 */
proto.commUnity.Disclaimer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Disclaimer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Disclaimer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Disclaimer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Disclaimer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string CustomerID = 1;
 * @return {string}
 */
proto.commUnity.Disclaimer.prototype.getCustomerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Disclaimer} returns this
 */
proto.commUnity.Disclaimer.prototype.setCustomerid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Text = 2;
 * @return {string}
 */
proto.commUnity.Disclaimer.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Disclaimer} returns this
 */
proto.commUnity.Disclaimer.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.MemberType.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.MemberType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.MemberType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MemberType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 10, ""),
    signupdefault: jspb.Message.getBooleanFieldWithDefault(msg, 20, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.MemberType}
 */
proto.commUnity.MemberType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.MemberType;
  return proto.commUnity.MemberType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.MemberType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.MemberType}
 */
proto.commUnity.MemberType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSignupdefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.MemberType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.MemberType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.MemberType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MemberType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSignupdefault();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.MemberType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MemberType} returns this
 */
proto.commUnity.MemberType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 10;
 * @return {string}
 */
proto.commUnity.MemberType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MemberType} returns this
 */
proto.commUnity.MemberType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool SignUpDefault = 20;
 * @return {boolean}
 */
proto.commUnity.MemberType.prototype.getSignupdefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberType} returns this
 */
proto.commUnity.MemberType.prototype.setSignupdefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.MemberProfileSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.MemberProfileSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.MemberProfileSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MemberProfileSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    showtitle: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    showprivate: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    showcompany: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    showfunction: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    showactivitysector: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    showdescription: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    showurl: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    showshortdescription: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    showprofessionaladdress: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    showpersonaladdress: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    showbirthday: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    showsocialnetwork: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    showinterest: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    showindex: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    showlicense: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    showmembersince: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    showrenewon: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    showpolosize: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
    showdeliveryaddress: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
    showcountry: jspb.Message.getBooleanFieldWithDefault(msg, 44, false),
    showprofessionaladdressmobile: jspb.Message.getBooleanFieldWithDefault(msg, 80, false),
    showprofessionaladdressphone: jspb.Message.getBooleanFieldWithDefault(msg, 81, false),
    showprofessionaladdressemail: jspb.Message.getBooleanFieldWithDefault(msg, 82, false),
    showpersonaladdressmobile: jspb.Message.getBooleanFieldWithDefault(msg, 90, false),
    showpersonaladdressphone: jspb.Message.getBooleanFieldWithDefault(msg, 91, false),
    showpersonaladdressemail: jspb.Message.getBooleanFieldWithDefault(msg, 92, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.MemberProfileSettings}
 */
proto.commUnity.MemberProfileSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.MemberProfileSettings;
  return proto.commUnity.MemberProfileSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.MemberProfileSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.MemberProfileSettings}
 */
proto.commUnity.MemberProfileSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowtitle(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowprivate(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowcompany(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowfunction(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowactivitysector(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowdescription(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowurl(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowshortdescription(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowprofessionaladdress(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowpersonaladdress(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowbirthday(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowsocialnetwork(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowinterest(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowindex(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowlicense(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowmembersince(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowrenewon(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowpolosize(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowdeliveryaddress(value);
      break;
    case 44:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowcountry(value);
      break;
    case 80:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowprofessionaladdressmobile(value);
      break;
    case 81:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowprofessionaladdressphone(value);
      break;
    case 82:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowprofessionaladdressemail(value);
      break;
    case 90:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowpersonaladdressmobile(value);
      break;
    case 91:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowpersonaladdressphone(value);
      break;
    case 92:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowpersonaladdressemail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.MemberProfileSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.MemberProfileSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.MemberProfileSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MemberProfileSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShowtitle();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getShowprivate();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getShowcompany();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getShowfunction();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getShowactivitysector();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getShowdescription();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getShowurl();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getShowshortdescription();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getShowprofessionaladdress();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getShowpersonaladdress();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getShowbirthday();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getShowsocialnetwork();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getShowinterest();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getShowindex();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getShowlicense();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getShowmembersince();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getShowrenewon();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getShowpolosize();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getShowdeliveryaddress();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getShowcountry();
  if (f) {
    writer.writeBool(
      44,
      f
    );
  }
  f = message.getShowprofessionaladdressmobile();
  if (f) {
    writer.writeBool(
      80,
      f
    );
  }
  f = message.getShowprofessionaladdressphone();
  if (f) {
    writer.writeBool(
      81,
      f
    );
  }
  f = message.getShowprofessionaladdressemail();
  if (f) {
    writer.writeBool(
      82,
      f
    );
  }
  f = message.getShowpersonaladdressmobile();
  if (f) {
    writer.writeBool(
      90,
      f
    );
  }
  f = message.getShowpersonaladdressphone();
  if (f) {
    writer.writeBool(
      91,
      f
    );
  }
  f = message.getShowpersonaladdressemail();
  if (f) {
    writer.writeBool(
      92,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.MemberProfileSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool ShowTitle = 3;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowtitle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowtitle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool ShowPrivate = 5;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowprivate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowprivate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool ShowCompany = 6;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowcompany = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowcompany = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool ShowFunction = 7;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowfunction = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowfunction = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool ShowActivitySector = 10;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowactivitysector = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowactivitysector = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool ShowDescription = 11;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowdescription = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowdescription = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool ShowURL = 12;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowurl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowurl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool ShowShortDescription = 13;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowshortdescription = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowshortdescription = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool ShowProfessionalAddress = 15;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowprofessionaladdress = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowprofessionaladdress = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool ShowPersonalAddress = 16;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowpersonaladdress = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowpersonaladdress = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool ShowBirthday = 21;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowbirthday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowbirthday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool ShowSocialNetwork = 25;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowsocialnetwork = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowsocialnetwork = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional bool ShowInterest = 26;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowinterest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowinterest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool ShowIndex = 30;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowindex = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowindex = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional bool ShowLicense = 31;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowlicense = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowlicense = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional bool ShowMemberSince = 33;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowmembersince = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowmembersince = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool ShowRenewOn = 34;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowrenewon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowrenewon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional bool ShowPoloSize = 42;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowpolosize = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowpolosize = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional bool ShowDeliveryAddress = 43;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowdeliveryaddress = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowdeliveryaddress = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional bool ShowCountry = 44;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowcountry = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 44, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowcountry = function(value) {
  return jspb.Message.setProto3BooleanField(this, 44, value);
};


/**
 * optional bool ShowProfessionalAddressMobile = 80;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowprofessionaladdressmobile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 80, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowprofessionaladdressmobile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 80, value);
};


/**
 * optional bool ShowProfessionalAddressPhone = 81;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowprofessionaladdressphone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 81, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowprofessionaladdressphone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 81, value);
};


/**
 * optional bool ShowProfessionalAddressEmail = 82;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowprofessionaladdressemail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 82, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowprofessionaladdressemail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 82, value);
};


/**
 * optional bool ShowPersonalAddressMobile = 90;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowpersonaladdressmobile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 90, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowpersonaladdressmobile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 90, value);
};


/**
 * optional bool ShowPersonalAddressPhone = 91;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowpersonaladdressphone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 91, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowpersonaladdressphone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 91, value);
};


/**
 * optional bool ShowPersonalAddressEmail = 92;
 * @return {boolean}
 */
proto.commUnity.MemberProfileSettings.prototype.getShowpersonaladdressemail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 92, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileSettings} returns this
 */
proto.commUnity.MemberProfileSettings.prototype.setShowpersonaladdressemail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 92, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Link.repeatedFields_ = [100];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Link.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Link.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Link} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Link.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 4, 0),
    url: jspb.Message.getFieldWithDefault(msg, 5, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 8, ""),
    openinside: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    photo: jspb.Message.getFieldWithDefault(msg, 10, ""),
    membertypesList: (f = jspb.Message.getRepeatedField(msg, 100)) == null ? undefined : f,
    linknb: jspb.Message.getFieldWithDefault(msg, 200, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Link}
 */
proto.commUnity.Link.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Link;
  return proto.commUnity.Link.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Link} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Link}
 */
proto.commUnity.Link.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpeninside(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoto(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembertypes(value);
      break;
    case 200:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLinknb(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Link.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Link.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Link} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Link.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOpeninside();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPhoto();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMembertypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      100,
      f
    );
  }
  f = message.getLinknb();
  if (f !== 0) {
    writer.writeInt32(
      200,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Link.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Link} returns this
 */
proto.commUnity.Link.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.commUnity.Link.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Link} returns this
 */
proto.commUnity.Link.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 Order = 4;
 * @return {number}
 */
proto.commUnity.Link.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Link} returns this
 */
proto.commUnity.Link.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string URL = 5;
 * @return {string}
 */
proto.commUnity.Link.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Link} returns this
 */
proto.commUnity.Link.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Icon = 8;
 * @return {string}
 */
proto.commUnity.Link.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Link} returns this
 */
proto.commUnity.Link.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool OpenInside = 9;
 * @return {boolean}
 */
proto.commUnity.Link.prototype.getOpeninside = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Link} returns this
 */
proto.commUnity.Link.prototype.setOpeninside = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string Photo = 10;
 * @return {string}
 */
proto.commUnity.Link.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Link} returns this
 */
proto.commUnity.Link.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated string MemberTypes = 100;
 * @return {!Array<string>}
 */
proto.commUnity.Link.prototype.getMembertypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 100));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Link} returns this
 */
proto.commUnity.Link.prototype.setMembertypesList = function(value) {
  return jspb.Message.setField(this, 100, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Link} returns this
 */
proto.commUnity.Link.prototype.addMembertypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 100, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Link} returns this
 */
proto.commUnity.Link.prototype.clearMembertypesList = function() {
  return this.setMembertypesList([]);
};


/**
 * optional int32 LinkNb = 200;
 * @return {number}
 */
proto.commUnity.Link.prototype.getLinknb = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 200, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Link} returns this
 */
proto.commUnity.Link.prototype.setLinknb = function(value) {
  return jspb.Message.setProto3IntField(this, 200, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Stream.repeatedFields_ = [100];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Stream.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Stream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Stream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Stream.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 4, 0),
    url: jspb.Message.getFieldWithDefault(msg, 5, ""),
    customerid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 8, ""),
    openinside: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    photo: jspb.Message.getFieldWithDefault(msg, 10, ""),
    membertypesList: (f = jspb.Message.getRepeatedField(msg, 100)) == null ? undefined : f,
    linknb: jspb.Message.getFieldWithDefault(msg, 200, 0),
    showchromecast: jspb.Message.getBooleanFieldWithDefault(msg, 210, false),
    autostart: jspb.Message.getBooleanFieldWithDefault(msg, 220, false),
    showonandroidtv: jspb.Message.getBooleanFieldWithDefault(msg, 230, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Stream}
 */
proto.commUnity.Stream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Stream;
  return proto.commUnity.Stream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Stream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Stream}
 */
proto.commUnity.Stream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpeninside(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoto(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembertypes(value);
      break;
    case 200:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLinknb(value);
      break;
    case 210:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowchromecast(value);
      break;
    case 220:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutostart(value);
      break;
    case 230:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowonandroidtv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Stream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Stream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Stream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Stream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCustomerid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOpeninside();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPhoto();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMembertypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      100,
      f
    );
  }
  f = message.getLinknb();
  if (f !== 0) {
    writer.writeInt32(
      200,
      f
    );
  }
  f = message.getShowchromecast();
  if (f) {
    writer.writeBool(
      210,
      f
    );
  }
  f = message.getAutostart();
  if (f) {
    writer.writeBool(
      220,
      f
    );
  }
  f = message.getShowonandroidtv();
  if (f) {
    writer.writeBool(
      230,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Stream.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.commUnity.Stream.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 Order = 4;
 * @return {number}
 */
proto.commUnity.Stream.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string URL = 5;
 * @return {string}
 */
proto.commUnity.Stream.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string CustomerID = 6;
 * @return {string}
 */
proto.commUnity.Stream.prototype.getCustomerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.setCustomerid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Icon = 8;
 * @return {string}
 */
proto.commUnity.Stream.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool OpenInside = 9;
 * @return {boolean}
 */
proto.commUnity.Stream.prototype.getOpeninside = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.setOpeninside = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string Photo = 10;
 * @return {string}
 */
proto.commUnity.Stream.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated string MemberTypes = 100;
 * @return {!Array<string>}
 */
proto.commUnity.Stream.prototype.getMembertypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 100));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.setMembertypesList = function(value) {
  return jspb.Message.setField(this, 100, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.addMembertypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 100, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.clearMembertypesList = function() {
  return this.setMembertypesList([]);
};


/**
 * optional int32 LinkNb = 200;
 * @return {number}
 */
proto.commUnity.Stream.prototype.getLinknb = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 200, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.setLinknb = function(value) {
  return jspb.Message.setProto3IntField(this, 200, value);
};


/**
 * optional bool ShowChromeCast = 210;
 * @return {boolean}
 */
proto.commUnity.Stream.prototype.getShowchromecast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 210, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.setShowchromecast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 210, value);
};


/**
 * optional bool AutoStart = 220;
 * @return {boolean}
 */
proto.commUnity.Stream.prototype.getAutostart = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 220, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.setAutostart = function(value) {
  return jspb.Message.setProto3BooleanField(this, 220, value);
};


/**
 * optional bool ShowOnAndroidTV = 230;
 * @return {boolean}
 */
proto.commUnity.Stream.prototype.getShowonandroidtv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 230, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Stream} returns this
 */
proto.commUnity.Stream.prototype.setShowonandroidtv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 230, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.CustomizedMenu.repeatedFields_ = [100];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.CustomizedMenu.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.CustomizedMenu.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.CustomizedMenu} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CustomizedMenu.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 4, 0),
    url: jspb.Message.getFieldWithDefault(msg, 5, ""),
    photo: jspb.Message.getFieldWithDefault(msg, 10, ""),
    membertypesList: (f = jspb.Message.getRepeatedField(msg, 100)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.CustomizedMenu}
 */
proto.commUnity.CustomizedMenu.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.CustomizedMenu;
  return proto.commUnity.CustomizedMenu.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.CustomizedMenu} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.CustomizedMenu}
 */
proto.commUnity.CustomizedMenu.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoto(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembertypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.CustomizedMenu.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.CustomizedMenu.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.CustomizedMenu} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CustomizedMenu.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhoto();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMembertypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.CustomizedMenu.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CustomizedMenu} returns this
 */
proto.commUnity.CustomizedMenu.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.commUnity.CustomizedMenu.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CustomizedMenu} returns this
 */
proto.commUnity.CustomizedMenu.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 Order = 4;
 * @return {number}
 */
proto.commUnity.CustomizedMenu.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.CustomizedMenu} returns this
 */
proto.commUnity.CustomizedMenu.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string URL = 5;
 * @return {string}
 */
proto.commUnity.CustomizedMenu.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CustomizedMenu} returns this
 */
proto.commUnity.CustomizedMenu.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Photo = 10;
 * @return {string}
 */
proto.commUnity.CustomizedMenu.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CustomizedMenu} returns this
 */
proto.commUnity.CustomizedMenu.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated string MemberTypes = 100;
 * @return {!Array<string>}
 */
proto.commUnity.CustomizedMenu.prototype.getMembertypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 100));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.CustomizedMenu} returns this
 */
proto.commUnity.CustomizedMenu.prototype.setMembertypesList = function(value) {
  return jspb.Message.setField(this, 100, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.CustomizedMenu} returns this
 */
proto.commUnity.CustomizedMenu.prototype.addMembertypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 100, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.CustomizedMenu} returns this
 */
proto.commUnity.CustomizedMenu.prototype.clearMembertypesList = function() {
  return this.setMembertypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.MenuAlias.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.MenuAlias.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.MenuAlias} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MenuAlias.toObject = function(includeInstance, msg) {
  var f, obj = {
    language: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customerid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    link: jspb.Message.getFieldWithDefault(msg, 10, ""),
    linkphoto: jspb.Message.getFieldWithDefault(msg, 11, ""),
    event: jspb.Message.getFieldWithDefault(msg, 20, ""),
    eventphoto: jspb.Message.getFieldWithDefault(msg, 21, ""),
    news: jspb.Message.getFieldWithDefault(msg, 30, ""),
    newsphoto: jspb.Message.getFieldWithDefault(msg, 31, ""),
    news2: jspb.Message.getFieldWithDefault(msg, 32, ""),
    news2photo: jspb.Message.getFieldWithDefault(msg, 33, ""),
    news3: jspb.Message.getFieldWithDefault(msg, 34, ""),
    news3photo: jspb.Message.getFieldWithDefault(msg, 35, ""),
    news4: jspb.Message.getFieldWithDefault(msg, 36, ""),
    news4photo: jspb.Message.getFieldWithDefault(msg, 37, ""),
    shop: jspb.Message.getFieldWithDefault(msg, 40, ""),
    shopphoto: jspb.Message.getFieldWithDefault(msg, 41, ""),
    restaurant: jspb.Message.getFieldWithDefault(msg, 42, ""),
    restaurantphoto: jspb.Message.getFieldWithDefault(msg, 43, ""),
    benefit: jspb.Message.getFieldWithDefault(msg, 50, ""),
    benefitphoto: jspb.Message.getFieldWithDefault(msg, 51, ""),
    forum: jspb.Message.getFieldWithDefault(msg, 60, ""),
    forumphoto: jspb.Message.getFieldWithDefault(msg, 61, ""),
    place: jspb.Message.getFieldWithDefault(msg, 70, ""),
    placephoto: jspb.Message.getFieldWithDefault(msg, 71, ""),
    leaderboard: jspb.Message.getFieldWithDefault(msg, 80, ""),
    leaderboardphoto: jspb.Message.getFieldWithDefault(msg, 81, ""),
    competition: jspb.Message.getFieldWithDefault(msg, 90, ""),
    competitionphoto: jspb.Message.getFieldWithDefault(msg, 91, ""),
    album: jspb.Message.getFieldWithDefault(msg, 100, ""),
    albumphoto: jspb.Message.getFieldWithDefault(msg, 101, ""),
    aboutus: jspb.Message.getFieldWithDefault(msg, 110, ""),
    aboutusphoto: jspb.Message.getFieldWithDefault(msg, 111, ""),
    member: jspb.Message.getFieldWithDefault(msg, 120, ""),
    memberphoto: jspb.Message.getFieldWithDefault(msg, 121, ""),
    document: jspb.Message.getFieldWithDefault(msg, 122, ""),
    documentphoto: jspb.Message.getFieldWithDefault(msg, 123, ""),
    businesscard: jspb.Message.getFieldWithDefault(msg, 124, ""),
    businesscardphoto: jspb.Message.getFieldWithDefault(msg, 125, ""),
    cpslink: jspb.Message.getFieldWithDefault(msg, 126, ""),
    cpslinkphoto: jspb.Message.getFieldWithDefault(msg, 127, ""),
    link2: jspb.Message.getFieldWithDefault(msg, 130, ""),
    linkphoto2: jspb.Message.getFieldWithDefault(msg, 131, ""),
    link3: jspb.Message.getFieldWithDefault(msg, 132, ""),
    linkphoto3: jspb.Message.getFieldWithDefault(msg, 133, ""),
    link4: jspb.Message.getFieldWithDefault(msg, 134, ""),
    linkphoto4: jspb.Message.getFieldWithDefault(msg, 135, ""),
    stream: jspb.Message.getFieldWithDefault(msg, 138, ""),
    streamphoto: jspb.Message.getFieldWithDefault(msg, 139, ""),
    stream2: jspb.Message.getFieldWithDefault(msg, 140, ""),
    streamphoto2: jspb.Message.getFieldWithDefault(msg, 141, ""),
    stream3: jspb.Message.getFieldWithDefault(msg, 142, ""),
    streamphoto3: jspb.Message.getFieldWithDefault(msg, 143, ""),
    stream4: jspb.Message.getFieldWithDefault(msg, 144, ""),
    streamphoto4: jspb.Message.getFieldWithDefault(msg, 145, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.MenuAlias}
 */
proto.commUnity.MenuAlias.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.MenuAlias;
  return proto.commUnity.MenuAlias.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.MenuAlias} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.MenuAlias}
 */
proto.commUnity.MenuAlias.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.commUnity.Language} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkphoto(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvent(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventphoto(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setNews(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewsphoto(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setNews2(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setNews2photo(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setNews3(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setNews3photo(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setNews4(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setNews4photo(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setShop(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopphoto(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setRestaurant(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setRestaurantphoto(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setBenefit(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setBenefitphoto(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setForum(value);
      break;
    case 61:
      var value = /** @type {string} */ (reader.readString());
      msg.setForumphoto(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlace(value);
      break;
    case 71:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacephoto(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeaderboard(value);
      break;
    case 81:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeaderboardphoto(value);
      break;
    case 90:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetition(value);
      break;
    case 91:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionphoto(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlbum(value);
      break;
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlbumphoto(value);
      break;
    case 110:
      var value = /** @type {string} */ (reader.readString());
      msg.setAboutus(value);
      break;
    case 111:
      var value = /** @type {string} */ (reader.readString());
      msg.setAboutusphoto(value);
      break;
    case 120:
      var value = /** @type {string} */ (reader.readString());
      msg.setMember(value);
      break;
    case 121:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberphoto(value);
      break;
    case 122:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocument(value);
      break;
    case 123:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentphoto(value);
      break;
    case 124:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinesscard(value);
      break;
    case 125:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinesscardphoto(value);
      break;
    case 126:
      var value = /** @type {string} */ (reader.readString());
      msg.setCpslink(value);
      break;
    case 127:
      var value = /** @type {string} */ (reader.readString());
      msg.setCpslinkphoto(value);
      break;
    case 130:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink2(value);
      break;
    case 131:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkphoto2(value);
      break;
    case 132:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink3(value);
      break;
    case 133:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkphoto3(value);
      break;
    case 134:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink4(value);
      break;
    case 135:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkphoto4(value);
      break;
    case 138:
      var value = /** @type {string} */ (reader.readString());
      msg.setStream(value);
      break;
    case 139:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreamphoto(value);
      break;
    case 140:
      var value = /** @type {string} */ (reader.readString());
      msg.setStream2(value);
      break;
    case 141:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreamphoto2(value);
      break;
    case 142:
      var value = /** @type {string} */ (reader.readString());
      msg.setStream3(value);
      break;
    case 143:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreamphoto3(value);
      break;
    case 144:
      var value = /** @type {string} */ (reader.readString());
      msg.setStream4(value);
      break;
    case 145:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreamphoto4(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.MenuAlias.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.MenuAlias.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.MenuAlias} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MenuAlias.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCustomerid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLinkphoto();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEvent();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getEventphoto();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getNews();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getNewsphoto();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getNews2();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getNews2photo();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getNews3();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getNews3photo();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getNews4();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getNews4photo();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getShop();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getShopphoto();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getRestaurant();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getRestaurantphoto();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getBenefit();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getBenefitphoto();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getForum();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getForumphoto();
  if (f.length > 0) {
    writer.writeString(
      61,
      f
    );
  }
  f = message.getPlace();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getPlacephoto();
  if (f.length > 0) {
    writer.writeString(
      71,
      f
    );
  }
  f = message.getLeaderboard();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
  f = message.getLeaderboardphoto();
  if (f.length > 0) {
    writer.writeString(
      81,
      f
    );
  }
  f = message.getCompetition();
  if (f.length > 0) {
    writer.writeString(
      90,
      f
    );
  }
  f = message.getCompetitionphoto();
  if (f.length > 0) {
    writer.writeString(
      91,
      f
    );
  }
  f = message.getAlbum();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
  f = message.getAlbumphoto();
  if (f.length > 0) {
    writer.writeString(
      101,
      f
    );
  }
  f = message.getAboutus();
  if (f.length > 0) {
    writer.writeString(
      110,
      f
    );
  }
  f = message.getAboutusphoto();
  if (f.length > 0) {
    writer.writeString(
      111,
      f
    );
  }
  f = message.getMember();
  if (f.length > 0) {
    writer.writeString(
      120,
      f
    );
  }
  f = message.getMemberphoto();
  if (f.length > 0) {
    writer.writeString(
      121,
      f
    );
  }
  f = message.getDocument();
  if (f.length > 0) {
    writer.writeString(
      122,
      f
    );
  }
  f = message.getDocumentphoto();
  if (f.length > 0) {
    writer.writeString(
      123,
      f
    );
  }
  f = message.getBusinesscard();
  if (f.length > 0) {
    writer.writeString(
      124,
      f
    );
  }
  f = message.getBusinesscardphoto();
  if (f.length > 0) {
    writer.writeString(
      125,
      f
    );
  }
  f = message.getCpslink();
  if (f.length > 0) {
    writer.writeString(
      126,
      f
    );
  }
  f = message.getCpslinkphoto();
  if (f.length > 0) {
    writer.writeString(
      127,
      f
    );
  }
  f = message.getLink2();
  if (f.length > 0) {
    writer.writeString(
      130,
      f
    );
  }
  f = message.getLinkphoto2();
  if (f.length > 0) {
    writer.writeString(
      131,
      f
    );
  }
  f = message.getLink3();
  if (f.length > 0) {
    writer.writeString(
      132,
      f
    );
  }
  f = message.getLinkphoto3();
  if (f.length > 0) {
    writer.writeString(
      133,
      f
    );
  }
  f = message.getLink4();
  if (f.length > 0) {
    writer.writeString(
      134,
      f
    );
  }
  f = message.getLinkphoto4();
  if (f.length > 0) {
    writer.writeString(
      135,
      f
    );
  }
  f = message.getStream();
  if (f.length > 0) {
    writer.writeString(
      138,
      f
    );
  }
  f = message.getStreamphoto();
  if (f.length > 0) {
    writer.writeString(
      139,
      f
    );
  }
  f = message.getStream2();
  if (f.length > 0) {
    writer.writeString(
      140,
      f
    );
  }
  f = message.getStreamphoto2();
  if (f.length > 0) {
    writer.writeString(
      141,
      f
    );
  }
  f = message.getStream3();
  if (f.length > 0) {
    writer.writeString(
      142,
      f
    );
  }
  f = message.getStreamphoto3();
  if (f.length > 0) {
    writer.writeString(
      143,
      f
    );
  }
  f = message.getStream4();
  if (f.length > 0) {
    writer.writeString(
      144,
      f
    );
  }
  f = message.getStreamphoto4();
  if (f.length > 0) {
    writer.writeString(
      145,
      f
    );
  }
};


/**
 * optional Language Language = 1;
 * @return {!proto.commUnity.Language}
 */
proto.commUnity.MenuAlias.prototype.getLanguage = function() {
  return /** @type {!proto.commUnity.Language} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.commUnity.Language} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string CustomerID = 5;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getCustomerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setCustomerid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Link = 10;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string LinkPhoto = 11;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getLinkphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setLinkphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string Event = 20;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getEvent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setEvent = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string EventPhoto = 21;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getEventphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setEventphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string News = 30;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getNews = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setNews = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string NewsPhoto = 31;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getNewsphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setNewsphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string News2 = 32;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getNews2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setNews2 = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string News2Photo = 33;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getNews2photo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setNews2photo = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string News3 = 34;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getNews3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setNews3 = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string News3Photo = 35;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getNews3photo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setNews3photo = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string News4 = 36;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getNews4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setNews4 = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string News4Photo = 37;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getNews4photo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setNews4photo = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string Shop = 40;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getShop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setShop = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string ShopPhoto = 41;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getShopphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setShopphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string Restaurant = 42;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getRestaurant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setRestaurant = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string RestaurantPhoto = 43;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getRestaurantphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setRestaurantphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string Benefit = 50;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getBenefit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setBenefit = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string BenefitPhoto = 51;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getBenefitphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setBenefitphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional string Forum = 60;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getForum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setForum = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional string ForumPhoto = 61;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getForumphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 61, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setForumphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 61, value);
};


/**
 * optional string Place = 70;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional string PlacePhoto = 71;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getPlacephoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 71, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setPlacephoto = function(value) {
  return jspb.Message.setProto3StringField(this, 71, value);
};


/**
 * optional string Leaderboard = 80;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getLeaderboard = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setLeaderboard = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};


/**
 * optional string LeaderboardPhoto = 81;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getLeaderboardphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 81, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setLeaderboardphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 81, value);
};


/**
 * optional string Competition = 90;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getCompetition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 90, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setCompetition = function(value) {
  return jspb.Message.setProto3StringField(this, 90, value);
};


/**
 * optional string CompetitionPhoto = 91;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getCompetitionphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 91, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setCompetitionphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 91, value);
};


/**
 * optional string Album = 100;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getAlbum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setAlbum = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};


/**
 * optional string AlbumPhoto = 101;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getAlbumphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 101, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setAlbumphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 101, value);
};


/**
 * optional string AboutUs = 110;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getAboutus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 110, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setAboutus = function(value) {
  return jspb.Message.setProto3StringField(this, 110, value);
};


/**
 * optional string AboutUsPhoto = 111;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getAboutusphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 111, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setAboutusphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 111, value);
};


/**
 * optional string Member = 120;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getMember = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 120, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setMember = function(value) {
  return jspb.Message.setProto3StringField(this, 120, value);
};


/**
 * optional string MemberPhoto = 121;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getMemberphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 121, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setMemberphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 121, value);
};


/**
 * optional string Document = 122;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getDocument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 122, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setDocument = function(value) {
  return jspb.Message.setProto3StringField(this, 122, value);
};


/**
 * optional string DocumentPhoto = 123;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getDocumentphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 123, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setDocumentphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 123, value);
};


/**
 * optional string BusinessCard = 124;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getBusinesscard = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 124, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setBusinesscard = function(value) {
  return jspb.Message.setProto3StringField(this, 124, value);
};


/**
 * optional string BusinessCardPhoto = 125;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getBusinesscardphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 125, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setBusinesscardphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 125, value);
};


/**
 * optional string CPSLink = 126;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getCpslink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 126, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setCpslink = function(value) {
  return jspb.Message.setProto3StringField(this, 126, value);
};


/**
 * optional string CPSLinkPhoto = 127;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getCpslinkphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 127, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setCpslinkphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 127, value);
};


/**
 * optional string Link2 = 130;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getLink2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 130, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setLink2 = function(value) {
  return jspb.Message.setProto3StringField(this, 130, value);
};


/**
 * optional string LinkPhoto2 = 131;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getLinkphoto2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 131, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setLinkphoto2 = function(value) {
  return jspb.Message.setProto3StringField(this, 131, value);
};


/**
 * optional string Link3 = 132;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getLink3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 132, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setLink3 = function(value) {
  return jspb.Message.setProto3StringField(this, 132, value);
};


/**
 * optional string LinkPhoto3 = 133;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getLinkphoto3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 133, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setLinkphoto3 = function(value) {
  return jspb.Message.setProto3StringField(this, 133, value);
};


/**
 * optional string Link4 = 134;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getLink4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 134, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setLink4 = function(value) {
  return jspb.Message.setProto3StringField(this, 134, value);
};


/**
 * optional string LinkPhoto4 = 135;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getLinkphoto4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 135, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setLinkphoto4 = function(value) {
  return jspb.Message.setProto3StringField(this, 135, value);
};


/**
 * optional string Stream = 138;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getStream = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 138, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setStream = function(value) {
  return jspb.Message.setProto3StringField(this, 138, value);
};


/**
 * optional string StreamPhoto = 139;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getStreamphoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 139, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setStreamphoto = function(value) {
  return jspb.Message.setProto3StringField(this, 139, value);
};


/**
 * optional string Stream2 = 140;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getStream2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 140, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setStream2 = function(value) {
  return jspb.Message.setProto3StringField(this, 140, value);
};


/**
 * optional string StreamPhoto2 = 141;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getStreamphoto2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 141, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setStreamphoto2 = function(value) {
  return jspb.Message.setProto3StringField(this, 141, value);
};


/**
 * optional string Stream3 = 142;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getStream3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 142, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setStream3 = function(value) {
  return jspb.Message.setProto3StringField(this, 142, value);
};


/**
 * optional string StreamPhoto3 = 143;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getStreamphoto3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 143, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setStreamphoto3 = function(value) {
  return jspb.Message.setProto3StringField(this, 143, value);
};


/**
 * optional string Stream4 = 144;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getStream4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 144, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setStream4 = function(value) {
  return jspb.Message.setProto3StringField(this, 144, value);
};


/**
 * optional string StreamPhoto4 = 145;
 * @return {string}
 */
proto.commUnity.MenuAlias.prototype.getStreamphoto4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 145, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MenuAlias} returns this
 */
proto.commUnity.MenuAlias.prototype.setStreamphoto4 = function(value) {
  return jspb.Message.setProto3StringField(this, 145, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.BusinessCard.repeatedFields_ = [100];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.BusinessCard.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.BusinessCard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.BusinessCard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.BusinessCard.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    photo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    position: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 7, ""),
    mobile: jspb.Message.getFieldWithDefault(msg, 9, ""),
    qrcode: jspb.Message.getFieldWithDefault(msg, 30, ""),
    membertypesList: (f = jspb.Message.getRepeatedField(msg, 100)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.BusinessCard}
 */
proto.commUnity.BusinessCard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.BusinessCard;
  return proto.commUnity.BusinessCard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.BusinessCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.BusinessCard}
 */
proto.commUnity.BusinessCard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoto(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPosition(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setQrcode(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembertypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.BusinessCard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.BusinessCard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.BusinessCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.BusinessCard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoto();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPosition();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getQrcode();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getMembertypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      100,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.BusinessCard.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.BusinessCard} returns this
 */
proto.commUnity.BusinessCard.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.commUnity.BusinessCard.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.BusinessCard} returns this
 */
proto.commUnity.BusinessCard.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Name = 3;
 * @return {string}
 */
proto.commUnity.BusinessCard.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.BusinessCard} returns this
 */
proto.commUnity.BusinessCard.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Photo = 4;
 * @return {string}
 */
proto.commUnity.BusinessCard.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.BusinessCard} returns this
 */
proto.commUnity.BusinessCard.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string FirstName = 5;
 * @return {string}
 */
proto.commUnity.BusinessCard.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.BusinessCard} returns this
 */
proto.commUnity.BusinessCard.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Position = 6;
 * @return {string}
 */
proto.commUnity.BusinessCard.prototype.getPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.BusinessCard} returns this
 */
proto.commUnity.BusinessCard.prototype.setPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Phone = 7;
 * @return {string}
 */
proto.commUnity.BusinessCard.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.BusinessCard} returns this
 */
proto.commUnity.BusinessCard.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Mobile = 9;
 * @return {string}
 */
proto.commUnity.BusinessCard.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.BusinessCard} returns this
 */
proto.commUnity.BusinessCard.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string QRCode = 30;
 * @return {string}
 */
proto.commUnity.BusinessCard.prototype.getQrcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.BusinessCard} returns this
 */
proto.commUnity.BusinessCard.prototype.setQrcode = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * repeated string MemberTypes = 100;
 * @return {!Array<string>}
 */
proto.commUnity.BusinessCard.prototype.getMembertypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 100));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.BusinessCard} returns this
 */
proto.commUnity.BusinessCard.prototype.setMembertypesList = function(value) {
  return jspb.Message.setField(this, 100, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.BusinessCard} returns this
 */
proto.commUnity.BusinessCard.prototype.addMembertypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 100, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.BusinessCard} returns this
 */
proto.commUnity.BusinessCard.prototype.clearMembertypesList = function() {
  return this.setMembertypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Document.repeatedFields_ = [100];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortdescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    image: jspb.Message.getFieldWithDefault(msg, 4, ""),
    publishdate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    url: jspb.Message.getFieldWithDefault(msg, 7, ""),
    endpublishdate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    qrcode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    order: jspb.Message.getFieldWithDefault(msg, 10, 0),
    categoryid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    language: jspb.Message.getFieldWithDefault(msg, 12, 0),
    placeid: jspb.Message.getFieldWithDefault(msg, 20, ""),
    placename: jspb.Message.getFieldWithDefault(msg, 21, ""),
    membertypesList: (f = jspb.Message.getRepeatedField(msg, 100)) == null ? undefined : f,
    categoryname: jspb.Message.getFieldWithDefault(msg, 110, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Document}
 */
proto.commUnity.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Document;
  return proto.commUnity.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Document}
 */
proto.commUnity.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortdescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPublishdate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndpublishdate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setQrcode(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryid(value);
      break;
    case 12:
      var value = /** @type {!proto.commUnity.Language} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceid(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlacename(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.addMembertypes(value);
      break;
    case 110:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortdescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPublishdate();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEndpublishdate();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getQrcode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getCategoryid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getPlaceid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPlacename();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getMembertypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      100,
      f
    );
  }
  f = message.getCategoryname();
  if (f.length > 0) {
    writer.writeString(
      110,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Document.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.commUnity.Document.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string shortDescription = 3;
 * @return {string}
 */
proto.commUnity.Document.prototype.getShortdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setShortdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image = 4;
 * @return {string}
 */
proto.commUnity.Document.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 publishDate = 5;
 * @return {number}
 */
proto.commUnity.Document.prototype.getPublishdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setPublishdate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.commUnity.Document.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string URL = 7;
 * @return {string}
 */
proto.commUnity.Document.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 EndPublishDate = 8;
 * @return {number}
 */
proto.commUnity.Document.prototype.getEndpublishdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setEndpublishdate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string QRCode = 9;
 * @return {string}
 */
proto.commUnity.Document.prototype.getQrcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setQrcode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional uint32 Order = 10;
 * @return {number}
 */
proto.commUnity.Document.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string CategoryID = 11;
 * @return {string}
 */
proto.commUnity.Document.prototype.getCategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional Language Language = 12;
 * @return {!proto.commUnity.Language}
 */
proto.commUnity.Document.prototype.getLanguage = function() {
  return /** @type {!proto.commUnity.Language} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.commUnity.Language} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string PlaceID = 20;
 * @return {string}
 */
proto.commUnity.Document.prototype.getPlaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setPlaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string PlaceName = 21;
 * @return {string}
 */
proto.commUnity.Document.prototype.getPlacename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setPlacename = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated string MemberTypes = 100;
 * @return {!Array<string>}
 */
proto.commUnity.Document.prototype.getMembertypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 100));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setMembertypesList = function(value) {
  return jspb.Message.setField(this, 100, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.addMembertypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 100, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.clearMembertypesList = function() {
  return this.setMembertypesList([]);
};


/**
 * optional string CategoryName = 110;
 * @return {string}
 */
proto.commUnity.Document.prototype.getCategoryname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 110, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Document} returns this
 */
proto.commUnity.Document.prototype.setCategoryname = function(value) {
  return jspb.Message.setProto3StringField(this, 110, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.DocumentCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.DocumentCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.DocumentCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.DocumentCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.DocumentCategory}
 */
proto.commUnity.DocumentCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.DocumentCategory;
  return proto.commUnity.DocumentCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.DocumentCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.DocumentCategory}
 */
proto.commUnity.DocumentCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.DocumentCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.DocumentCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.DocumentCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.DocumentCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.DocumentCategory.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.DocumentCategory} returns this
 */
proto.commUnity.DocumentCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.commUnity.DocumentCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.DocumentCategory} returns this
 */
proto.commUnity.DocumentCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.commUnity.DocumentCategory.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.DocumentCategory} returns this
 */
proto.commUnity.DocumentCategory.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 Order = 5;
 * @return {number}
 */
proto.commUnity.DocumentCategory.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.DocumentCategory} returns this
 */
proto.commUnity.DocumentCategory.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.commUnity.OS = {
  OS_ANDROID: 0,
  OS_IOS: 1,
  OS_PWA: 2
};

/**
 * @enum {number}
 */
proto.commUnity.EmailUsMem = {
  EMAILMEMBER: 0,
  EMAILUSER: 1
};

/**
 * @enum {number}
 */
proto.commUnity.MenuName = {
  MENUPLACE: 0,
  MENUEVENT: 1,
  MENUSHOP: 2,
  MENUSTREAM: 3,
  MENUSTREAM2: 4,
  MENUSTREAM3: 5,
  MENUSTREAM4: 6,
  MENUNEWS: 7,
  MENUNEWS2: 8,
  MENUNEWS3: 9,
  MENUNEWS4: 10,
  MENUCOMPETITION: 11,
  MENULEADERBOARD: 12,
  MENUMEMBER: 13,
  MENUFORUM: 14,
  MENURESTAURANT: 15,
  MENUBENEFIT: 16,
  MENUALBUM: 17,
  MENUSHOPPROFILE: 18,
  MENUDOCUMENT: 20,
  MENUBUSINESSCARD: 21,
  MENUCPSLINK: 22,
  MENULINK: 23,
  MENULINK2: 24,
  MENULINK3: 25,
  MENULINK4: 26,
  MENUCUSTOMMENU: 27
};

/**
 * @enum {number}
 */
proto.commUnity.MobileMenuType = {
  DEFAULTMOBILE: 0,
  PHOTOMOBILE: 1
};

/**
 * @enum {number}
 */
proto.commUnity.MobileGroupMenuType = {
  GROUPDEFAULTMOBILE: 0,
  GROUPPHOTOMOBILE: 1
};

/**
 * @enum {number}
 */
proto.commUnity.DesktopMenuType = {
  NODESKTOP: 0,
  MOBILEDESIGN: 1,
  DEFAULTDESKTOP: 2,
  MOBILEPHOTODESIGN: 3,
  TABLETPHOTODESIGN: 4
};

/**
 * @enum {number}
 */
proto.commUnity.LoginTemplate = {
  BLACK: 0,
  WHITE: 1
};

/**
 * @enum {number}
 */
proto.commUnity.MobileApp = {
  COMMUNITYLIGHT: 0,
  HOTELPREF: 1,
  HENRIETTE: 2,
  KIKUYU: 3,
  MATELIER: 4,
  CITADIN: 5,
  PIGUY: 6,
  CHAMPAGNECASTELNAU: 7,
  MLADANSE: 8,
  ICLINIQUE: 9,
  BLUEGREEN: 10,
  AGWC: 11,
  COUDRAY: 12,
  VINCENTGOESEL: 13,
  VAC: 14,
  PREMIUMGOLFCLUB: 15,
  HUBERTPRIVE: 16,
  CLUBAPAR: 17,
  MGC: 18,
  ALVORIA: 19,
  WELCOME: 20,
  GHT: 21,
  ABRICOTIER: 22,
  CAMPINGSCOTEEMERAUDE: 23,
  POINTZERO: 24,
  LEETANGSLARICHARDAIS: 25,
  LESILOTSSTMALO: 26,
  CITEDALETHSTMALO: 27,
  WELCOMEHOTEL: 28,
  TOSHIBA: 49,
  LUNETTARIUM: 50,
  HERITAGEWORLDCUP: 51,
  HWCSTAFF: 52,
  HWCPLAYERS: 53,
  GOLFISABELLA: 54,
  GOLFILEFLEURIE: 55,
  CLOUD: 56,
  THECLUBPREFERENCE: 57,
  FREGATEGOLFCLUB: 58,
  LEGRISBI: 59,
  FOOTBALLCLUBMILLYGATINAIS: 60,
  EVERSTREAM: 61,
  CLOUD2: 62,
  GOLFY: 63,
  LNA: 64,
  LESALIZES: 65,
  SANTE: 66,
  BEAUMIER: 67,
  GOLFY1: 68,
  GOLFY2: 69,
  GOLFY3: 70,
  GOLFY4: 71,
  GOLFY5: 72,
  GOLFY6: 73,
  GOLFY7: 74,
  GOLFY8: 75,
  GOLFY9: 76,
  GOLFY10: 77,
  GOLFY11: 78,
  GOLFY12: 79,
  GOLFDUSOLEIL: 80,
  LCGCOUNTRYMANAGER: 81,
  CLOUD3: 82,
  DEMOONE: 2000,
  DEMOTWO: 2001,
  DEMOTHREE: 2002,
  DEMOFOUR: 2003,
  DEMOFIVE: 2004,
  DEMOSIX: 2005,
  DEMOSEVEN: 2006,
  DEMOHEIGHT: 2007,
  DEMONINE: 2008,
  DEMOTEN: 2009,
  DEMOELEVEN: 2010,
  DEMOTWELVE: 2011,
  TESTONE: 1003,
  TESTTWO: 1004,
  TESTTHREE: 1005,
  TESTFOUR: 1006,
  TESTFIVE: 1007,
  TESTSIX: 1008,
  TESTSEVEN: 1009,
  TESTEIGHT: 1010,
  TESTNINE: 1011,
  TESTTEN: 1012,
  TESTELEVEN: 1013,
  TESTTWELVE: 1014,
  DEVONE: 1103,
  DEVTWO: 1104,
  DEVTHREE: 1105,
  DEVFOUR: 1106,
  DEVFIVE: 1107,
  DEVSIX: 1108,
  DEVSEVEN: 1109,
  DEVEIGHT: 1110,
  DEVNINE: 1111,
  DEVTEN: 1112,
  DEVELEVEN: 1113,
  DEVTWELVE: 1114
};

/**
 * @enum {number}
 */
proto.commUnity.Font = {
  DEFAULT: 0,
  DIN: 1,
  SANSERIF: 2,
  PLAYFAIR: 3
};

/**
 * @enum {number}
 */
proto.commUnity.Country = {
  FRANCE: 0,
  BRAZIL: 1,
  SPAIN: 2,
  BELGIUM: 3,
  PORTUGAL: 4,
  UNITED_KINGDOM: 5,
  SWITZERLAND: 6,
  LUXEMBURG: 7,
  GERMANY: 8,
  NEW_CALEDONIA: 9,
  THAILAND: 80,
  OTHER_COUNTRY: 100
};

/**
 * @enum {number}
 */
proto.commUnity.PoloSize = {
  XS: 0,
  S: 1,
  M: 2,
  L: 3,
  XL: 4,
  XXL: 5,
  XXXL: 6,
  OTHER_POLO_SIZE: 100
};

/**
 * @enum {number}
 */
proto.commUnity.Language = {
  FR: 0,
  PT: 1,
  EN: 2,
  ES: 3,
  OTHER_LANGUAGE: 100
};

/**
 * @enum {number}
 */
proto.commUnity.Title = {
  MR: 0,
  MME: 1,
  MLLE: 2
};

/**
 * @enum {number}
 */
proto.commUnity.Amenities = {
  MATERIAL_RENTAL: 0,
  TENNIS: 1,
  WIFI: 2,
  CART_RENTAL: 3,
  SWIMMING_POOL: 4,
  RESTAURANT: 5,
  CONFERENCE_ROOM: 6,
  PROSHOP: 7,
  FITNESS: 8,
  SAUNA: 9,
  HOTEL: 10,
  BAR: 11,
  GUEST_HOUSE: 12,
  DOG_AUTHORIZED: 13,
  SPA: 14,
  CHANGING_ROOMS: 15,
  CART_AUTHORIZED: 16,
  PARKING: 17
};

/**
 * @enum {number}
 */
proto.commUnity.Attending = {
  NO_ANSWER: 0,
  YES: 1,
  NO: 2
};

/**
 * @enum {number}
 */
proto.commUnity.Color = {
  COLOR_NO: 0,
  COLOR_BLACK: 1,
  COLOR_WHITE: 2,
  COLOR_GREEN: 3,
  COLOR_RED: 4,
  COLOR_ORANGE: 5,
  COLOR_PINK: 6,
  COLOR_YELLOW: 7,
  COLOR_PURPLE: 8,
  COLOR_BROWN: 9,
  COLOR_SILVER: 10,
  COLOR_GOLD: 11,
  COLOR_GREY: 12,
  COLOR_BLUE: 13,
  COLOR_VIOLET: 14,
  COLOR_TURQUOISE: 15,
  COLOR_CYAN: 16,
  COLOR_MAGENTA: 17
};

goog.object.extend(exports, proto.commUnity);
